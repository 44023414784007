import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'use-debounce';

import { useOrganizationsApi } from '@api/OrganizationsApi';
import { Spinner } from '@atoms/Spinner';
import { useModalsContext } from '@contexts/modals';
import { useShipmentContext } from '@contexts/shipment';
import { QueryKeys } from '@definitions/QueryKeys';
import { SearchInput } from '@molecules/Form/SearchInput';
import { useInfiniteQuery } from '@tanstack/react-query';

import { ShipmentBaseModal } from '../ShipmentBaseModal';
import { ShipmentBaseModalProps } from '../ShipmentBaseModal/definitions';
import AddressList from './AddressList/AddressList';

const SEARCH_MINIMUM_CHARACTERS = 3;

const ShipmentSelectAddressModal = ({
  open,
  onClose,
}: Pick<ShipmentBaseModalProps, 'open' | 'onClose'>) => {
  const { showShipmentSelectProductsModal } = useModalsContext();
  const {
    flow,
    setOrganizationId: ctxSetOrganizationId,
    organizationId: ctxOrganizationId,
  } = useShipmentContext();
  const { t } = useTranslation();

  const { getOrganizationsRecipients } = useOrganizationsApi();

  const [searchValue, setSearchValue] = useState('');
  const [apiSearchValue] = useDebounce(searchValue, 300);
  const hasMinimumCharaters =
    apiSearchValue.length >= SEARCH_MINIMUM_CHARACTERS;

  const parsedApiSearchValue = useMemo(() => {
    return hasMinimumCharaters ? apiSearchValue : '';
  }, [apiSearchValue, hasMinimumCharaters]);

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useInfiniteQuery({
      enabled: open,
      queryKey: [
        QueryKeys.SHIPMENT_ORGANIZATIONS_RECIPIENTS,
        parsedApiSearchValue,
      ],
      queryFn: ({ pageParam = 1 }) =>
        getOrganizationsRecipients({
          page: pageParam,
          perPage: 20,
          textFilter: parsedApiSearchValue || undefined,
        }),

      initialPageParam: 1,
      getNextPageParam: (thisPage) =>
        thisPage.meta.currentPage + 1 > thisPage.meta.lastPage
          ? null
          : thisPage.meta.currentPage + 1,
    });

  const clearSearch = useCallback(() => {
    setSearchValue('');
  }, []);

  const handleClose = useCallback(() => {
    clearSearch();
    onClose?.();
  }, [onClose, clearSearch]);

  const handleNext = useCallback(() => {
    showShipmentSelectProductsModal();
  }, [showShipmentSelectProductsModal]);

  const handleBottomReached = useCallback(() => {
    if (!hasNextPage || isFetching) {
      return;
    }

    fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetching]);

  return (
    <ShipmentBaseModal
      id={`${flow || ''}SelectAddressModal`}
      title={t('Modals.ShipmentSelectAddress.title', { context: flow })}
      open={open}
      onClose={handleClose}
      onNext={handleNext}
      onBottomReached={handleBottomReached}
      isNextDisabled={!ctxOrganizationId}
    >
      <div className="mb-5 sticky top-0 bg-Primary-05 pr-3">
        <SearchInput
          name="search-address"
          value={searchValue}
          placeholder={t('General.search')}
          onChange={setSearchValue}
          onClear={() => clearSearch()}
        />
      </div>
      <div className="flex flex-col gap-3">
        {data?.pages.map((page, i) => {
          return (
            <AddressList
              key={`${page.meta.currentPage}-${i}`}
              items={page.data || []}
              selectedAddressId={ctxOrganizationId}
              onSelectAddress={ctxSetOrganizationId}
            />
          );
        })}
      </div>
      {(isFetching || isFetchingNextPage) && <Spinner className="mt-4" />}
    </ShipmentBaseModal>
  );
};
export default React.memo(ShipmentSelectAddressModal);
