import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useAuthApi } from '@api/authApi';
import { Typography } from '@atoms/Typography';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLogger } from '@hooks/useLogger';
import useToast from '@hooks/useToast.tsx';
import { Input } from '@molecules/Form/Input';
import { AnonymousRoutes } from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { LoginLayout } from '@templates/LoginLayout';

import { FormValues } from './definitions';
import { useValidationSchema } from './validation';

const ResetPassword = () => {
  const { t } = useTranslation();
  const { logError } = useLogger();

  const validation = useValidationSchema();
  const { control, handleSubmit, reset, formState, getValues } =
    useForm<FormValues>({
      resolver: yupResolver(validation),
      defaultValues: {
        newPassword: '',
        confirmPassword: '',
      },
    });

  const { getPasswordPolicy } = useAuthApi();
  const { data } = getPasswordPolicy();

  const { length, upperCase, specialChars, digits } = data || {
    length: 8,
    upperCase: 1,
    specialChars: 1,
    digits: 1,
  };
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const { showWarningToast } = useToast();

  const { showSuccessToast } = useToast();
  const { goTo } = useNavigation();

  const { resetPassword } = useAuthApi();

  const resetPasswordHandler = useCallback(() => {
    resetPassword
      .mutateAsync({ password: getValues('newPassword'), token: token || '' })
      .then(() => {
        showSuccessToast(t('Login.ResetPasswordPage.toastMessage'));
        goTo(AnonymousRoutes.LOGIN);
        reset();
      })
      .catch((error) => {
        showWarningToast(t('Login.ResetPasswordPage.resetError'));
        reset();
        logError(error);
      });
  }, [
    getValues,
    goTo,
    logError,
    reset,
    resetPassword,
    showSuccessToast,
    showWarningToast,
    t,
    token,
  ]);

  return (
    <LoginLayout
      CTATitle={t('Login.ResetPasswordPage.CTA')}
      CTAHandler={handleSubmit(resetPasswordHandler)}
      CTADisabled={!formState.isValid}
    >
      <Typography isUppercase isBold size="xl" color="text-Primary-05">
        {t('Login.ResetPasswordPage.title')}
      </Typography>
      <Typography color="text-Primary-04" size="sm" className="mt-4">
        {t('Login.ResetPasswordPage.description', {
          length: length,
          upperCase: upperCase,
          specialChars: specialChars,
          digits: digits,
        })}
      </Typography>
      <div className={'mt-10'}>
        <Controller
          control={control}
          name="confirmPassword"
          render={({
            field: { name, value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <Input
              variant="light"
              title={t('Login.ResetPasswordPage.newPassword')}
              placeholder={t('Login.ResetPasswordPage.newPasswordPlaceholder')}
              placeholderUppercase
              name={name}
              type="password"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
            />
          )}
        />

        <div className={'mt-10'}>
          <Controller
            control={control}
            name="newPassword"
            render={({
              field: { name, value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                variant="light"
                title={t('Login.ResetPasswordPage.confirmPassword')}
                placeholder={t(
                  'Login.ResetPasswordPage.confirmPasswordPlaceholder',
                )}
                placeholderUppercase
                name={name}
                type="password"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
              />
            )}
          />
        </div>
      </div>
    </LoginLayout>
  );
};

export default React.memo(ResetPassword);
