import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useShipmentApi } from '@api/ShipmentApi';
import { Button } from '@atoms/Button';
import { IconSVG } from '@atoms/IconSVG';
import { Textarea } from '@atoms/Textarea';
import { Typography } from '@atoms/Typography';
import { useInvalidateQueries } from '@hooks/useInvalidateQueries';
import useToast from '@hooks/useToast';
import { BaseModal } from '@organisms/Modals/BaseModal';
import { useMutation } from '@tanstack/react-query';

import { AttachCommentModalProps } from './definitions';

const AttachCommentModal = ({
  open,
  onClose,
  shipmentId,
  delayedComment: delayedCommentFromProps,
}: AttachCommentModalProps) => {
  const { invalidateAllShipmentQueries } = useInvalidateQueries();
  const { t } = useTranslation();
  const { showWarningToast } = useToast();

  const [textareaValue, setTextareaValue] = useState('');
  useEffect(() => {
    setTextareaValue(delayedCommentFromProps || '');
  }, [delayedCommentFromProps]);

  const { updateShipmentDelayedComment } = useShipmentApi();

  const { mutate: callUpdateShipmentDelayedComment } = useMutation({
    mutationFn: updateShipmentDelayedComment,
  });

  const handleSubmit = useCallback(
    (action: 'save' | 'delete') => {
      callUpdateShipmentDelayedComment(
        {
          shipmentId,
          delayedComment: action === 'delete' ? '' : textareaValue,
        },
        {
          onSuccess: () => {
            onClose?.();

            invalidateAllShipmentQueries();
          },
          onError: () => {
            showWarningToast(t('Modals.AttachComment.errorMessage'));
          },
        },
      );
    },
    [
      callUpdateShipmentDelayedComment,
      invalidateAllShipmentQueries,
      onClose,
      shipmentId,
      showWarningToast,
      t,
      textareaValue,
    ],
  );

  return (
    <BaseModal
      id="attachCommentModal"
      size="large"
      header={{
        leftElement: <div />,
        centerElement: (
          <Typography isUppercase isBold size="lg">
            {t('Modals.AttachComment.title')}
          </Typography>
        ),
        rightElement: <IconSVG icon="close" size={16} onClick={onClose} />,
      }}
      footer={
        <div className="flex flex-col gap-2">
          <Button
            type="primary"
            onClick={() => handleSubmit('save')}
            label={t('Modals.AttachComment.saveButton')}
          />
          <Button
            type="secondary"
            onClick={() => handleSubmit('delete')}
            label={t('Modals.AttachComment.deleteButton')}
          />
        </div>
      }
      open={open}
      onClose={onClose}
    >
      <div>
        <Textarea
          rows={14}
          placeholder={t('Modals.AttachComment.textareaPlaceholder')}
          value={textareaValue}
          onChange={setTextareaValue}
        />
      </div>
    </BaseModal>
  );
};

export default React.memo(AttachCommentModal);
