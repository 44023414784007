import React, { ChangeEventHandler, useCallback, useState } from 'react';

import imageCompression from 'browser-image-compression';

import { IconSVG } from '@atoms/IconSVG';
import useWindow from '@hooks/useWindow';
import { FileInputButtonProps } from '@molecules/FileInputButton/definitions';

const FileInputButton: React.FC<FileInputButtonProps> = ({
  onChange,
  accept,
  ...otherProps
}) => {
  const { isMobile } = useWindow();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [inputKey, setInputKey] = useState('');

  const handleButtonClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleClear = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    onChange?.(undefined);
    setInputKey(Date.now().toString());
  }, [onChange]);

  const handleFileSelect = useCallback<ChangeEventHandler<HTMLInputElement>>(
    async (event) => {
      let file: File | undefined = event.target.files?.[0];

      if (file) {
        file = await imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        });
      }
      onChange?.(file);
      setInputKey(Date.now().toString());
    },
    [onChange],
  );

  return (
    <>
      <input
        key={inputKey}
        type="file"
        accept={accept || '*'}
        capture={isMobile}
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileSelect}
      />
      {otherProps.element ? (
        otherProps.element({ onClick: handleButtonClick, onClear: handleClear })
      ) : (
        <button
          onClick={handleButtonClick}
          className="ui-btn btn py-2 btn-secondary"
        >
          <IconSVG icon={otherProps.icon} size={26} />
        </button>
      )}
    </>
  );
};

export default React.memo(FileInputButton);
