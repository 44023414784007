import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { usePackagingApi } from '@api/packagingApi';
import { useSupportsApi } from '@api/supportsApi';
import { IconSVG } from '@atoms/IconSVG';
import { Spinner } from '@atoms/Spinner';
import { Typography } from '@atoms/Typography';
import { useUserContext } from '@contexts/user';
import { QueryKeys } from '@definitions/QueryKeys';
import {
  ShipmentPackaging,
  ShipmentSupport,
  StatusHistory,
} from '@definitions/Shipment';
import { useDownloadShipmentPdf } from '@hooks/useDownload';
import { useShipmentData } from '@hooks/useShipmentData';
import { Select } from '@molecules/Form/Select';
import { BaseModal } from '@organisms/Modals/BaseModal';
import { useQuery } from '@tanstack/react-query';

import { ShipmentDetailsModalProps } from './definitions';

const DetailField = ({
  label,
  value,
  isUrgent,
}: {
  label: string;
  value: ReactNode;
  isUrgent?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-6 gap- items-center">
      <Typography className="overflow-hidden truncate col-span-2 text-ellipsis">
        {label}
      </Typography>

      <div className={`${isUrgent ? 'col-span-2' : 'col-span-4'}`}>
        {typeof value === 'string' ? (
          <Typography>{value || '-'}</Typography>
        ) : (
          value
        )}
      </div>
      {isUrgent && (
        <div className="text-center col-span-2">
          <div className="p-[2px] w-fit flex justify-center bg-Urgent-red">
            <Typography size="sm" isUppercase color="text-Primary-05">
              {t('Modals.ShipmentDetails.urgent')}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

const SHIPMENT_HISTORY_MAX_COUNT = 6;

const ShipmentDetailsModal: React.FC<ShipmentDetailsModalProps> = ({
  open,
  onClose,
  shipment,
}) => {
  const { getPackagings } = usePackagingApi();
  const { getSupports } = useSupportsApi();

  const { getShipmentType, getShipmentTotalProducts, getShipmentProductStyle } =
    useShipmentData();
  const { user } = useUserContext();
  const { t } = useTranslation();

  const { data: packagingsList } = useQuery({
    queryKey: [QueryKeys.SHIPMENT_PACKAGINGS],
    queryFn: () => getPackagings(),
    enabled: open,
  });

  const { data: supportsList } = useQuery({
    queryKey: [QueryKeys.SHIPMENT_SUPPORTS],
    queryFn: () => getSupports(),
    enabled: open,
  });

  const shipmentType = getShipmentType(shipment, user);
  const shipmentTotalItems = getShipmentTotalProducts(shipment);
  const { styleId, styleIdType } = getShipmentProductStyle(shipment);

  const icon = useMemo(
    () => (shipmentType === 'incoming' ? 'download-box' : 'upload-box'),
    [shipmentType],
  );
  const entryDate = useMemo(
    () =>
      shipment?.createdAt && dayjs(shipment?.createdAt).format('DD/MM/YYYY'),
    [shipment?.createdAt],
  );

  const shipmentCurrentStatus = useMemo(
    () =>
      shipment?.statusHistory && shipment?.statusHistory?.length > 1
        ? shipment?.statusHistory.at(-1)
        : shipment?.statusHistory.at(0),
    [shipment?.statusHistory],
  );

  const packagings = useMemo(() => {
    if (!packagingsList) {
      return '';
    }

    return shipment?.shipmentPackagings
      .map(
        (packaging: ShipmentPackaging) =>
          `${packaging.quantity} ${packagingsList?.find((p) => p.id === packaging.packagingId)?.label}`,
      )
      .join(' - ');
  }, [packagingsList, shipment?.shipmentPackagings]);
  const supports = useMemo(() => {
    if (!supportsList) {
      return '';
    }

    return shipment?.shipmentSupports
      .map(
        (support: ShipmentSupport) =>
          `${support.quantity} ${supportsList?.find((s) => s.id === support.supportId)?.label}`,
      )
      .join(' - ');
  }, [shipment?.shipmentSupports, supportsList]);
  const acceptedWithReserveCount = useMemo(
    () =>
      shipment?.statusHistory.filter(
        (el: StatusHistory) => el.withReserve === true,
      ).length,
    [shipment?.statusHistory],
  );

  const { downloadShipmentPdf, isLoading: isDownloadingShipmentPdf } =
    useDownloadShipmentPdf();

  return (
    <BaseModal
      id="shipmentDetailsModal"
      size="large"
      showCloseButton={true}
      open={open}
      onClose={onClose}
      header={{
        leftElement: (
          <IconSVG icon="chevron-left" size={16} onClick={onClose} />
        ),
        centerElement: (
          <Typography isUppercase color="text-Primary-02" size="lg" isBold>
            {t('Modals.ShipmentDetails.title')}
          </Typography>
        ),
        rightElement: <IconSVG icon="close" size={16} onClick={onClose} />,
      }}
    >
      <div className="flex w-full items-center flex-col rounded-md mt-6">
        <div className="flex w-full items-center">
          <IconSVG icon={icon} size={24} className="my-2 mr-3" />
          <div className="px-2 border-l border-Primary-02 flex items-start flex-col ">
            <Typography isUppercase isBold size="sm" sizeMd="xl">
              {t('Modals.ShipmentDetails.id')}: {shipment?.code}
            </Typography>
            {acceptedWithReserveCount > 0 && (
              <div className="flex items-center">
                <IconSVG icon="shield-warning" size={16} />
                <Typography size="xs" color="text-Primary-03" className="ml-1">
                  {t('Modals.ShipmentDetails.acceptedWithReserve', {
                    count: acceptedWithReserveCount,
                  })}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-5 gap-10 items-start">
          <div className="w-full mt-3 flex gap-2 col-span-3 flex-col">
            <DetailField
              label={t('Modals.ShipmentDetails.address')}
              value={shipment?.ownerOrganization.address}
            />
            <DetailField
              label={t('Modals.ShipmentDetails.owner')}
              value={shipment?.ownerOrganization.name}
            />
            <DetailField
              label={t('Modals.ShipmentDetails.sender')}
              value={shipment?.senderOrganization.name}
            />
            <DetailField
              label={t('Modals.ShipmentDetails.receiver')}
              value={shipment?.receiverOrganization.name}
            />
            <DetailField
              label={t('Modals.ShipmentDetails.entryDate')}
              value={entryDate ?? ''}
              isUrgent
            />
            <DetailField
              label={t('Modals.ShipmentDetails.deliveryReq')}
              value={
                shipmentCurrentStatus &&
                dayjs(shipmentCurrentStatus.setAt).format('DD/MM/YYYY')
              }
            />

            <DetailField
              label={styleIdType}
              value={
                <Select
                  value=""
                  placeholder={shipmentTotalItems}
                  onChange={() => {}}
                  options={styleId?.split(',').map((style) => ({
                    value: style,
                    label: `${styleIdType}: ${style}`,
                  }))}
                />
              }
            />
            <DetailField
              label={t('Modals.ShipmentDetails.packaging')}
              value={packagings}
            />
            <DetailField
              label={t('Modals.ShipmentDetails.supports')}
              value={supports}
            />
          </div>
          <div className="flex flex-col w-full col-span-2">
            {shipment?.statusHistory
              .slice(-SHIPMENT_HISTORY_MAX_COUNT)
              ?.map((statusHistory, index, statusHistoryArray) => {
                const isLastStatus = index === statusHistoryArray.length - 1;
                return (
                  <div
                    key={index}
                    className={`min-h-[100px] flex w-full bg-custom bg-contain ${isLastStatus ? "bg-[url('/public/delivery.svg')]" : "bg-[url('/public/delivery-grey.svg')]"} pl-10 bg-repeat-y`}
                  >
                    <div className="mt-1 w-full flex flex-col">
                      <Typography
                        isBold
                        size="sm"
                        color={
                          isLastStatus ? 'text-Primary-00' : 'text-Primary-03'
                        }
                      >
                        {t(`General.ShipmentStatus.${statusHistory.status}`)}
                      </Typography>
                      <div className="grid w-full grid-cols-4 justify-items-start items-center">
                        <Typography
                          size="xs"
                          className="col-span-3 w-full overflow-hidden truncate text-ellipsis"
                        >
                          {`${statusHistory.user.firstName} ${statusHistory.user.lastName}, ${statusHistory.user.organization.name}`}
                        </Typography>
                        {statusHistory.withReserve && (
                          <div className="col-span-1 w-full">
                            <IconSVG icon="shield-warning" size={16} />
                          </div>
                        )}
                      </div>
                      <Typography size="xs">
                        {dayjs(statusHistory.setAt).format(
                          'DD/MM/YYYY at HH:mm',
                        )}
                      </Typography>

                      {statusHistory.comment && (
                        <div className="col-span-1 w-full flex items-center gap-2">
                          <IconSVG
                            icon={
                              statusHistory.withReserve
                                ? 'shield-warning'
                                : 'tooltip'
                            }
                            size={16}
                          />
                          <Typography
                            size="xs"
                            color="text-Primary-02"
                            className="italic font-[300]"
                          >
                            {statusHistory.comment}
                          </Typography>
                        </div>
                      )}
                      {statusHistory.imageKey && (
                        <div
                          className={`flex gap-2 ${statusHistory.comment ? 'ml-4' : ''} `}
                        >
                          {statusHistory.withReserve &&
                            !statusHistory.comment && (
                              <IconSVG icon="shield-warning" size={16} />
                            )}
                          <IconSVG icon="attachment" size={16} />
                          <Typography
                            size="xs"
                            color="text-Primary-02"
                            className="font-[400] underline"
                          >
                            {statusHistory.imageKey}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        {shipment?.delayedComment && (
          <div className="grid w-full grid-cols-10 gap-2 mt-10">
            <div className="col-span-1 flex justify-start items-center ">
              <IconSVG icon={'tooltip'} size={18} />
            </div>
            <Typography size="xs" className="col-span-9">
              {shipment?.delayedComment}
            </Typography>
          </div>
        )}
        <div className="flex mt-6 w-full justify-end ">
          <button
            onClick={() => downloadShipmentPdf(shipment?.id)}
            className="flex gap-2 items-center "
          >
            <Typography size="sm" color="text-Primary-02">
              {t('Modals.ShipmentDetails.downloadPDF')}
            </Typography>
            {isDownloadingShipmentPdf ? (
              <Spinner size="sm" className="inline-flex" />
            ) : (
              <IconSVG icon="download" size={20} />
            )}
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default React.memo(ShipmentDetailsModal);
