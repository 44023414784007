import { useEffect, useRef } from 'react';
import { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { fr } from 'date-fns/locale/fr';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import objectSupport from 'dayjs/plugin/objectSupport';
import updateLocale from 'dayjs/plugin/updateLocale';

// Import day.js locales
import('dayjs/locale/fr');

dayjs.extend(updateLocale);

dayjs.extend(objectSupport);

dayjs.extend(duration);

registerLocale('fr', fr);

export const useDayjsConfig = () => {
  const hasUpdatedDayjsLocale = useRef<boolean>();
  const { i18n } = useTranslation();

  useEffect(() => {
    dayjs.locale(i18n.language);

    if (!hasUpdatedDayjsLocale.current) {
      dayjs.updateLocale('fr', {
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
      });
    }

    hasUpdatedDayjsLocale.current = true;
  }, [i18n.language]);
};
