import React, { useCallback, useEffect } from 'react';

import { IconSVG } from '@atoms/IconSVG';
import { IconNames } from '@atoms/IconSVG/definitions';
import { Typography } from '@atoms/Typography';
import { Pagination } from '@molecules/Pagination';
import { PaginationProps } from '@molecules/Pagination/definitions';
import {
  SortDirection,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { TableProps } from './definitions';

const sortIcons: Record<SortDirection, IconNames> = {
  asc: 'arrow-up',
  desc: 'arrow-down',
};

const Table = <TData,>({
  data,
  columns,
  sorting,
  onSortingChange,
  pagination,
  onPaginationChange,
  columnFilters,
  onColumnFiltersChange,
  totalResults,
  multiSorting = true,
  showPagination = true,
  paginationSideElement,
}: TableProps<TData>) => {
  const enhancedColumns: TableProps<TData>['columns'] = columns.map(
    (column) => {
      const { header, cell } = column;

      const result = { ...column };

      if (typeof header === 'string') {
        result.header = () => (
          <Typography size="xs" isBold>
            {header}
          </Typography>
        );
      }

      if (typeof cell === 'undefined') {
        result.cell = (info) => (
          <Typography size="sm">{info.getValue() as string}</Typography>
        );
      }

      return result;
    },
    [],
  );

  const table = useReactTable({
    data,
    columns: enhancedColumns,
    state: {
      sorting,
      pagination,
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    onSortingChange,
    manualPagination: true,
    onPaginationChange,
    manualFiltering: true,
    onColumnFiltersChange,
    rowCount: totalResults,
    enableMultiSort: multiSorting,
    isMultiSortEvent: () => true,
  });

  const pageCount = table.getPageCount();
  const pageIndex = table.getState().pagination.pageIndex;
  const canShowPagination = showPagination && pageCount > 0;

  const handlePageChange = useCallback<PaginationProps['onChange']>(
    (...params) => {
      const action = params[0];

      if (action === 'first' && table.getCanPreviousPage()) {
        table.firstPage();
        return;
      }

      if (action === 'prev' && table.getCanPreviousPage()) {
        table.previousPage();
        return;
      }

      if (action === 'last' && table.getCanNextPage()) {
        table.lastPage();
        return;
      }

      if (action === 'next' && table.getCanNextPage()) {
        table.nextPage();
        return;
      }

      if (action === 'page') {
        table.setPageIndex(params[1]);
      }
    },
    [table],
  );

  // When pageindex falls outside of page count
  // reset page index to 0
  useEffect(() => {
    if (pageIndex >= pageCount) {
      table.setPageIndex(0);
    }
  }, [pageCount, pageIndex, table]);

  return (
    <div className="w-full">
      <div className="w-full overflow-y-scroll">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const headClasses = ['flex gap-1 items-center'];
                    let headProps = {};

                    const columnCanBeSorted = header.column.getCanSort();
                    const columnIsSorted = header.column.getIsSorted();

                    if (columnCanBeSorted) {
                      headClasses.push('cursor-pointer select-none');
                      headProps = {
                        ...headProps,
                        onClick: header.column.getToggleSortingHandler(),
                      };
                    }

                    headProps = {
                      ...headProps,
                      className: headClasses.join(' '),
                    };

                    if (header.isPlaceholder) {
                      return (
                        <th
                          key={header.id}
                          className={
                            'border-b border-[#EAECF0] p-3 text-left bg-Primary-05'
                          }
                        >
                          {null}
                        </th>
                      );
                    }

                    return (
                      <th
                        key={header.id}
                        className={
                          'border-b border-[#EAECF0] p-3 text-left bg-Primary-05'
                        }
                      >
                        <div {...headProps}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {columnCanBeSorted && columnIsSorted !== false && (
                            <div className="grow-0 shrink-0">
                              <IconSVG
                                size={16}
                                icon={sortIcons[columnIsSorted]}
                              />
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell, cellIdx) => {
                  const bgColor =
                    cellIdx % 2 === 0 ? 'bg-Primary-05' : 'bg-[#F9F9F9]';

                  return (
                    <td
                      key={cell.id}
                      className={`border-b border-[#EAECF0] p-3 text-left ${bgColor}`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {(paginationSideElement || canShowPagination) && (
        <div className="flex flex-row items-center justify-between gap-3 pt-4 pb-1 px-3">
          <div>{paginationSideElement}</div>
          <div>
            {canShowPagination && (
              <Pagination
                total={pageCount}
                current={pageIndex}
                onChange={handlePageChange}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Table) as typeof Table;
