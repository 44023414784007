import React, { useMemo } from 'react';

import { PaginationSlot } from './PaginationSlot';
import { PaginationProps } from './definitions';

const BOUNDARY_COUNT = 1;
const SIBLING_COUNT = 2;

const Pagination = ({ total, current, onChange }: PaginationProps) => {
  const showFirstSlot = useMemo(() => current > 0, [current]);
  const showPrevSlot = useMemo(() => current > 0, [current]);
  const showLastSlot = useMemo(() => current < total - 1, [current, total]);
  const showNextSlot = useMemo(() => current < total - 1, [current, total]);

  const pageList = useMemo(() => {
    // Create array for all pages
    const pages = Array.from({ length: total }).map((_, idx) => idx);

    // Create array for pages before current one
    // Create arrays for left boundary and sibling pages
    // and check if left dots should be shown
    const prevPages = pages.slice(0, current);
    const leftBoundaryPages = prevPages.splice(0, BOUNDARY_COUNT);
    const leftSiblingPages = prevPages.splice(-SIBLING_COUNT);
    const showLeftDots = prevPages.length > 0;

    // Create array for pages after current one
    // Create arrays for right boundary and sibling pages
    // and check if right dots should be shown
    const nextPages = pages.slice(current + 1);
    const rightBoundaryPages = nextPages.splice(-BOUNDARY_COUNT);
    const rightSiblingPages = nextPages.splice(0, SIBLING_COUNT);
    const showRightDots = nextPages.length > 0;

    // Return flattened array with pages and dots
    return [
      leftBoundaryPages,
      showLeftDots ? 'dots' : null,
      leftSiblingPages,
      current,
      rightSiblingPages,
      showRightDots ? 'dots' : null,
      rightBoundaryPages,
    ]
      .flat()
      .filter((p): p is number | 'dots' => p !== null);
  }, [current, total]);

  return (
    <div className="flex flex-row gap-1">
      {showFirstSlot && (
        <PaginationSlot
          type="arrow"
          icon="page-first"
          onClick={() => onChange('first')}
        />
      )}
      {showPrevSlot && (
        <PaginationSlot
          type="arrow"
          icon="page-prev"
          onClick={() => onChange('prev')}
        />
      )}
      {pageList.map((page, pageIdx) => {
        if (page === 'dots') {
          return <PaginationSlot key={`${pageIdx}-dots`} type="dots" />;
        }

        const isActive = page === current;

        return (
          <PaginationSlot
            key={`${pageIdx}-${page}`}
            type="number"
            value={page + 1}
            isActive={isActive}
            onClick={!isActive ? () => onChange('page', page) : undefined}
          />
        );
      })}
      {showNextSlot && (
        <PaginationSlot
          type="arrow"
          icon="page-next"
          onClick={() => onChange('next')}
        />
      )}
      {showLastSlot && (
        <PaginationSlot
          type="arrow"
          icon="page-last"
          onClick={() => onChange('last')}
        />
      )}
    </div>
  );
};

export default React.memo(Pagination);
