import { useState } from 'react';

import { useShipmentApi } from '@api/ShipmentApi';

export const useDownloadShipmentPdf = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { getShipmentPdf } = useShipmentApi();

  async function downloadShipmentPdf(shipmentId: number) {
    setIsLoading(true);
    const data = await getShipmentPdf(shipmentId);
    setIsLoading(false);
    if (data) {
      try {
        const blob = new Blob([data], { type: 'application/pdf' });
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `shipment_${shipmentId}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } catch (error) {
        console.error('Error processing PDF:', error);
      }
    } else {
      console.error('No PDF data available');
    }
  }

  return {
    isLoading,
    downloadShipmentPdf,
  };
};
