import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useModalsContext } from '@contexts/modals';
import { useShipmentContext } from '@contexts/shipment';

export const useShipmentBaseModal = ({ onClose }: { onClose?: () => void }) => {
  const { t } = useTranslation();
  const { showConfirmationModal, hideAllShipmentModals } = useModalsContext();
  const { reset: resetShipmentContext } = useShipmentContext();

  const onDismiss = useCallback(() => {
    showConfirmationModal({
      onConfirm: () => {
        hideAllShipmentModals();
        resetShipmentContext();
        onClose?.();
      },
      title: t('Modals.ShipmentConfirmation.Dismiss.title'),
      content: t('Modals.ShipmentConfirmation.Dismiss.description'),
    });
  }, [
    hideAllShipmentModals,
    onClose,
    resetShipmentContext,
    showConfirmationModal,
    t,
  ]);

  return {
    onDismiss,
  };
};
