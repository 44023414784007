import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useShipmentApi } from '@api/ShipmentApi';
import { Button } from '@atoms/Button';
import { Spinner } from '@atoms/Spinner';
import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { useReceiveContext } from '@contexts/receive';
import { useUserContext } from '@contexts/user';
import { QueryKeys } from '@definitions/QueryKeys';
import { useInvalidateQueries } from '@hooks/useInvalidateQueries';
import useToast from '@hooks/useToast';
import ActivityCard from '@molecules/ActivityCard/ActivityCard';
import { FormField } from '@molecules/Form/FormField';
import { Select } from '@molecules/Form/Select';
import { ReceiveBaseModal } from '@organisms/Modals/ReceiveFlow/ReceiveBaseModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isNullOrUndefined } from '@utils/typeCheck';

import {
  ReceiveShipmentDetailsModalProps,
  ReceiverFunction,
} from './definitions';

const ReceiveShipmentDetailsModal: React.FC<
  ReceiveShipmentDetailsModalProps
> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { invalidateAllShipmentQueries } = useInvalidateQueries();
  const { getShipment, receiveShipment, getShipmentByCode, pickUpShipment } =
    useShipmentApi();

  const { isDriver } = useUserContext();

  const {
    showConfirmationModal,
    showReceiveRemarksModal,
    showResumeConfirmationModal,
    hideAllReceiveModals,
  } = useModalsContext();

  const { showWarningToast } = useToast();

  const {
    selectedShipmentId,
    setReceivedShipment,
    selectedShipmentCode,
    receiverFunction,
    setReceiverFunction,
    flow,
    isFinalUser,
    reset: resetReceiveContext,
  } = useReceiveContext();

  const { mutate: callReceiveShipment } = useMutation({
    mutationFn: receiveShipment,
  });
  const { mutate: callPickUpShipment } = useMutation({
    mutationFn: pickUpShipment,
  });

  const isQueryEnabled = useMemo(
    () =>
      open ||
      (selectedShipmentId !== undefined && selectedShipmentCode !== undefined),
    [open, selectedShipmentCode, selectedShipmentId],
  );

  const {
    data: shipment,
    isError: shipmentNotFound,
    isLoading: isLoadingShipment,
  } = useQuery({
    queryKey: [QueryKeys.SHIPMENT, selectedShipmentId, selectedShipmentCode],
    queryFn: () =>
      selectedShipmentCode
        ? getShipmentByCode(selectedShipmentCode!)
        : getShipment({ shipmentId: selectedShipmentId! }),
    enabled: isQueryEnabled,
    retry: false,
  });

  const roleSelectOptions = useMemo(() => {
    const shipmentStatus = shipment?.status;

    const finalAddressee = {
      value: ReceiverFunction.FINAL_ADDRESSEE,
      label: t('Modals.ReceiveFlow.ShipmentDetails.final_addressee'),
    };

    const intermediary = {
      value: ReceiverFunction.INTERMEDIATE,
      label: t('Modals.ReceiveFlow.ShipmentDetails.intermediary'),
    };

    if (shipmentStatus === 'arrived') {
      return [finalAddressee];
    }

    if (shipmentStatus === 'moving' || shipmentStatus === 'validated') {
      return [intermediary, finalAddressee];
    }

    return [];
  }, [shipment?.status, t]);

  useEffect(() => {
    if (shipment?.status === 'arrived') {
      setReceiverFunction(ReceiverFunction.FINAL_ADDRESSEE);
    }
  }, [setReceiverFunction, shipment?.status]);

  const isValidStatus = useMemo(
    () =>
      !isDriver &&
      (shipment?.status === 'arrived' ||
        shipment?.status === 'moving' ||
        shipment?.status === 'validated'),
    [isDriver, shipment?.status],
  );

  const isButtonDisabled = useMemo(
    () =>
      !isDriver &&
      (!receiverFunction ||
        !isValidStatus ||
        isLoadingShipment ||
        shipmentNotFound),
    [
      isDriver,
      isLoadingShipment,
      isValidStatus,
      receiverFunction,
      shipmentNotFound,
    ],
  );

  return (
    <ReceiveBaseModal
      id="receiveShipmentDetailsModal"
      open={open}
      onClose={onClose}
      title={t('Modals.ReceiveFlow.ShipmentDetails.title', { context: flow })}
      size="large"
      Footer={
        <div>
          <Button
            disabled={isButtonDisabled}
            className="w-full mt-4"
            type="primary"
            label={t('Modals.ReceiveFlow.ShipmentDetails.accept', {
              context: flow,
            })}
            onClick={() =>
              showConfirmationModal({
                title: t('Modals.ReceiveFlow.ShipmentDetails.accept', {
                  context: flow,
                }),
                content: t('Modals.ReceiveFlow.ShipmentDetails.acceptMessage', {
                  context: flow,
                }),
                onConfirm: () => {
                  if (shipment?.id === undefined) {
                    showWarningToast(t('ErrorPage.somethingWrong'));
                    return;
                  }

                  if (flow === 'driver') {
                    callPickUpShipment(
                      {
                        shipmentID: shipment.id,
                        withReserve: false,
                      },
                      {
                        onSuccess: () => {
                          showResumeConfirmationModal({
                            content: t(
                              'Modals.CheckingPendingShipment.success',
                              {
                                context: flow,
                              },
                            ),
                            onConfirm: () => {
                              onClose();
                              hideAllReceiveModals();
                              resetReceiveContext();
                            },
                            code: shipment!.code || '',
                          });
                          invalidateAllShipmentQueries();
                        },
                        onError: () => {
                          showWarningToast(t('ErrorPage.somethingWrong'));
                        },
                      },
                    );

                    return;
                  }

                  callReceiveShipment(
                    {
                      shipmentID: shipment.id,
                      isFinalUser,
                    },
                    {
                      onSuccess: () => {
                        showResumeConfirmationModal({
                          onConfirm: () => {
                            onClose();
                            hideAllReceiveModals();
                            resetReceiveContext();
                          },
                          code: shipment!.code!,
                          content: t('Modals.CheckingPendingShipment.success', {
                            context: flow,
                          }),
                        });
                        invalidateAllShipmentQueries();
                      },
                      onError: () => {
                        showWarningToast(t('ErrorPage.somethingWrong'));
                      },
                    },
                  );
                },
              })
            }
          />
          <Button
            disabled={isButtonDisabled}
            className="w-full mt-4"
            type="primary"
            label={t('Modals.ReceiveFlow.ShipmentDetails.acceptWhitReserve', {
              context: flow,
            })}
            onClick={() =>
              showConfirmationModal({
                title: t(
                  'Modals.ReceiveFlow.ShipmentDetails.acceptWhitReserve',
                  { context: flow },
                ),
                content: t(
                  'Modals.ReceiveFlow.ShipmentDetails.acceptWhitReserveMessage',
                  { context: flow },
                ),
                onConfirm: () => {
                  if (!isNullOrUndefined(shipment?.id)) {
                    setReceivedShipment('id', shipment.id);
                    setReceivedShipment('code', shipment?.code || undefined);
                  }
                  showReceiveRemarksModal();
                },
              })
            }
          />
        </div>
      }
    >
      {shipment && (
        <>
          <ActivityCard shipment={shipment} canChangeStatus={false} />
          {isValidStatus && (
            <div className="mt-10 px-3">
              <FormField
                label={t('Modals.ReceiveFlow.ShipmentDetails.role') + ' *'}
                direction={'row'}
                isLabelBold
              >
                <Select
                  value={receiverFunction || ''}
                  disabled={shipment?.status === 'arrived'}
                  onChange={(value) =>
                    setReceiverFunction(value as ReceiverFunction)
                  }
                  options={roleSelectOptions}
                  placeholder={t(
                    'Modals.ReceiveFlow.ShipmentDetails.select_role',
                  )}
                />
              </FormField>
            </div>
          )}
        </>
      )}

      {(isLoadingShipment || shipmentNotFound) && (
        <div className="flex items-center justify-center h-[200px]">
          {isLoadingShipment && <Spinner />}
          {shipmentNotFound && (
            <Typography color="text-Primary-03">
              {t('Modals.ReceiveFlow.ShipmentDetails.notFound')}
            </Typography>
          )}
        </div>
      )}
    </ReceiveBaseModal>
  );
};

export default React.memo(ReceiveShipmentDetailsModal);
