import React from 'react';

import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';

import { FormFieldProps } from './definitions';

const FormField: React.FC<FormFieldProps> = ({
  label,
  isLabelBold,
  required,
  disabled,
  children,
  direction = 'row',
  info,
  icon,
}) => {
  return (
    <div className="flex flex-col items-stretch justify-start gap-2">
      <div
        className={`${direction === 'row' ? 'grid grid-cols-5 items-center gap-4' : ''}`}
      >
        <div className="col-span-2 flex flex-row items-center justify-start">
          <div className="flex flex-row items-center gap-2">
            {icon && <IconSVG icon={icon} size={16} />}
            <Typography
              size="md"
              color={disabled ? 'text-Primary-03' : 'text-Primary-00'}
              isBold={isLabelBold}
            >
              {`${label}:`}
            </Typography>
          </div>
          {required && (
            <Typography
              size="md"
              color={disabled ? 'text-Primary-03' : 'text-Primary-00'}
            >
              *
            </Typography>
          )}
        </div>

        <div className="col-span-3">{children}</div>
      </div>
      {info && (
        <div className="grid grid-cols-5 items-center gap-4">
          <div
            className={`${direction === 'row' ? 'col-start-3 col-span-3' : 'col-start-1 col-span-5'}`}
          >
            <Typography size="sm" color="text-Primary-03">
              {info}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(FormField);
