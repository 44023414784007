import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganizationsApi } from '@api/OrganizationsApi';
import { QueryKeys } from '@definitions/QueryKeys';
import useWindow from '@hooks/useWindow';
import { FormField } from '@molecules/Form/FormField';
import { Select } from '@molecules/Form/Select';
import { useQuery } from '@tanstack/react-query';

import { FilterCommonProps } from './definitions';

export const OrganizationTypeFilter = ({
  value,
  onChange,
}: FilterCommonProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindow();
  const { getOrganizationTypeList } = useOrganizationsApi();

  const { data: organizationTypesData } = useQuery({
    queryKey: [QueryKeys.ORGANIZATIONS_TYPE_LIST],
    queryFn: getOrganizationTypeList,
  });

  const organizationTypesOptions = useMemo(() => {
    if (!organizationTypesData) {
      return [];
    }

    return organizationTypesData.map((item) => ({
      value: item.id.toString(),
      label: item.label,
    }));
  }, [organizationTypesData]);

  return (
    <FormField
      label={t('Filters.organizationType.label')}
      direction={isMobile ? 'row' : 'column'}
    >
      <Select
        value={value}
        onChange={onChange}
        options={organizationTypesOptions}
        placeholder={t('Filters.organizationType.placeholder')}
      />
    </FormField>
  );
};
