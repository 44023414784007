import { useCallback } from 'react';

import usePrivateApi from 'src/api/base/private';

import { useLogger } from '@hooks/useLogger';
import { buildQuery } from '@utils/buildQuery';

import {
  CreateOrganizationParams,
  GetOrganizationListParams,
  GetOrganizationListResponse,
  GetOrganizationPayload,
  GetOrganizationResponse,
  GetOrganizationTypeListResponse,
  GetOrganizationTypeParams,
  GetOrganizationTypeResponse,
  GetOrganizationsRecipientsPayload,
  GetOrganizationsRecipientsResponse,
  UpdateOrganizationStatusParams,
} from './definitions';

export const useOrganizationsApi = () => {
  const { logError } = useLogger();

  const privateApi = usePrivateApi();

  // Get addresses
  const getOrganizationsRecipients = useCallback(
    async ({
      page,
      perPage,
      textFilter,
    }: GetOrganizationsRecipientsPayload) => {
      const query = buildQuery({
        page,
        perPage,
        textFilter,
      });

      try {
        const { data } =
          await privateApi.get<GetOrganizationsRecipientsResponse>(
            `/organizations/recipients${query}`,
          );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const getOrganizationList = useCallback(
    async (params?: GetOrganizationListParams) => {
      try {
        const { data } = await privateApi.get<GetOrganizationListResponse>(
          '/organizations',
          {
            params,
          },
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const getOrganization = useCallback(
    async ({ organizationId }: GetOrganizationPayload) => {
      try {
        const { data } = await privateApi.get<GetOrganizationResponse>(
          `/organizations/${organizationId}`,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const getOrganizationType = useCallback(
    async (payload: GetOrganizationTypeParams) => {
      try {
        const { data } = await privateApi.get<GetOrganizationTypeResponse>(
          `/organization-types/${payload.id}`,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const getOrganizationTypeList = useCallback(async () => {
    try {
      const { data } = await privateApi.get<GetOrganizationTypeListResponse>(
        '/organization-types',
      );
      return data;
    } catch (error) {
      logError(error);
      throw error;
    }
  }, [logError, privateApi]);

  const updateOrganizationStatus = useCallback(
    async ({ id, enabled }: UpdateOrganizationStatusParams) => {
      try {
        await privateApi.patch(`/organizations/${id}/toggle`, {
          enabled,
        });
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const createOrganization = useCallback(
    async (payload: CreateOrganizationParams) => {
      try {
        await privateApi.post('/organizations', payload);
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );
  // Return API functions
  return {
    getOrganizationsRecipients,
    getOrganizationList,
    getOrganization,
    getOrganizationTypeList,
    getOrganizationType,
    updateOrganizationStatus,
    createOrganization,
  };
};
