import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSupportsApi } from '@api/supportsApi';
import {
  GetSupportsParams,
  GetSupportsResponse,
  SupportItem,
} from '@api/supportsApi/definitions';
import { IconSVG } from '@atoms/IconSVG';
import { useModalsContext } from '@contexts/modals';
import { QueryKeys } from '@definitions/QueryKeys';
import useToast from '@hooks/useToast';
import { Toggle } from '@molecules/Form/Toggle';
import { useTableState } from '@molecules/Table/tableState';
import { AdminTableCard } from '@organisms/AdminTableCard';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import imagePlaceholder from '@theme/assets/image-placeholder.svg';

const Supports = () => {
  const { t } = useTranslation();

  const { showWarningToast } = useToast();
  const { updateSupport, getSupport } = useSupportsApi();

  const {
    showConfirmationModal,
    showEditGenericItemModal,
    showAddGenericItemModal,
  } = useModalsContext();

  const queryClient = useQueryClient();

  const { getSupports, updateSupportStatus, createSupport } = useSupportsApi();

  const tableState = useTableState({
    defaultSorting: [
      { id: 'enabled', desc: true },
      { id: 'label', desc: true },
    ],
  });

  const queryParams = useMemo<GetSupportsParams>(() => {
    const { sorting } = tableState;

    const params: GetSupportsParams = {};

    if (sorting.length > 0) {
      params.orderBy = sorting.map(({ id, desc }) => ({
        columnName: id,
        sortDirection: desc ? 'desc' : 'asc',
      }));
    }

    return params;
  }, [tableState]);

  const { data: supportData, isLoading } = useQuery({
    queryKey: [QueryKeys.SHIPMENT_SUPPORTS, queryParams],
    queryFn: () => getSupports(queryParams),
    placeholderData: keepPreviousData,
  });

  const { mutate: callUpdateSupportStatus } = useMutation({
    mutationFn: updateSupportStatus,
    onMutate: async ({ id, enabled }) => {
      const queryKey = [QueryKeys.SHIPMENT_SUPPORTS, queryParams];

      await queryClient.cancelQueries({
        queryKey: queryKey,
      });

      const previousData =
        queryClient.getQueryData<GetSupportsResponse>(queryKey);

      if (previousData) {
        queryClient.setQueryData<GetSupportsResponse>(
          queryKey,
          previousData.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                enabled,
              };
            }
            return item;
          }),
        );
      }

      return { previousData };
    },
    onError: (_error, _variables, context) => {
      if (context?.previousData) {
        queryClient.setQueryData<GetSupportsResponse>(
          [QueryKeys.SHIPMENT_SUPPORTS, queryParams],
          context.previousData,
        );
      }

      showWarningToast(t('Modals.SupportStatusConfirmation.errorMessage'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.SHIPMENT_SUPPORTS, queryParams],
      });
    },
  });

  const tableData = useMemo(() => {
    return supportData ?? [];
  }, [supportData]);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<SupportItem>();

    return [
      columnHelper.accessor('label', {
        header: t('ItemsPage.supports.table.columns.name'),
        enableSorting: true,
      }),
      columnHelper.accessor('image', {
        header: t('ItemsPage.supports.table.columns.image'),
        enableSorting: false,
        cell: (info) => {
          const imageSrc = info.getValue();
          return (
            <div className="flex flex-row items-center justify-between gap-2">
              <img
                className="w-[40px] h-auto"
                src={imageSrc || imagePlaceholder}
              />
              <IconSVG
                icon="edit"
                size={18}
                onClick={() => {
                  showEditGenericItemModal({
                    getItemQueryKey: [
                      QueryKeys.SHIPMENT_SUPPORTS,
                      Number(info.row.original.id),
                    ],
                    categoryId: Number(info.row.original.id),
                    updateItem: updateSupport,
                    getItem: getSupport,
                    editItemLabel: 'EditSupport',
                    addEditItemLabel: 'AddEditSupport',
                    onEditItem: () => {
                      queryClient.invalidateQueries({
                        queryKey: [QueryKeys.SHIPMENT_SUPPORTS],
                        exact: false,
                      });
                      queryClient.invalidateQueries({
                        queryKey: [
                          QueryKeys.SHIPMENT_SUPPORTS,
                          Number(info.row.original.id),
                        ],
                      });
                    },
                  });
                }}
              />
            </div>
          );
        },
      }),
      columnHelper.accessor('enabled', {
        header: t('ItemsPage.supports.table.columns.status'),
        enableSorting: true,
        cell: (info) => {
          return (
            <div className="flex flex-row items-center">
              <Toggle
                checked={info.getValue()}
                onChange={(checked) =>
                  showConfirmationModal({
                    title: t('Modals.SupportStatusConfirmation.title'),
                    content: t('Modals.SupportStatusConfirmation.content'),
                    onConfirm: () => {
                      callUpdateSupportStatus({
                        id: Number(info.row.original.id),
                        enabled: checked,
                      });
                    },
                  })
                }
              />
            </div>
          );
        },
      }),
    ];
  }, [
    callUpdateSupportStatus,
    getSupport,
    queryClient,
    showConfirmationModal,
    showEditGenericItemModal,
    t,
    updateSupport,
  ]);

  return (
    <AdminTableCard
      isLoading={isLoading}
      title={t('ItemsPage.supports.table.title')}
      table={{
        data: tableData,
        columns,
        ...tableState,
        showPagination: false,
      }}
      footerActions={[
        {
          label: t('ItemsPage.supports.add'),
          onClick: () =>
            showAddGenericItemModal({
              createItem: createSupport,
              addItemLabelNs: 'AddSupport',
              addEditItemLabelNs: 'AddEditSupport',
              showImageField: true,
              onItemCreated: () => {
                queryClient.invalidateQueries({
                  queryKey: [QueryKeys.SHIPMENT_SUPPORTS],
                  exact: false,
                });
              },
            }),
        },
      ]}
    />
  );
};

export default React.memo(Supports);
