import { FunctionComponent, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { useUserContext } from '@contexts/user';
import { Roles } from '@definitions/Users';
import { FourZeroFour } from '@pages/404';
import { ForgotPassword } from '@pages/ForgotPassword';
import Homepage from '@pages/Homepage/Homepage.tsx';
import { InStockPage } from '@pages/InStockPage';
import { ItemsPage } from '@pages/ItemsPage';
import { Login } from '@pages/Login';
import { OrganizationsPage } from '@pages/OrganizationsPage';
import { ResetPassword } from '@pages/ResetPassword';
import { HistoryPage, InCoursePage } from '@pages/ShipmentsListPage';
import PendingPage from '@pages/ShipmentsListPage/PendingPage';
import { StatisticsPage } from '@pages/StatisticsPage';
import { UsersPage } from '@pages/UsersPage';

import Authenticated from './middleware/Authenticated';
import Guest from './middleware/Guest';
import { AnonymousRoutes, AuthenticatedRoutes } from './routes';

export const Navigation: FunctionComponent = () => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const { isAdmin } = useUserContext();

  useEffect(() => {
    setInitialized(true);
  }, [setInitialized]);

  if (initialized) {
    return (
      <Router>
        <Routes>
          {/* Add all guest routes as child of this middleware route */}
          <Route element={<Guest />}>
            <Route path={AnonymousRoutes.LOGIN} element={<Login />} />
            <Route
              path={AnonymousRoutes.FORGOT_PASSWORD}
              element={<ForgotPassword />}
            />
            <Route
              path={AnonymousRoutes.RESET_PASSWORD}
              element={<ResetPassword />}
            />
          </Route>

          {/* USER */}
          <Route
            path={AuthenticatedRoutes.PENDING_PAGE}
            element={
              <Authenticated roles={[Roles.USER]} element={<PendingPage />} />
            }
          />

          {/* USER + DRIVER */}
          <Route
            path={AuthenticatedRoutes.HOME_PAGE}
            element={
              <Authenticated
                roles={[Roles.USER, Roles.DRIVER]}
                element={<Homepage />}
                otherRolesFallbackRoute={
                  isAdmin ? AuthenticatedRoutes.HISTORY_PAGE : undefined
                }
              />
            }
          />

          {/* USER + ADMIN */}
          <Route
            path={AuthenticatedRoutes.HISTORY_PAGE}
            element={
              <Authenticated
                roles={[Roles.USER, Roles.ADMIN, Roles.DRIVER]}
                element={<HistoryPage />}
              />
            }
          />

          <Route
            path={AuthenticatedRoutes.IN_COURSE_PAGE}
            element={
              <Authenticated
                roles={[Roles.USER, Roles.ADMIN]}
                element={<InCoursePage />}
              />
            }
          />
          <Route
            path={AuthenticatedRoutes.STATISTICS_PAGE}
            element={
              <Authenticated
                roles={[Roles.USER, Roles.ADMIN]}
                element={<StatisticsPage />}
              />
            }
          />

          {/* ADMIN */}

          <Route
            path={AuthenticatedRoutes.HISTORY_PAGE}
            element={
              <Authenticated roles={[Roles.ADMIN]} element={<HistoryPage />} />
            }
          />
          <Route
            path={AuthenticatedRoutes.USERS_PAGE}
            element={
              <Authenticated roles={[Roles.ADMIN]} element={<UsersPage />} />
            }
          />
          <Route
            path={AuthenticatedRoutes.ORGANIZATIONS_PAGE}
            element={
              <Authenticated
                roles={[Roles.ADMIN]}
                element={<OrganizationsPage />}
              />
            }
          />
          <Route
            path={AuthenticatedRoutes.ITEMS_PAGE}
            element={
              <Authenticated roles={[Roles.ADMIN]} element={<ItemsPage />} />
            }
          />
          <Route
            path={AuthenticatedRoutes.IN_STOCK_PAGE}
            element={
              <Authenticated roles={[Roles.ADMIN]} element={<InStockPage />} />
            }
          />

          {/* Handle all non matching routes */}
          <Route path="*" element={<FourZeroFour />} />
        </Routes>
      </Router>
    );
  } else {
    return <></>;
  }
};
