import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UpdateProductCategoryParams } from '@api/productApi/definitions';
import { Button } from '@atoms/Button';
import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFilePreview } from '@hooks/useFilePreview';
import useToast from '@hooks/useToast';
import { FileInputButton } from '@molecules/FileInputButton';
import { FormField } from '@molecules/Form/FormField';
import { Input } from '@molecules/Form/Input';
import { useMutation, useQuery } from '@tanstack/react-query';
import { LanguageId } from '@translations/definitions';

import { BaseModal } from '../BaseModal';
import { EditGenericItemModalProps, FormValues } from './definitions';
import { useValidationSchema } from './validation';

const EditGenericItemModal = ({
  open,
  onClose,
  categoryId,
  updateItem,
  editItemLabel,
  addEditItemLabel,
  getItem,
  onEditItem,
  getItemQueryKey,
}: EditGenericItemModalProps) => {
  const { t } = useTranslation();

  const validation = useValidationSchema();

  const { showSuccessToast, showWarningToast } = useToast();

  const { data: item } = useQuery({
    queryKey: getItemQueryKey,
    queryFn: () => getItem(categoryId),
    enabled: open,
  });

  const frLabel = useMemo(() => {
    return item?.label.find(
      (label) => label.languageId.toString() === LanguageId.FR,
    )?.label;
  }, [item]);

  const enLabel = useMemo(
    () =>
      item?.label.find((label) => label.languageId.toString() === LanguageId.EN)
        ?.label,
    [item?.label],
  );

  const { control, handleSubmit, reset } = useForm<FormValues>({
    resolver: yupResolver(validation),
    defaultValues: {
      nameFR: frLabel,
      nameEN: enLabel,
    },
  });

  const isImageChanged = useWatch({
    control,
    name: 'image',
  });

  useEffect(
    () => reset({ nameEN: enLabel, nameFR: frLabel }),
    [enLabel, frLabel, item, open, reset],
  );

  const imageFileValue = useWatch({
    control,
    name: 'image',
  });

  const { previewUrl } = useFilePreview(imageFileValue ?? null);

  const handleClose = useCallback(() => {
    reset();
    onClose?.();
  }, [onClose, reset]);

  const { mutateAsync: callUpdateItem, isPending: isUpdatingItem } =
    useMutation({
      mutationFn: updateItem,
      onSuccess: () => {
        showSuccessToast(t(`Modals.${editItemLabel}.successMessage`));
        onEditItem?.();
        handleClose();
      },
      onError: () => {
        showWarningToast(t(`Modals.${editItemLabel}.errorMessage`));
      },
    });

  const onSubmit = useCallback<SubmitHandler<FormValues>>(
    async ({ nameFR, nameEN, image }) => {
      const params: UpdateProductCategoryParams = {
        id: categoryId,
        labels: nameEN
          ? [
              { languageId: LanguageId.FR, label: nameFR },
              { languageId: LanguageId.EN, label: nameEN },
            ]
          : [
              { languageId: LanguageId.FR, label: nameFR },
              { languageId: LanguageId.EN, label: nameFR },
            ],
      };

      if (image) {
        params.image = image;
      }

      await callUpdateItem(params);
    },
    [callUpdateItem, categoryId],
  );

  return (
    <BaseModal
      id="editProductCategoryModal"
      title={t(`Modals.${editItemLabel}.title`)}
      size="medium"
      showCloseButton={true}
      open={open}
      onClose={handleClose}
    >
      <div className="flex flex-col gap-9 items-stretch mt-4">
        <div className="flex flex-col gap-6 items-stretch">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <Typography size="lg" isBold>
                {t(`Modals.${addEditItemLabel}.fields.name.title`)}
              </Typography>
              <Controller
                control={control}
                name="nameFR"
                render={({
                  field: { name, value, onChange },
                  fieldState: { error },
                }) => (
                  <FormField
                    label={t(`Modals.${addEditItemLabel}.fields.name.label`, {
                      context: 'fr',
                    })}
                    required
                    direction="column"
                  >
                    <Input
                      name={name}
                      value={value}
                      onChange={onChange}
                      placeholder={t(
                        `Modals.${addEditItemLabel}.fields.name.placeholder`,
                      )}
                      error={error?.message}
                    />
                  </FormField>
                )}
              />
              <Controller
                control={control}
                name="nameEN"
                render={({
                  field: { name, value, onChange },
                  fieldState: { error },
                }) => (
                  <FormField
                    label={t(`Modals.${addEditItemLabel}.fields.name.label`, {
                      context: 'en',
                    })}
                    direction="column"
                  >
                    <Input
                      name={name}
                      value={value}
                      onChange={onChange}
                      placeholder={t(
                        `Modals.${addEditItemLabel}.fields.name.placeholder`,
                      )}
                      error={error?.message}
                    />
                  </FormField>
                )}
              />
            </div>
            <Typography size="lg" isBold>
              {t(`Modals.${addEditItemLabel}.fields.image.title`)}
            </Typography>
            <Controller
              control={control}
              name="image"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <div className="flex flex-col gap-2 items-start">
                  <div className="flex flex-row gap-5 items-start">
                    {previewUrl ? (
                      <div className="w-[70px] h-[70px] border border-Primary-04 rounded-[5px] flex flex-row items-center justify-center overflow-hidden">
                        <img className="w-[40px] h-auto" src={previewUrl} />
                      </div>
                    ) : (
                      <div className="w-[70px] h-[70px] border border-Primary-04 rounded-[5px] flex flex-row items-center justify-center overflow-hidden">
                        <img className="w-[40px] h-auto" src={item?.image} />
                      </div>
                    )}
                    <div className="flex-1 flex flex-col items-start gap-2">
                      <Typography size="md">
                        {t(`Modals.${addEditItemLabel}.fields.image.label`)}
                      </Typography>
                      <Typography size="md" color="text-Primary-03">
                        {t(
                          `Modals.${addEditItemLabel}.fields.image.acceptedFiles`,
                        )}
                      </Typography>
                      <FileInputButton
                        onChange={onChange}
                        accept="image/*"
                        element={({ onClick, onClear }) => (
                          <>
                            <Button
                              type="secondary"
                              label={t(
                                `Modals.${addEditItemLabel}.fields.image.buttonLabel`,
                              )}
                              onClick={onClick}
                              disabled={isUpdatingItem}
                            />
                            <div className="w-full flex flex-row items-center justify-between">
                              <Typography
                                size="md"
                                color="text-Primary-02"
                                className="truncate overflow-hidden text-ellipsis w-[90%]"
                              >
                                {value
                                  ? value.name
                                  : t(
                                      `Modals.${addEditItemLabel}.fields.image.noFile`,
                                    )}
                              </Typography>
                              {isImageChanged && (
                                <IconSVG
                                  icon="trash"
                                  size={18}
                                  onClick={onClear}
                                  disabled={isUpdatingItem}
                                />
                              )}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  </div>
                  {error && (
                    <Typography size="xs" sizeMd="sm" color="text-System-red">
                      {error?.message}
                    </Typography>
                  )}
                </div>
              )}
            />
          </div>
        </div>
        <div className="flex flex-row gap-3 items-center">
          <Button
            className="flex-1"
            type="secondary"
            label={t('General.cancel')}
            onClick={handleClose}
          />
          <Button
            className="flex-1"
            type="primary"
            label={t('General.save')}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default React.memo(EditGenericItemModal);
