import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useShipmentApi } from '@api/ShipmentApi';
import { IconSVG } from '@atoms/IconSVG';
import { Textarea } from '@atoms/Textarea';
import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { useReceiveContext } from '@contexts/receive';
import { useInvalidateQueries } from '@hooks/useInvalidateQueries';
import useToast from '@hooks/useToast';
import { FileInputButton } from '@molecules/FileInputButton';
import { ReceiveBaseModal } from '@organisms/Modals/ReceiveFlow/ReceiveBaseModal';
import { ReceiveRemarksModalProps } from '@organisms/Modals/ReceiveFlow/ReceiveRemarksModal/definitions';
import { useMutation } from '@tanstack/react-query';

const ReceiveRemarksModal: React.FC<ReceiveRemarksModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const { invalidateAllShipmentQueries } = useInvalidateQueries();

  const {
    receivedShipment,
    setReceivedShipment,
    reset: resetReceiveContext,
    isFinalUser,
    flow,
  } = useReceiveContext();

  const {
    showShipmentImagePreviewModal,
    showConfirmationModal,
    showResumeConfirmationModal,
    hideAllReceiveModals,
  } = useModalsContext();
  const { receiveShipment, pickUpShipment } = useShipmentApi();

  const { mutate: callReceiveShipment } = useMutation({
    mutationFn: receiveShipment,
  });

  const { mutate: callPickUpShipment } = useMutation({
    mutationFn: pickUpShipment,
  });

  const textareaChangeHandler = useCallback(
    (text: string) => {
      setReceivedShipment('comment', text);
    },
    [setReceivedShipment],
  );

  const imageChangeHandler = useCallback(
    (file: File | undefined) => {
      setReceivedShipment('image', file);
    },
    [setReceivedShipment],
  );

  const imageDeleteHandler = useCallback(() => {
    showConfirmationModal({
      title: t('Modals.ShipmentRemarks.deleteImageConfirm', {
        image: receivedShipment?.image?.name,
      }),
      onConfirm: () => {
        setReceivedShipment('image', undefined);
      },
    });
  }, [
    receivedShipment?.image?.name,
    setReceivedShipment,
    showConfirmationModal,
    t,
  ]);

  const { showWarningToast } = useToast();

  const onNextHandler = useCallback(() => {
    if (!receivedShipment) return;

    if (flow === 'receive') {
      callReceiveShipment(
        {
          shipmentID: receivedShipment.id,
          comment: receivedShipment.comment,
          image: receivedShipment.image,
          withReserve: true,
          isFinalUser,
        },
        {
          onSuccess: () => {
            showResumeConfirmationModal({
              content: t('Modals.CheckingPendingShipment.success', {
                context: flow,
              }),
              onConfirm: () => {
                onClose();
                hideAllReceiveModals();
                resetReceiveContext();
              },
              code: receivedShipment!.code || '',
            });
            invalidateAllShipmentQueries();
          },
          onError: () => {
            showWarningToast(t('ErrorPage.somethingWrong'));
          },
        },
      );
    }

    if (flow === 'driver') {
      callPickUpShipment(
        {
          shipmentID: receivedShipment.id,
          comment: receivedShipment.comment,
          image: receivedShipment.image,
          withReserve: true,
        },
        {
          onSuccess: () => {
            showResumeConfirmationModal({
              content: t('Modals.CheckingPendingShipment.success', {
                context: flow,
              }),
              onConfirm: () => {
                onClose();
                hideAllReceiveModals();
                resetReceiveContext();
              },
              code: receivedShipment!.code || '',
            });
            invalidateAllShipmentQueries();
          },
          onError: () => {
            showWarningToast(t('ErrorPage.somethingWrong'));
          },
        },
      );
    }
  }, [
    receivedShipment,
    flow,
    callReceiveShipment,
    isFinalUser,
    showResumeConfirmationModal,
    t,
    invalidateAllShipmentQueries,
    onClose,
    hideAllReceiveModals,
    resetReceiveContext,
    showWarningToast,
    callPickUpShipment,
  ]);

  return (
    <ReceiveBaseModal
      id="shipment-remarks"
      title={t('Modals.ReceiveFlow.ShipmentRemarks.title')}
      open={open}
      onNext={onNextHandler}
      onClose={onClose}
    >
      <div className="mt-10 flex flex-col gap-3">
        <div className="flex flex-col gap-1">
          <Typography isUppercase isBold size="lg">
            {t('Modals.ReceiveFlow.ShipmentRemarks.commentTitle')}
          </Typography>
          <Textarea
            name="remarks-comment"
            placeholder={t(
              'Modals.ReceiveFlow.ShipmentRemarks.commentPlaceholder',
            )}
            value={receivedShipment?.comment}
            onChange={textareaChangeHandler}
            maxLength={250}
          />
        </div>
        <FileInputButton
          icon="camera"
          onChange={imageChangeHandler}
          accept="image/*"
        />

        {receivedShipment?.image && (
          <div className="flex justify-between mt-4">
            <div className="flex gap-2">
              <IconSVG icon="attachment" size={24} />
              <Typography>{receivedShipment?.image?.name}</Typography>
            </div>
            <div className="flex gap-4">
              <IconSVG
                icon="search"
                size={24}
                onClick={() => {
                  showShipmentImagePreviewModal({
                    image: receivedShipment?.image,
                  });
                }}
              />
              <IconSVG icon="trash" size={24} onClick={imageDeleteHandler} />
            </div>
          </div>
        )}
      </div>
    </ReceiveBaseModal>
  );
};

export default React.memo(ReceiveRemarksModal);
