import React, { useMemo, useState } from 'react';

import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import colors from '@theme/colors';

import { PaginationSlotProps } from './definitions';

export const PaginationSlot = (props: PaginationSlotProps) => {
  const [hover, setHover] = useState(false);

  const slotClasses = useMemo(() => {
    const res = [
      'h-[32px] w-[32px] flex flex-row justify-center items-center px-2',
    ];

    if (props.type === 'number') {
      res.push(props.isActive || hover ? 'bg-Primary-00' : 'bg-Primary-05');
    }

    if (props.type === 'arrow') {
      res.push(hover ? 'bg-Primary-00' : 'bg-Primary-05');
    }

    if (props.type !== 'dots') {
      res.push('cursor-pointer rounded-[8px] border border-Primary-04');
    }

    return res.join(' ');
  }, [props, hover]);

  const slotProps = useMemo(() => {
    if (props.type !== 'dots') {
      return {
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
        onClick: props.onClick,
      };
    }

    return {};
  }, [props]);

  const content = useMemo(() => {
    switch (props.type) {
      case 'arrow':
        return (
          <IconSVG
            icon={props.icon}
            size={16}
            color={colors[hover ? 'Primary-05' : 'Primary-00']}
          />
        );
      case 'number':
        return (
          <Typography
            size="sm"
            color={
              props.isActive || hover ? 'text-Primary-05' : 'text-Primary-00'
            }
          >
            {props.value}
          </Typography>
        );
      case 'dots':
        return (
          <Typography size="sm" color="text-Primary-00">
            ...
          </Typography>
        );
      default:
        return null;
    }
  }, [props, hover]);

  return (
    <div className={slotClasses} {...slotProps}>
      {content}
    </div>
  );
};

export default React.memo(PaginationSlot);
