import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePackagingApi } from '@api/packagingApi';
import { Spinner } from '@atoms/Spinner';
import { useModalsContext } from '@contexts/modals';
import { usePendingShipmentContext } from '@contexts/pendingShipment';
import { QueryKeys } from '@definitions/QueryKeys';
import { ItemCounter } from '@molecules/ItemCounter';
import { useQuery } from '@tanstack/react-query';

import { PendingShipmentBaseModal } from '../PendingShipmentBaseModal';
import { PendingShipmentSelectPackagingProps } from './definitions';

const PendingShipmentSelectPackaging: React.FC<
  PendingShipmentSelectPackagingProps
> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const { getPackagings } = usePackagingApi();
  const { setPackaging, packagings: ctxPackagings } =
    usePendingShipmentContext();
  const { showPendingShipmentResumeModal } = useModalsContext();

  const { data: packagings, isFetching: isFetchingPackagings } = useQuery({
    enabled: open,
    queryKey: [QueryKeys.SHIPMENT_PACKAGINGS],
    queryFn: () => getPackagings(),
  });

  const handlePackagingsCountChange = useCallback(
    (id: number) => (count: number) => {
      setPackaging({
        id: id,
        quantity: count,
      });
    },
    [setPackaging],
  );

  const isNextDisabled = useMemo(() => {
    return ctxPackagings?.every((packaging) => packaging.quantity === 0);
  }, [ctxPackagings]);

  return (
    <PendingShipmentBaseModal
      id="pendingShipmentSelectPackaging"
      title={t('Modals.PendingShipmentSelectPackagings.title')}
      open={open}
      onNext={showPendingShipmentResumeModal}
      isNextDisabled={isNextDisabled}
      onClose={onClose}
    >
      <div className="flex flex-col gap-5 mt-4">
        {packagings?.map(({ image, label, id }) => {
          return (
            <ItemCounter
              key={id + open.toString()}
              icon={{
                pathName: image,
              }}
              label={label}
              onChange={handlePackagingsCountChange(id)}
              startValue={ctxPackagings?.find((p) => p.id === id)?.quantity}
            />
          );
        })}
      </div>
      {isFetchingPackagings && <Spinner className="mt-4" />}
    </PendingShipmentBaseModal>
  );
};

export default React.memo(PendingShipmentSelectPackaging);
