import { useEffect } from 'react';
import 'react-calendar/dist/Calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';

import { ApiProvider } from '@contexts/api';
import { AuthenticationProvider } from '@contexts/auth';
import { ModalsProvider } from '@contexts/modals';
import { PendingShipmentProvider } from '@contexts/pendingShipment';
import { ReceiveProvider } from '@contexts/receive';
import { ShipmentProvider } from '@contexts/shipment';
import { UserProvider } from '@contexts/user/UserContext';
import { RootElements } from '@organisms/RootElements';
import { TranslationKey } from '@translations/definitions';
import { useDayjsConfig } from '@utils/dayjs';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';

import './App.scss';
import { Navigation } from './navigation';

function App() {
  const {
    i18n: { changeLanguage },
    ready: i18nReady,
  } = useTranslation();
  // init dayjs
  useDayjsConfig();

  useEffect(() => {
    if (i18nReady) {
      const defaultLang = navigator.language.split('-')[0];
      const newLang =
        defaultLang === TranslationKey.EN
          ? TranslationKey.EN
          : TranslationKey.FR;

      changeLanguage(newLang);
    }
  }, [changeLanguage, i18nReady]);

  return (
    <AuthenticationProvider>
      <UserProvider>
        <ApiProvider>
          <ShipmentProvider>
            <ReceiveProvider>
              <PendingShipmentProvider>
                <ModalsProvider>
                  <Navigation />
                  <RootElements />
                </ModalsProvider>
              </PendingShipmentProvider>
            </ReceiveProvider>
          </ShipmentProvider>
        </ApiProvider>
      </UserProvider>
    </AuthenticationProvider>
  );
}

export default App;
