import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@atoms/Typography';
import { Radio } from '@molecules/Form/Radio';

import { FilterCommonProps } from './definitions';

export const DirectionFilter = ({ value, onChange }: FilterCommonProps) => {
  const { t } = useTranslation();
  const handleChange = useCallback(
    (radioValue: string, checked: boolean) => {
      if (checked) {
        onChange(radioValue);
      }
    },
    [onChange],
  );

  return (
    <div className="flex items-center md:justify-center gap-4">
      <div className="flex items-center gap-2">
        <Radio
          name="direction"
          value="from"
          onChange={handleChange}
          checked={value === 'from'}
        />
        <Typography as="label" className="text-Primary-02">
          {t('Filters.directionFrom.label')}:
        </Typography>
      </div>
      <div className="flex items-center gap-2">
        <Radio
          name="direction"
          value="to"
          onChange={handleChange}
          checked={value === 'to'}
        />
        <Typography as="label" className="text-Primary-02">
          {t('Filters.directionTo.label')}:
        </Typography>
      </div>
    </div>
  );
};
