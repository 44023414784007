import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useShipmentApi } from '@api/ShipmentApi';
import { useUserContext } from '@contexts/user';
import { QueryKeys } from '@definitions/QueryKeys';
import useWindow from '@hooks/useWindow';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { ShipmentsHistoryFilters } from './ShipmentsHistoryFilters';
import { ShipmentsHistoryFiltersRecord } from './ShipmentsHistoryFilters/definitions';
import ShipmentsListPage from './ShipmentsListPage';

const HistoryPage = () => {
  const { isDriver } = useUserContext();
  const { isMobile } = useWindow();
  const { t } = useTranslation();
  const { getShipmentsHistory, getShipmentsHistoryFiltered } = useShipmentApi();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [QueryKeys.SHIPMENT_HISTORY],
      queryFn: ({ pageParam = 1 }) =>
        getShipmentsHistory({
          page: pageParam,
          perPage: 20,
        }),
      initialPageParam: 1,
      getNextPageParam: (thisPage) =>
        thisPage.meta.currentPage + 1 > thisPage.meta.lastPage
          ? null
          : thisPage.meta.currentPage + 1,
    });

  const shipmentsHistory = useMemo(
    () =>
      data?.pages.flatMap((pages) => {
        return pages.data;
      }),
    [data?.pages],
  );

  // Filtered results
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<ShipmentsHistoryFiltersRecord>();

  const resetFilters = useCallback(() => {
    setFilters(undefined);
  }, []);

  const filtersParams = useMemo(() => {
    const dates = filters?.dates?.split(';');
    const senderOrganizationId =
      filters?.direction === 'from'
        ? filters?.organizationId || undefined
        : undefined;
    const receiverOrganizationId =
      filters?.direction === 'to'
        ? filters.organizationId || undefined
        : undefined;

    return {
      shipmentId: filters?.shipmentHistoryId || undefined,
      from: dates?.[0] || undefined,
      to: dates?.[1] || undefined,
      senderOrganizationId,
      receiverOrganizationId,
      styleId: filters?.itemId || undefined,
    };
  }, [
    filters?.dates,
    filters?.direction,
    filters?.itemId,
    filters?.organizationId,
    filters?.shipmentHistoryId,
  ]);

  const {
    data: shipmentsHistoryFiltered,
    isLoading: isLoadingShipmentsHistoryFiltered,
  } = useQuery({
    enabled: !!filters,
    queryKey: [QueryKeys.SHIPMENTS_IN_COURSE_FILTER, filters],
    queryFn: () => getShipmentsHistoryFiltered(filtersParams),
  });

  const parsedShipmentList = useMemo(() => {
    const hasFilters = !!filters;

    if (hasFilters && shipmentsHistoryFiltered) {
      return shipmentsHistoryFiltered;
    }

    return shipmentsHistory;
  }, [filters, shipmentsHistory, shipmentsHistoryFiltered]);

  const handleOnBottomReached = useCallback(() => {
    if (filters) {
      return;
    }

    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, filters, hasNextPage, isFetchingNextPage]);

  const FiltersNode = useMemo(() => {
    // If the user is a driver, we don't show the filters
    if (isDriver) {
      return undefined;
    }

    return (
      <ShipmentsHistoryFilters
        isMobile={isMobile}
        isMobileFiltersOpen={isMobileFiltersOpen}
        onCloseMobileFilters={() => setIsMobileFiltersOpen(false)}
        onApply={setFilters}
        onReset={resetFilters}
      />
    );
  }, [isDriver, isMobile, isMobileFiltersOpen, resetFilters]);

  return (
    <ShipmentsListPage
      title={t('History.title')}
      isLoading={isLoadingShipmentsHistoryFiltered || isFetchingNextPage}
      FiltersNode={FiltersNode}
      shipmentsList={parsedShipmentList}
      onBottomReached={handleOnBottomReached}
      onMobileFiltersOpen={() => setIsMobileFiltersOpen(true)}
      canChangeActivityCardStatus={false}
    />
  );
};
export default HistoryPage;
