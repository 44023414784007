import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object({
    newPassword: yup
      .string()
      .required(t('Form.errors.required'))
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        t('Form.errors.passwordComplexity'),
      ),
    confirmPassword: yup
      .string()
      .required(t('Form.errors.required'))
      .oneOf([yup.ref('newPassword')], t('Form.errors.passwordMatch'))
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        t('Form.errors.passwordComplexity'),
      ),
  });
};
