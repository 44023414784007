import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'use-debounce';

import { useShipmentApi } from '@api/ShipmentApi';
import { QueryKeys } from '@definitions/QueryKeys';
import useWindow from '@hooks/useWindow';
import { FormField } from '@molecules/Form/FormField';
import { Select } from '@molecules/Form/Select';
import { useInfiniteQuery } from '@tanstack/react-query';

import { FilterCommonProps } from './definitions';

export const ShipmentHistoryIdFilter = ({
  value,
  onChange,
}: FilterCommonProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindow();
  const { getShipmentsHistory } = useShipmentApi();

  const [searchValue, setSearchValue] = useState('');
  const [apiSearchValue] = useDebounce(searchValue, 300);
  const parsedApiSearchValue = useMemo(() => {
    const hasMinimumCharaters = apiSearchValue.length >= 3;

    return hasMinimumCharaters ? apiSearchValue : '';
  }, [apiSearchValue]);

  const {
    data: shipments,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: [QueryKeys.SHIPMENT_HISTORY, parsedApiSearchValue],
    queryFn: ({ pageParam = 1 }) =>
      getShipmentsHistory({
        page: pageParam,
        perPage: 20,
        textFilter: parsedApiSearchValue || undefined,
      }),
    initialPageParam: 1,
    getNextPageParam: (thisPage) =>
      thisPage.meta.currentPage + 1 > thisPage.meta.lastPage
        ? null
        : thisPage.meta.currentPage + 1,
  });

  const shipmentsOptions = useMemo(() => {
    return shipments?.pages
      .flatMap((pages) => pages.data)
      .map((page) => {
        const stringId = page.id.toString();
        return {
          value: stringId,
          label: page.code || stringId,
        };
      });
  }, [shipments]);

  const handleBottomReached = useCallback(() => {
    if (!hasNextPage || isFetching) {
      return;
    }

    fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetching]);

  return (
    <FormField
      label={t('Filters.shipmentId.label')}
      direction={isMobile ? 'row' : 'column'}
    >
      <Select
        variant="search"
        value={value}
        onChange={onChange}
        placeholder={t('Filters.shipmentId.placeholder')}
        options={shipmentsOptions || []}
        searchValue={searchValue}
        onSearchChange={setSearchValue}
        onBottomReached={handleBottomReached}
      />
    </FormField>
  );
};
