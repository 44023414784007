import { useCallback } from 'react';

import usePrivateApi from 'src/api/base/private';

import { useLogger } from '@hooks/useLogger';
import { buildQuery } from '@utils/buildQuery';

import {
  CreateProductCategoryParams,
  GetProductCategoriesParams,
  GetProductCategoriesResponse,
  GetProductCategoryResponse,
  GetProductsParams,
  GetProductsResponse,
  GetProductsStockBySearchPayload,
  GetProductsStockBySearchResponse,
  GetProductsStockCountResponse,
  GetProductsStockPayload,
  GetProductsStockResponse,
  UpdateProductCategoryParams,
  UpdateProductCategoryStatusParams,
} from './definitions';

export const useProductApi = () => {
  const { logError } = useLogger();

  const privateApi = usePrivateApi();

  // Products
  const getProducts = useCallback(
    async (params: GetProductsParams) => {
      try {
        const { data } = await privateApi.get<GetProductsResponse>(
          '/products',
          {
            params,
          },
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  // Products Stock
  const getProductsStockCount = useCallback(async () => {
    try {
      const { data } = await privateApi.get<GetProductsStockCountResponse>(
        '/products/stock/count',
      );
      return data;
    } catch (error) {
      logError(error);
      throw error;
    }
  }, [logError, privateApi]);

  const getProductsStock = useCallback(
    async ({ page, perPage, productCategoryId }: GetProductsStockPayload) => {
      const query = buildQuery({
        page,
        perPage,
        productCategoryId,
      });

      try {
        const { data } = await privateApi.get<GetProductsStockResponse>(
          `/products/stock${query}`,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const getProductsStockBySearch = useCallback(
    async ({ textFilter }: GetProductsStockBySearchPayload) => {
      const query = buildQuery({
        textFilter,
      });

      try {
        const { data } = await privateApi.get<GetProductsStockBySearchResponse>(
          `/products/stock/search${query}`,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  // Categories

  const getProductCategories = useCallback(
    async (params?: GetProductCategoriesParams) => {
      try {
        const { data } = await privateApi.get<GetProductCategoriesResponse>(
          '/product-categories',
          { params },
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const getProductCategory = useCallback(
    async (categoryId: number) => {
      try {
        const { data } = await privateApi.get<GetProductCategoryResponse>(
          `/product-categories/${categoryId}`,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const createProductCategory = useCallback(
    async (params: CreateProductCategoryParams) => {
      const formData = new FormData();
      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((label, index) => {
            formData.append(
              `labels[${index}][languageId]`,
              label.languageId.toString(),
            );
            formData.append(`labels[${index}][label]`, label.label);
          });
        } else {
          formData.append(key, value);
        }
      });

      try {
        await privateApi.post('/product-categories', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const updateProductCategory = useCallback(
    async ({ id, ...otherParams }: UpdateProductCategoryParams) => {
      const formData = new FormData();
      Object.entries(otherParams).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((label, index) => {
            formData.append(
              `labels[${index}][languageId]`,
              label.languageId.toString(),
            );
            formData.append(`labels[${index}][label]`, label.label);
          });
        } else {
          formData.append(key, value);
        }
      });

      try {
        await privateApi.patch(`/product-categories/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const updateProductCategoryStatus = useCallback(
    async ({ id, enabled }: UpdateProductCategoryStatusParams) => {
      try {
        await privateApi.patch(`/product-categories/${id}/toggle`, {
          enabled,
        });
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  return {
    getProducts,
    getProductsStockCount,
    getProductsStock,
    getProductsStockBySearch,
    getProductCategories,
    updateProductCategoryStatus,
    createProductCategory,
    updateProductCategory,
    getProductCategory,
  };
};
