import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useWindow from '@hooks/useWindow';
import { FormField } from '@molecules/Form/FormField';
import { Select } from '@molecules/Form/Select';

import { FilterCommonProps } from './definitions';

export const ProductTypeFilter = ({
  value,
  onChange,
}: FilterCommonProps<string[]>) => {
  const { t } = useTranslation();
  const { isMobile } = useWindow();

  const options = useMemo(() => {
    return [
      {
        label: t('Filters.productType.options.sku'),
        value: 'sku',
      },
      {
        label: t('Filters.productType.options.other'),
        value: 'other',
      },
      {
        label: t('Filters.productType.options.consignment_nr'),
        value: 'consignment_nr',
      },
    ];
  }, [t]);

  return (
    <FormField
      label={t('Filters.productType.label')}
      direction={isMobile ? 'row' : 'column'}
    >
      <Select
        value={value}
        onChange={onChange}
        placeholder={t('Filters.productType.placeholder')}
        options={options}
        multiple={true}
      />
    </FormField>
  );
};
