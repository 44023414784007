import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@atoms/Button';
import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { usePendingShipmentContext } from '@contexts/pendingShipment';

import { PendingShipmentBaseModal } from '../PendingShipmentBaseModal';
import { PendingShipmentRequestApprovalModalProps } from './definitions';

const PendingShipmentRequestApprovalModal = ({
  open,
  onClose,
}: PendingShipmentRequestApprovalModalProps) => {
  const { t } = useTranslation();
  const {
    showPendingShipmentSelectProductsModal,
    showPendingShipmentSelectPackagingModal,
  } = useModalsContext();
  const { setProduct, setPackaging, selectedShipment } =
    usePendingShipmentContext();

  const handleSelectAll = useCallback(() => {
    if (
      selectedShipment?.shipmentProducts &&
      selectedShipment?.shipmentProducts.length > 0
    ) {
      selectedShipment.shipmentProducts.forEach((product) => {
        setProduct(product);
      });
    }
    if (
      selectedShipment?.shipmentPackagings &&
      selectedShipment.shipmentPackagings.length > 0
    ) {
      selectedShipment.shipmentPackagings?.forEach((packaging) => {
        setPackaging(packaging);
      });
    }

    // Show select products too, so the user can go back from select packaging
    showPendingShipmentSelectProductsModal();
    showPendingShipmentSelectPackagingModal();
  }, [
    selectedShipment?.shipmentPackagings,
    selectedShipment?.shipmentProducts,
    setPackaging,
    setProduct,
    showPendingShipmentSelectPackagingModal,
    showPendingShipmentSelectProductsModal,
  ]);

  return (
    <PendingShipmentBaseModal
      type="custom"
      size="small"
      id="pendingShipmentRequestApprovalModal"
      open={open}
      onClose={onClose}
      showCloseButton
      title={t('Modals.PendingShipmentRequestApproval.title')}
    >
      <div className="flex flex-col gap-2">
        <Typography size="sm" sizeMd="md" color="text-Primary-02">
          {t('Modals.PendingShipmentRequestApproval.content')}
        </Typography>
        <Button
          label={t('Modals.PendingShipmentRequestApproval.selectItems')}
          type="secondary"
          onClick={showPendingShipmentSelectProductsModal}
        />
        <Button
          label={t('Modals.PendingShipmentRequestApproval.approveAllItems')}
          type="primary"
          onClick={handleSelectAll}
        />
      </div>
    </PendingShipmentBaseModal>
  );
};

export default React.memo(PendingShipmentRequestApprovalModal);
