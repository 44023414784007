import React, { useEffect } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';

import { useUserApi } from '@api/UserApi';
import { useAuthentication } from '@contexts/auth';
import { useUserContext } from '@contexts/user';
import { QueryKeys } from '@definitions/QueryKeys';
import { Roles } from '@definitions/Users';
import { useQuery } from '@tanstack/react-query';

import { AnonymousRoutes, AuthenticatedRoutes } from '../routes';
import RoleOnly from './RoleOnly';

type AuthenticatedProps = RouteProps & {
  roles: Roles[];
  otherRolesFallbackRoute?: AuthenticatedRoutes;
};

const Authenticated = ({
  element: Page,
  roles,
  otherRolesFallbackRoute,
}: AuthenticatedProps) => {
  const { getUserMe } = useUserApi();
  const { setUser } = useUserContext();

  const { isAuthenticated, isAuthenticationChecked } = useAuthentication();

  const { data: userData } = useQuery({
    queryKey: [QueryKeys.USERS_ME],
    queryFn: () => getUserMe(),
    enabled: isAuthenticated,
  });

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [setUser, userData]);

  if (!isAuthenticationChecked) {
    return null;
  }

  if (isAuthenticated) {
    if (roles) {
      return (
        <RoleOnly
          element={Page}
          roles={roles}
          fallbackRoute={otherRolesFallbackRoute}
        />
      );
    }
    return <>{Page}</>;
  }

  return <Navigate to={AnonymousRoutes.LOGIN} replace />;
};

export default React.memo(Authenticated);
