import { useState } from 'react';

import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';

import { UseTableStateParams } from './definitions';

const DEFAULT_PAGINATION: PaginationState = {
  pageIndex: 0,
  pageSize: 20,
};

const DEFAULT_SORTING: SortingState = [];

const DEFAULT_COLUMN_FILTERS: ColumnFiltersState = [];

export const useTableState = (params?: UseTableStateParams) => {
  const { defaultPagination, defaultSorting, defaultColumnFilters } =
    params ?? {};

  // Pagination State
  const [pagination, setPagination] = useState<PaginationState>(
    defaultPagination ?? DEFAULT_PAGINATION,
  );

  // Sorting State
  const [sorting, setSorting] = useState<SortingState>(
    defaultSorting ?? DEFAULT_SORTING,
  );

  // Filters State
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    defaultColumnFilters ?? DEFAULT_COLUMN_FILTERS,
  );

  return {
    pagination,
    onPaginationChange: setPagination,
    sorting,
    onSortingChange: setSorting,
    columnFilters,
    onColumnFiltersChange: setColumnFilters,
  };
};
