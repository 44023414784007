import { useCallback } from 'react';

import { Typography } from '@atoms/Typography';
import { ItemSelect } from '@molecules/ItemSelect';

import { AddressListProps } from './definitions';

const AddressList = ({
  items,
  selectedAddressId,
  onSelectAddress,
}: AddressListProps) => {
  const handleChekboxChange = useCallback(
    (id: number) => (_: unknown, checked: boolean) => {
      onSelectAddress(checked ? id : undefined);
    },
    [onSelectAddress],
  );

  return (
    <div className="flex flex-col gap-3 pr-3">
      {items.map((item) => {
        return (
          <ItemSelect
            key={item.id}
            checkbox={{
              name: item.id.toString(),
              value: item.name,
              checked: selectedAddressId === item.id,
              onChange: handleChekboxChange(item.id),
              size: 'xs',
            }}
          >
            <Typography size="lg" isBold>
              {item.name}
            </Typography>
            <Typography color="text-Primary-02" size="md">
              {item.address}
            </Typography>
          </ItemSelect>
        );
      })}
    </div>
  );
};

export default AddressList;
