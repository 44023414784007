import { LogoProps } from './definitions';

const Logo = ({ type, className }: LogoProps) => {
  return (
    <img
      src={`${type === 'black' ? '/saint-laurent.svg' : '/saint-laurent-white.svg'}`}
      alt="Saint Laurent Logo"
      width="229px"
      className={className}
    />
  );
};
export default Logo;
