import { useTranslation } from 'react-i18next';

import { Button } from '@atoms/Button';
import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import { BaseModal } from '@organisms/Modals/BaseModal';

import { GenericShipmentBaseModalProps } from './definitions';

const GenericShipmentBaseModal = ({
  id,
  open,
  onBack,
  onBottomReached,
  onClose,
  onNext,
  isNextDisabled = false,
  nextLabel,
  title,
  children,
  Footer,
}: GenericShipmentBaseModalProps) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      size="large"
      id={id}
      open={open}
      onClose={onClose}
      onBottomReached={onBottomReached}
      showCloseButton
      contentClassName="h-auto sm:h-[500px]"
      header={{
        leftElement: onBack ? (
          <IconSVG icon="chevron-left" size={16} onClick={onBack} />
        ) : (
          <div />
        ),
        centerElement: (
          <Typography isUppercase isBold size="lg" color="text-Primary-02">
            {title}
          </Typography>
        ),
        rightElement: <IconSVG icon="close" size={16} onClick={onClose} />,
      }}
      footer={
        Footer || (
          <Button
            className="w-full mt-4"
            type="primary"
            label={nextLabel || t('General.next')}
            onClick={onNext}
            disabled={isNextDisabled}
          />
        )
      }
    >
      {children}
    </BaseModal>
  );
};
export default GenericShipmentBaseModal;
