import React from 'react';

import { Checkbox } from '@atoms/Checkbox';
import { ItemSelectProps } from '@molecules/ItemSelect/definitions';

import './styles.scss';

const ItemSelect = ({ children, checkbox }: ItemSelectProps) => {
  return (
    <div className="flex justify-between items-center">
      <label
        htmlFor={checkbox.name}
        className="flex flex-col flex-1 cursor-pointer"
      >
        {children}
      </label>
      <Checkbox {...checkbox} aria-labelledby="test-id" />
    </div>
  );
};

export default React.memo(ItemSelect);
