import React, { useMemo } from 'react';

import IcomoonReact from 'icomoon-react';

import { theme } from '@theme';

import { IconSVGProps } from './definitions.ts';
import iconSet from './selection.json';

const IconSVG = ({
  className,
  icon,
  size,
  color,
  onClick,
  disabled = false,
}: IconSVGProps): JSX.Element => {
  const IconElement = useMemo(
    () => (
      <IcomoonReact
        iconSet={iconSet}
        color={disabled ? theme.colors['Primary-03'] : color}
        size={size}
        icon={icon}
        className={className}
      />
    ),
    [className, color, icon, size, disabled],
  );

  if (onClick) {
    return (
      <button
        type="button"
        className="flex justify-center items-center"
        disabled={disabled}
        onClick={onClick}
      >
        {IconElement}
      </button>
    );
  }

  return IconElement;
};

export default React.memo(IconSVG);
