import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Roles, User } from '@definitions/Users';

import { UserContextProps, UserProviderProps } from './definitions';

const undefinedMethod = () => {
  throw new Error('[AuthContext] Context not initialised');
};

const UserContext = createContext<UserContextProps>({
  user: null,
  setUser: undefinedMethod,
  isAdmin: false,
  isDriver: false,
});

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, _setUser] = useState<User | null>(null);
  const {
    i18n: { changeLanguage },
  } = useTranslation();

  const setUserData = useCallback(
    (newUser: User | null) => {
      _setUser(newUser);
      if (newUser?.language) {
        changeLanguage(newUser?.language.code);
      }
    },
    [changeLanguage],
  );

  const isAdmin = useMemo(() => user?.role.name === Roles.ADMIN, [user]);
  const isDriver = useMemo(() => user?.role.name === Roles.DRIVER, [user]);

  const memoizedValue = useMemo<UserContextProps>(
    () => ({
      user,
      setUser: setUserData,
      isAdmin,
      isDriver,
    }),
    [user, setUserData, isAdmin, isDriver],
  );

  return (
    <UserContext.Provider value={memoizedValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = (): UserContextProps => useContext(UserContext);
