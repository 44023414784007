import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@atoms/Button';
import { Card } from '@atoms/Card';
import { Typography } from '@atoms/Typography';
import { useUserContext } from '@contexts/user';
import { BottomSheet } from '@molecules/BottomSheet';
import { DateRangeFilter } from '@molecules/Filters/DateRangeFilter';
import { DirectionFilter } from '@molecules/Filters/Direction';
import { OrganizationIdFilter } from '@molecules/Filters/OrganizationId';
import { ProductIdFilter } from '@molecules/Filters/ProductId';
import { ShipmentHistoryIdFilter } from '@molecules/Filters/ShipmentHistoryId';

import {
  ShipmentsHistoryFiltersProps,
  ShipmentsHistoryFiltersRecord,
  ShipmentsHistoryListFilterKey,
} from './definitions';
import './styles.scss';

const FILTERS_INITIAL_STATE: ShipmentsHistoryFiltersRecord = {
  shipmentHistoryId: '',
  direction: 'from',
  organizationId: '',
  itemId: '',
  dates: '',
};

const ShipmentsHistoryFilters = ({
  onApply,
  onReset,
  isMobile,
  isMobileFiltersOpen,
  onCloseMobileFilters,
}: ShipmentsHistoryFiltersProps) => {
  const { t } = useTranslation();
  const { isAdmin } = useUserContext();
  const [filters, setFilters] = useState(FILTERS_INITIAL_STATE);
  const [filtersKey, setFiltersKey] = useState<string>();

  const setFilter = useCallback(
    (key: ShipmentsHistoryListFilterKey) => (value: string | undefined) => {
      setFilters((prev) => ({
        ...prev,
        [key]: value,
      }));
    },
    [],
  );

  const resetFilters = useCallback(() => {
    setFilters(FILTERS_INITIAL_STATE);
  }, []);

  const handleApply = useCallback(() => {
    onApply(filters);
  }, [filters, onApply]);

  const handleReset = useCallback(() => {
    resetFilters();
    onReset();
    setFiltersKey(crypto.randomUUID());
  }, [onReset, resetFilters]);

  const isApplyButtonDisabled = useMemo(() => {
    if (
      !filters.shipmentHistoryId &&
      !filters.organizationId &&
      !filters.itemId &&
      !filters.dates
    ) {
      return true;
    }
  }, [filters]);

  const isResetButtonDisabled = isApplyButtonDisabled;

  const handleDateRangeFilterChange = useCallback(
    (value: Array<string | null>) => {
      const parsedValue = value.filter(Boolean);

      setFilter('dates')(
        parsedValue.length > 0 ? parsedValue.join(';') : undefined,
      );
    },
    [setFilter],
  );

  const FiltersNode = useMemo(() => {
    const dateRangeFilterValue = (
      filters.dates ? filters.dates.split(';') : [null, null]
    ) as [string | null, string | null];

    return (
      <Fragment key={filtersKey}>
        <ShipmentHistoryIdFilter
          value={filters.shipmentHistoryId}
          onChange={setFilter('shipmentHistoryId')}
        />

        {isAdmin && (
          <>
            <DirectionFilter
              value={filters.direction}
              onChange={setFilter('direction')}
            />

            <OrganizationIdFilter
              value={filters.organizationId}
              onChange={setFilter('organizationId')}
            />
          </>
        )}

        <ProductIdFilter
          value={filters.itemId}
          onChange={setFilter('itemId')}
        />

        <DateRangeFilter
          value={dateRangeFilterValue}
          onChange={handleDateRangeFilterChange}
          anchor={isMobile ? 'bottom' : 'top'}
        />
      </Fragment>
    );
  }, [
    filters,
    filtersKey,
    handleDateRangeFilterChange,
    isAdmin,
    isMobile,
    setFilter,
  ]);

  const ButtonsNode = useMemo(() => {
    return (
      <div className="flex flex-col gap-2">
        <Button
          type="primary"
          label={t('General.apply')}
          onClick={handleApply}
          disabled={isApplyButtonDisabled}
        />
        <Button
          type="secondary"
          label={t('General.clearAll')}
          onClick={handleReset}
          disabled={isResetButtonDisabled}
        />
      </div>
    );
  }, [
    t,
    handleApply,
    handleReset,
    isApplyButtonDisabled,
    isResetButtonDisabled,
  ]);

  if (isMobile) {
    return (
      <BottomSheet
        isOpen={isMobileFiltersOpen}
        onClose={onCloseMobileFilters}
        detent="content-height"
      >
        <div className="p-4 flex flex-col justify-between h-full">
          <div>
            <Typography
              isUppercase
              isBold
              size="xl"
              className="text-center mb-6"
            >
              {t('Filters.insertFilters')}
            </Typography>
            <div className="flex flex-col gap-8 mb-10">{FiltersNode}</div>
          </div>

          {ButtonsNode}
        </div>
      </BottomSheet>
    );
  }

  return (
    <Card hasPadding={false} className="p-4">
      <div className="w-full flex flex-row gap-6 justify-between items-center">
        <div id="historyFiltersDesktop">{FiltersNode}</div>

        {ButtonsNode}
      </div>
    </Card>
  );
};
export default ShipmentsHistoryFilters;
