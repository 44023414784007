import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useSupportsApi } from '@api/supportsApi';
import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { useShipmentContext } from '@contexts/shipment';
import { NewShipmentProduct } from '@definitions/Products';
import { QueryKeys } from '@definitions/QueryKeys';
import { ShipmentSupport } from '@definitions/Shipment';
import { ItemCounter } from '@molecules/ItemCounter';
import { useQuery } from '@tanstack/react-query';

import { SummaryTabProps } from './definitions';

const SummaryTab = ({ className: classNameFromProps }: SummaryTabProps) => {
  const { t } = useTranslation();
  const { showShipmentItemDetailsModal } = useModalsContext();
  const { getSupports } = useSupportsApi();

  const {
    products: ctxProducts,
    removeProduct: ctxRemoveProduct,
    supports: ctxSupports,
    setSupport: ctxSetSupport,
  } = useShipmentContext();

  const { data: apiSupports } = useQuery({
    queryKey: [QueryKeys.SHIPMENT_SUPPORTS],
    queryFn: () => getSupports(),
  });

  const hasOnlyStsOrConsignmentProducts =
    ctxProducts.length > 0 &&
    ctxProducts.every(
      (p) => p.styleIdType === 'sts' || p.styleIdType === 'consignment',
    );

  const renderItemRow = useCallback(
    (params: {
      type: NewShipmentProduct['styleIdType'];
      id: NewShipmentProduct['styleId'];
    }) => {
      const matchingProduct = ctxProducts.find((p) => p.styleId === params.id);

      return (
        <div key={params.id} className="flex flex-col py-[8px]">
          <div className="flex justify-between items-center">
            <div className="flex gap-1">
              <Typography color="text-Primary-03">
                {params.type.toUpperCase()}:
              </Typography>
              <Typography color="text-Primary-02">{params.id}</Typography>
            </div>
            <div className="flex gap-4">
              <IconSVG
                icon="edit"
                size={18}
                onClick={() => {
                  if (matchingProduct) {
                    showShipmentItemDetailsModal({
                      product: {
                        productId: matchingProduct.productId!,
                        productCategoryId: matchingProduct.productCategoryId,
                        styleId: matchingProduct.styleId,
                        styleIdType: matchingProduct.styleIdType,
                        name: matchingProduct.name,
                        size: matchingProduct.size,
                        billableCenterCostId:
                          matchingProduct.billableCenterCostId,
                        billable: matchingProduct.billable,
                        reasonForRequestId: matchingProduct.reasonForRequestId,
                        comment: matchingProduct.comment,
                      },
                      type: params.type,
                    });
                  }
                }}
              />
              <IconSVG
                icon="trash"
                size={18}
                onClick={() => {
                  if (matchingProduct) {
                    ctxRemoveProduct(matchingProduct?.productId);
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    },
    [ctxProducts, ctxRemoveProduct, showShipmentItemDetailsModal],
  );

  const renderItemsGroup = useCallback(
    ({
      title,
      items,
      disabled,
    }: {
      title: string;
      items: Array<{
        type: NewShipmentProduct['styleIdType'];
        id: NewShipmentProduct['styleId'];
      }>;
      disabled?: boolean;
    }) => {
      return (
        <div>
          <Typography
            isUppercase
            size="sm"
            isBold
            color={disabled ? 'text-Primary-03' : 'text-Primary-00'}
            className="py-[14px]"
          >
            {title}
          </Typography>
          <div className="flex flex-col">{items.map(renderItemRow)}</div>
        </div>
      );
    },
    [renderItemRow],
  );

  const renderSupportsGroup = useCallback(
    ({
      title,
      items,
      disabled,
    }: {
      title: string;
      items: Array<{ id: ShipmentSupport['id']; label: string }>;
      disabled?: boolean;
    }) => {
      return (
        <div>
          <Typography
            isUppercase
            size="sm"
            isBold
            color={disabled ? 'text-Primary-03' : 'text-Primary-00'}
            className="py-[14px]"
          >
            {title}
          </Typography>
          <div className="flex flex-col">
            {items.map((item) => (
              <ItemCounter
                key={item.id}
                label={item.label}
                onChange={(value) =>
                  ctxSetSupport({ id: item.id, quantity: value })
                }
                startValue={
                  ctxSupports.find((support) => support.id === item.id)
                    ?.quantity || 0
                }
              />
            ))}
          </div>
        </div>
      );
    },
    [ctxSetSupport, ctxSupports],
  );

  return (
    <div className={`flex flex-col ${classNameFromProps || ''}`.trim()}>
      {renderItemsGroup({
        title: t('Shipment.Summary.itemsAddedManually'),
        items: ctxProducts
          .filter(({ productId }) => productId !== undefined && productId < 0)
          .map((product) => ({
            type: product.styleIdType,
            id: product.styleId,
          })),
      })}

      {renderItemsGroup({
        disabled: hasOnlyStsOrConsignmentProducts,
        title: t('Shipment.Summary.itemsSelectedFromStock'),
        items: ctxProducts
          .filter(({ productId }) => productId !== undefined && productId > 0)
          .map((product) => ({
            type: product.styleIdType,
            id: product.styleId,
          })),
      })}

      {renderSupportsGroup({
        disabled: hasOnlyStsOrConsignmentProducts,
        title: t('Shipment.Summary.supportsSelected'),
        items:
          ctxSupports.map(({ id }) => ({
            id,
            label: apiSupports?.find((s) => s.id === id)?.label || '',
          })) || [],
      })}
    </div>
  );
};

export default SummaryTab;
