import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSVG } from '@atoms/IconSVG';
import { Logo } from '@atoms/Logo';
import { useModalsContext } from '@contexts/modals';
import { Logout } from '@molecules/Logout';
import { Navigation } from '@molecules/Navigation';
import { UserDetails } from '@molecules/UserDetails';
import { AuthenticatedRoutes } from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { ChangeLanguage } from '@organisms/ChangeLanguage';

import { NavigationMenuMobileLayoutsProps } from './definitions';

const NavigationMenuMobileLayouts = ({
  children,
}: NavigationMenuMobileLayoutsProps) => {
  const { goTo } = useNavigation();
  const [showUserSettings, setShowUserSettings] = useState(false);
  const { t } = useTranslation();
  const { showChangePasswordModal, showNotificationModal } = useModalsContext();

  const showHistogramHandler = () => {
    goTo(AuthenticatedRoutes.STATISTICS_PAGE);
  };

  const toggleUserSettingsHandler = (bool: boolean) => {
    setShowUserSettings(bool);
  };

  const Drawer = useMemo(() => {
    return (
      <div className="ui-drawer">
        <input
          id="menuMobileDrawer"
          type="checkbox"
          className="ui-drawer-toggle"
          checked={showUserSettings}
        />
        {/* Render children here */}
        <div className="ui-drawer-content">{children}</div>
        <div className="ui-drawer-side z-10">
          <div className="bg-Primary-05 p-4 w-full h-full flex flex-col">
            <button
              onClick={() => toggleUserSettingsHandler(false)}
              className="self-end"
            >
              <IconSVG icon="close" size={18} />
            </button>

            <div className="flex flex-col justify-between flex-1">
              <div className="flex flex-col gap-4">
                <UserDetails />
                <ChangeLanguage />
                <button
                  onClick={showChangePasswordModal}
                  className="text-Primary-00 uppercase text-md font-bold text-left"
                >
                  {t('ChangePassword.title')}
                </button>
                <button
                  onClick={showNotificationModal}
                  className="text-Primary-00 uppercase text-md font-bold text-left py-5 pr-4"
                >
                  {t('Modals.NotificationSettings.title')}
                </button>
              </div>

              <Logout />
            </div>
          </div>
        </div>
      </div>
    );
  }, [
    children,
    showChangePasswordModal,
    showNotificationModal,
    showUserSettings,
    t,
  ]);

  return (
    <div id="pageContainer" className="flex flex-col h-dvh">
      <div className="flex sticky top-0 z-10 items-center justify-between bg-Primary-05 p-4">
        <button onClick={showHistogramHandler}>
          <IconSVG icon="statistics" size={20} />
        </button>
        <Logo type="black" className="h-3" />
        <button onClick={() => toggleUserSettingsHandler(true)}>
          <IconSVG icon="user" size={20} />
        </button>
      </div>
      <div
        id="contentContainer"
        className="overflow-scroll flex-1 flex flex-col"
      >
        {Drawer}
      </div>
      <div id="navigationContainer">
        <Navigation isMobileNavigation />
      </div>
    </div>
  );
};

export default NavigationMenuMobileLayouts;
