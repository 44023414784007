import daisyui from 'daisyui';

import { theme } from './src/theme';

/** @type {import('tailwindcss').Config} */
// @ts-expect-error Ignore Tailwind errors (config is 100% valid)
export default {
  content: ['./App.scss', './src/**/*.{js,ts,jsx,tsx}'],
  theme: theme,
  plugins: [daisyui],
  daisyui: {
    prefix: 'ui-',
    themes: ['light'],
  },
};
