import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@atoms/Typography/Typography.tsx';
import { TypographyProps } from '@atoms/Typography/definitions.ts';

import { InputProps } from './definitions.ts';

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      title,
      placeholder,
      placeholderUppercase,
      type,
      name,
      value,
      onFocus,
      onBlur,
      onChange,
      error,
      disabled,
      variant = 'default',
      startAdornment,
      endAdornment,
      autoComplete,
      className: classNameFromProps = '',
    },
    ref,
  ) => {
    const [formType, setFormType] = useState(type);
    const [showPassword, setShowPassword] = useState(false);

    const hasError = useMemo(() => Boolean(error), [error]);

    const { t } = useTranslation();

    const textProps = useMemo((): Pick<
      TypographyProps,
      'size' | 'sizeMd' | 'isBold' | 'color'
    > => {
      const getColor = () => {
        if (disabled) {
          return 'text-Primary-02';
        }

        if (variant === 'light') {
          return 'text-Primary-05';
        }
      };

      return {
        size: 'sm',
        sizeMd: 'md',
        isBold: true,
        color: getColor(),
      };
    }, [disabled, variant]);

    const boxClasses = useMemo(() => {
      const classes = ['w-full flex items-center gap-3 border-b'];

      classes.push(
        disabled
          ? 'border-Primary-03'
          : hasError
            ? 'border-System-red'
            : 'border-Primary-02',
      );

      if (classNameFromProps) {
        classes.push(classNameFromProps);
      }

      return classes.filter(Boolean).join(' ');
    }, [classNameFromProps, disabled, hasError]);

    const inputClasses = useMemo(() => {
      const classes = [];

      // spacing
      classes.push(
        'w-full bg-transparent',
        'h-10',
        'pr-2.5',
        'focus:outline-none',
      );

      // text
      classes.push(
        'font-normal',
        'text-sm md:text-md',
        disabled ? 'text-Primary-03' : 'text-Primary-00',
      );

      // placeholder
      classes.push(
        'placeholder:text-sm md:placeholder:text-md',
        'placeholder:font-normal',
        'placeholder:text-Primary-03',
        placeholderUppercase ? 'placeholder:uppercase' : '',
      );

      if (variant === 'light') {
        classes.push('formInput-light text-Primary-05');

        if (disabled) {
          classes.push('text-Primary-05');
        }
      }

      return classes.filter(Boolean).join(' ');
    }, [disabled, placeholderUppercase, variant]);

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
      },
      [onChange],
    );

    const setShowPasswordHandler = () => {
      if (!disabled) {
        setShowPassword((prevState) => !prevState);
        setFormType((prevState) =>
          prevState === 'password' ? 'text' : 'password',
        );
      }
    };

    return (
      <div>
        {title && (
          <Typography isUppercase {...textProps}>
            {title}
          </Typography>
        )}

        <div className="flex items-center relative">
          <div className={boxClasses}>
            {startAdornment}
            <input
              ref={ref}
              disabled={disabled}
              type={formType}
              id={name}
              name={name}
              value={value}
              placeholder={placeholder}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
              className={inputClasses}
              autoComplete={autoComplete}
            />
            {endAdornment}
          </div>

          {type === 'password' && (
            <button
              onClick={setShowPasswordHandler}
              className="absolute right-0"
            >
              <Typography as="span" isUppercase {...textProps}>
                {showPassword ? t('General.hide') : t('General.show')}
              </Typography>
            </button>
          )}
        </div>
        {hasError && (
          <Typography
            size="xs"
            sizeMd="sm"
            color="text-System-red"
            className="mt-1"
          >
            {error}
          </Typography>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
