import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { Shipment, ShipmentProduct } from '@definitions/Shipment';

import {
  PendingShipmentContextProps,
  PendingShipmentProviderProps,
  ShipmentPackaging,
} from './definitions';

const undefinedMethod = () => {
  throw new Error('[PendingShipmentContext] Context not initialised');
};

const PendingShipmentContext = createContext<PendingShipmentContextProps>({
  selectedShipment: undefined,
  setSelectedShipment: undefinedMethod,
  products: [],
  setProduct: undefinedMethod,
  removeProduct: undefinedMethod,
  removeAllProducts: undefinedMethod,
  packagings: [],
  setPackaging: undefinedMethod,
  reset: undefinedMethod,
});

export const PendingShipmentProvider = ({
  children,
}: PendingShipmentProviderProps) => {
  const [selectedShipment, setSelectedShipment] = useState<
    Shipment | undefined
  >(undefined);

  // Products
  const [products, _setProducts] = useState<Array<ShipmentProduct>>([]);

  const setProduct = useCallback((product: ShipmentProduct) => {
    _setProducts((prevProducts) => {
      const targetProductIndex = prevProducts.findIndex(
        (prevProduct) => prevProduct.productId === product.productId,
      );
      const foundItem = targetProductIndex > -1;

      if (foundItem) {
        const updatedProducts = [...prevProducts];
        updatedProducts[targetProductIndex] = product;
        return updatedProducts;
      }

      return [...prevProducts, product];
    });
  }, []);

  const removeProduct = useCallback(
    (productId: ShipmentProduct['productId']) => {
      _setProducts((prevProducts) =>
        prevProducts.filter((product) => product.productId !== productId),
      );
    },
    [],
  );

  const removeAllProducts = () => _setProducts([]);

  // Packagings
  const [packagings, _setPackagings] = useState<Array<ShipmentPackaging>>([]);

  const setPackaging = useCallback((packaging: ShipmentPackaging) => {
    _setPackagings((prevPackagings) => {
      const targetPackagingIndex = prevPackagings.findIndex(
        (findPackaging) => findPackaging.id === packaging.id,
      );

      const foundItem = targetPackagingIndex !== -1;

      if (foundItem) {
        const updatedPackagings = [...prevPackagings];
        updatedPackagings[targetPackagingIndex] = packaging;
        return updatedPackagings;
      }

      return [...prevPackagings, packaging];
    });
  }, []);

  const _removeAllPackagings = () => _setPackagings([]);

  const reset = useCallback(() => {
    setSelectedShipment(undefined);
    removeAllProducts();
    _removeAllPackagings();
  }, []);

  const memoizedValue = useMemo<PendingShipmentContextProps>(
    () => ({
      selectedShipment,
      setSelectedShipment,

      products,
      setProduct,
      removeProduct,
      removeAllProducts,
      packagings,
      setPackaging,
      reset,
    }),
    [
      selectedShipment,
      products,
      setProduct,
      removeProduct,
      packagings,
      setPackaging,
      reset,
    ],
  );

  return (
    <PendingShipmentContext.Provider value={memoizedValue}>
      {children}
    </PendingShipmentContext.Provider>
  );
};

export const usePendingShipmentContext = (): PendingShipmentContextProps =>
  useContext(PendingShipmentContext);
