import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { IconNames } from '@atoms/IconSVG/definitions';
import { useGetNavigationItem } from '@hooks/useGetNavigationItem';
import NavigationItem from '@molecules/Navigation/NavigationItem';
import { AuthenticatedRoutes } from '@navigation/routes';

import {
  NavigationItemEnum,
  NavigationItemEnumProps,
  NavigationProps,
} from './definitions';

const Navigation = ({ isMobileNavigation }: NavigationProps) => {
  const { t } = useTranslation();

  const location = useLocation();
  const { items: navigationItemsArray } = useGetNavigationItem();
  const getRoutes = (item: NavigationItemEnumProps) => {
    const result = Object.keys(NavigationItemEnum).find(
      (key) => NavigationItemEnum[key] === item,
    );

    if (result) {
      return AuthenticatedRoutes[result as keyof typeof AuthenticatedRoutes];
    } else {
      return AuthenticatedRoutes.HOME_PAGE;
    }
  };

  const navigationItems = useMemo(() => {
    if (isMobileNavigation) {
      return navigationItemsArray.slice(0, 4);
    }
    return navigationItemsArray;
  }, [isMobileNavigation, navigationItemsArray]);

  return (
    <div
      className={`flex justify-around bg-Primary-05 ${isMobileNavigation ? 'flex-row' : 'flex-col'}`}
    >
      {navigationItems.map((item) => {
        return (
          <NavigationItem
            key={item}
            label={t(`Navigation.${item}`)}
            icon={item as IconNames}
            isActive={
              location.pathname === getRoutes(item as NavigationItemEnumProps)
            }
            goToRoute={getRoutes(item as NavigationItemEnumProps)}
            isVertical={!isMobileNavigation}
          />
        );
      })}
    </div>
  );
};

export default React.memo(Navigation);
