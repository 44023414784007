import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object({
    shipmentCode: yup
      .string()
      .required(t('Form.errors.required'))
      .matches(/^[A-Za-z]{6}$/, t('Form.errors.invalidFormat')),
  });
};
