const colors = {
  'Primary-00': '#000000',
  'Primary-00-transparent': 'rgba(0, 0, 0, 0.80)',
  'Primary-01': '#1F1F1E',
  'Primary-02': '#525251',
  'Primary-03': '#B1B1B1',
  'Primary-04': '#F0F0F0',
  'Primary-05': '#FFFFFF',
  'System-red': '#FF3B30',
  'Urgent-red': '#961A1A',
  'Toast-gray': 'rgba(0, 0, 0, 0.9)',
  'Modal-gray': 'rgba(42, 42, 42, 0.70)',
  transparent: 'transparent',
};
export default colors;
