import { Typography } from '@atoms/Typography';

import { LegendProps } from './definitions';

export const Legend = ({ items }: LegendProps) => {
  return (
    items.length > 0 && (
      <div className="flex flex-row items-center gap-6">
        {items.map(({ label, colorClassName, imageUrl }, index) => (
          <div key={index} className="flex flex-row items-center gap-2">
            <div
              className={`rounded-full w-[10px] h-[10px] ${colorClassName}`}
              {...(imageUrl
                ? { style: { backgroundImage: `url(${imageUrl})` } }
                : {})}
            />
            <Typography size="xs">{label}</Typography>
          </div>
        ))}
      </div>
    )
  );
};
