import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePackagingApi } from '@api/packagingApi';
import { Spinner } from '@atoms/Spinner';
import { useModalsContext } from '@contexts/modals';
import { useShipmentContext } from '@contexts/shipment';
import { QueryKeys } from '@definitions/QueryKeys';
import { ItemCounter } from '@molecules/ItemCounter';
import { ShipmentBaseModal } from '@organisms/Modals/Shipment/ShipmentBaseModal';
import { useQuery } from '@tanstack/react-query';

import { ShipmentSelectPackagingProps } from './definitions';

const ShipmentSelectPackaging: React.FC<ShipmentSelectPackagingProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  const { getPackagings } = usePackagingApi();
  const { setPackaging, packagings: ctxPackagings } = useShipmentContext();
  const { showShipmentRemarksModal } = useModalsContext();

  const { data: packagings, isFetching: isFetchingPackagings } = useQuery({
    enabled: open,
    queryKey: [QueryKeys.SHIPMENT_PACKAGINGS],
    queryFn: () => getPackagings(),
  });

  const handlePackagingsCountChange = useCallback(
    (id: number) => (count: number) => {
      setPackaging({
        id: id,
        quantity: count,
      });
    },
    [setPackaging],
  );

  const isNextDisabled = useMemo(() => {
    return ctxPackagings?.every((packaging) => packaging.quantity === 0);
  }, [ctxPackagings]);

  return (
    <ShipmentBaseModal
      id="shipment-select-packaging"
      title={t('Modals.ShipmentSelectPackagings.title')}
      open={open}
      onNext={showShipmentRemarksModal}
      isNextDisabled={isNextDisabled}
      onClose={onClose}
    >
      <div className="flex flex-col gap-5 mt-4">
        {packagings?.map(({ image, label, id }) => {
          return (
            <ItemCounter
              key={id + open.toString()}
              icon={{
                pathName: image,
              }}
              label={label}
              onChange={handlePackagingsCountChange(id)}
              startValue={ctxPackagings?.find((p) => p.id === id)?.quantity}
            />
          );
        })}
      </div>
      {isFetchingPackagings && <Spinner className="mt-4" />}
    </ShipmentBaseModal>
  );
};

export default React.memo(ShipmentSelectPackaging);
