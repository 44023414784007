import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import 'chart.js/auto';

import { Card } from '@atoms/Card';
import { Typography } from '@atoms/Typography';
import colors from '@theme/colors';

import { TransferPlusRequestProps } from './definitions';

export const TransferPlusRequest = ({
  chartData,
}: TransferPlusRequestProps) => {
  const { t } = useTranslation();

  return (
    <Card hasPadding={false}>
      <div className="w-full p-4">
        <div className="pb-6">
          <Typography size="lg" isUppercase isBold>
            {t('Statistics.timechart.transferPlusRequest.title')}
          </Typography>
        </div>
        <div className="relative w-full h-[400px]">
          <Line
            data={{
              labels: chartData.labels,
              datasets: [
                {
                  data: chartData.values,
                  borderCapStyle: 'round',
                  backgroundColor: 'transparent',
                  borderColor: 'black',
                  borderWidth: 4,

                  pointHitRadius: 15,

                  pointBorderColor: 'transparent',
                  pointBorderWidth: 4,
                  pointBackgroundColor: 'transparent',
                  pointRadius: 8,

                  pointHoverBorderColor: 'white',
                  pointHoverBorderWidth: 4,
                  pointHoverBackgroundColor: 'black',
                  pointHoverRadius: 8,
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
              font: {
                family: "'Helvetica', sans-serif",
              },
              scales: {
                y: {
                  beginAtZero: true,
                  border: {
                    display: false,
                  },
                  grid: {
                    tickColor: 'transparent',
                    tickLength: 15,
                    color: '#F2F4F6',
                  },
                  ticks: {
                    precision: 0,
                    color: colors['Primary-02'],
                    font: {
                      size: 12,
                      style: 'normal',
                      weight: 'normal',
                    },
                  },
                },
                x: {
                  offset: true,
                  border: {
                    display: false,
                  },
                  grid: {
                    display: false,
                    tickLength: 15,
                  },
                  ticks: {
                    color: colors['Primary-02'],
                    font: {
                      size: 12,
                      style: 'normal',
                      weight: 'normal',
                    },
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  position: 'nearest',
                  displayColors: false,
                  backgroundColor: colors['Primary-02'],
                  xAlign: 'left',
                  yAlign: 'bottom',
                  caretSize: 0,
                  caretPadding: 12,
                  padding: {
                    top: 8,
                    bottom: 8,
                    left: 12,
                    right: 12,
                  },
                  titleColor: colors['Primary-03'],
                  titleFont: {
                    size: 12,
                    style: 'normal',
                    weight: 'normal',
                  },
                  bodyColor: colors['Primary-05'],
                  bodyFont: {
                    size: 20,
                    style: 'normal',
                    weight: 'bold',
                  },
                },
              },
            }}
          />
        </div>
        <div className="flex flex-row items-center"></div>
      </div>
    </Card>
  );
};
