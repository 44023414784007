import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@atoms/Button';
import { Typography } from '@atoms/Typography';
import BaseModal from '@organisms/Modals/BaseModal/BaseModal';
import { ConfirmationModalProps } from '@organisms/Modals/ConfirmationModal/definitions';

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  primaryButtonProps,
  secondaryButtonProps,
}) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      id="confirmationModal"
      size="small"
      open={open}
      onClose={onClose}
      title={title}
      showCloseButton={true}
      footer={
        <div className="flex flex-col gap-2">
          {!primaryButtonProps?.hide && (
            <Button
              type="primary"
              label={primaryButtonProps?.label || t('General.yes')}
              onClick={() => {
                onConfirm();
                onClose();
              }}
            />
          )}
          {!secondaryButtonProps?.hide && (
            <Button
              type="secondary"
              label={secondaryButtonProps?.label || t('General.no')}
              onClick={onClose}
            />
          )}
        </div>
      }
    >
      {content && typeof content === 'string' ? (
        <Typography
          className="mb-4"
          size="sm"
          sizeMd="md"
          color="text-Primary-02"
        >
          {content}
        </Typography>
      ) : (
        content
      )}
    </BaseModal>
  );
};

export default React.memo(ConfirmationModal);
