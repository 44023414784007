import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@atoms/Button/Button.tsx';
import { IconSVG } from '@atoms/IconSVG';
import { Logo } from '@atoms/Logo';
import Typography from '@atoms/Typography/Typography.tsx';
import useWindow from '@hooks/useWindow.tsx';
import { useNavigation } from '@navigation/useNavigation.ts';

import { theme } from '../../../theme';
import { LoginLayoutProps } from './definitions.ts';

const LoginLayout = ({
  CTATitle,
  CTAHandler,
  CTADisabled,
  showGoToPreviousPageCTA,
  children,
}: LoginLayoutProps): ReactElement => {
  const { goBack } = useNavigation();
  const imgUrl = new URL('/assets/images/login_bkg.jpg', import.meta.url).href;

  const { t } = useTranslation();
  const { isMobile } = useWindow();

  return (
    <div className="md:bg-Primary-00 min-h-fit font-normal w-full">
      <div className="flex h-screen">
        <div
          className="absolute w-full h-full lg:w-auto lg:h-auto lg:relative lg:basis-7/12 flex-col border-b-Primary-05 "
          style={{
            backgroundImage: `url(${imgUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: `${isMobile ? 'center' : '40% 25%'}`,
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="login-bkg-wrapper "></div>
        </div>
        <div className=" flex flex-col z-20 lg:basis-5/12 basis-full justify-around">
          {showGoToPreviousPageCTA && (
            <>
              <button
                type="button"
                onClick={goBack}
                className="absolute top-[5%] right-[90%] lg:right-[38%]"
              >
                <IconSVG
                  icon="chevron-left"
                  size={16}
                  color={theme.colors['Primary-05']}
                />
              </button>
              <button
                type="button"
                onClick={goBack}
                className="absolute top-[5%] right-[90%] lg:right-[38%]"
              >
                <IconSVG
                  icon="chevron-left"
                  size={16}
                  color={theme.colors['Primary-05']}
                />
              </button>
            </>
          )}
          <div className={'flex flex-col items-center h-full justify-center'}>
            <Logo />
            <Typography isUppercase color="text-Primary-05" size="xl" isBold>
              {t('General.appTitle')}
            </Typography>
          </div>
          <div className={'mx-[5%] lg:mx-[20%] h-full'}>{children}</div>
          <div className="mx-[5%] lg:mx-[20%] h-full flex items-end mb-[7%]">
            <Button
              label={CTATitle}
              disabled={CTADisabled}
              type="primary"
              onClick={CTAHandler}
              className="size-11 w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(LoginLayout);
