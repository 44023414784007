import React from 'react';

import { Accordion } from '@molecules/Accordion';

import { ProductCategoryAccordionProps } from './definitions';

const ProductCategoryAccordion: React.FC<ProductCategoryAccordionProps> = ({
  open,
  onChange,
  name,
  icon,
  label,
  itemsQty,
  children,
}) => {
  return (
    <Accordion
      open={open}
      onChange={onChange}
      name={name}
      icon={{
        name: icon?.name,
        pathName: icon?.pathName as undefined, // workaround for TS error
      }}
      label={
        <>
          {label} <span className="text-md font-normal">(x {itemsQty})</span>
        </>
      }
    >
      {children}
    </Accordion>
  );
};

export default React.memo(ProductCategoryAccordion);
