import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object({
    nameFR: yup.string().required(t('Form.errors.required')),
    nameEN: yup.string(),
  });
};
