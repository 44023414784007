import { useCallback, useEffect, useState } from 'react';

import useTailwindConfig from '@hooks/useTailwindConfig';

const useWindow = () => {
  const { config } = useTailwindConfig();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [config.screens.sm, handleResize, windowWidth]);

  return {
    windowWidth,
    isMobile: windowWidth <= Number(config.screens.sm.replace('px', '')),
  };
};

export default useWindow;
