import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@atoms/Typography';

const FourZeroFour = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-screen flex flex-col justify-center text-center items-center px-4">
      <Typography isBold className="text-[32px] md:text-[64px]">
        {t('ErrorPage.ops')}
      </Typography>
      <Typography isBold size="md" sizeMd="xxl">
        {t('ErrorPage.somethingWrong')}
      </Typography>
      <Typography size="xs" sizeMd="xxl" className="mt-3 md:mt-9">
        {t('ErrorPage.message')}
      </Typography>
    </div>
  );
};

export default React.memo(FourZeroFour);
