import dayjs from 'dayjs';

import {
  GetShipmentStatisticsResponse,
  ShipmentTimeUnit,
} from '@api/statistics';

import {
  AverageDeliveryTimeProps,
  TransferAndRequestProps,
  TransferPlusRequestProps,
} from './definitions';

export const getLabelByTimeUnit = (
  date: string,
  unit: ShipmentTimeUnit,
): string => {
  const dateObj = dayjs(date);
  switch (unit) {
    case 'day':
      return dateObj.format('MMM D');
    case 'week':
      return `${dateObj.format('MMM')} ${dateObj.startOf('week').format('D')}-${dateObj.endOf('week').format('D')}`;
    case 'month':
      return dateObj.format('MMM');
    case 'year':
      return dateObj.format('YYYY');
  }
};

export const getTransferPlusRequestChartData = (
  dataFromApi: GetShipmentStatisticsResponse | undefined,
): TransferPlusRequestProps['chartData'] => {
  const result: TransferPlusRequestProps['chartData'] = {
    labels: [],
    values: [],
  };

  if (!dataFromApi) {
    return result;
  }

  const {
    flow,
    quantityGraphs: { timeUnit },
  } = dataFromApi;

  dataFromApi.quantityGraphs.columns.forEach(
    ({ timeUnitStartDate, request: requestData, return: returnData }) => {
      result.labels.push(getLabelByTimeUnit(timeUnitStartDate, timeUnit));

      let calculatedValue = 0;

      if (flow === 'request') {
        calculatedValue = requestData.total;
      } else if (flow === 'return') {
        calculatedValue = returnData.total;
      } else {
        calculatedValue = returnData.total + requestData.total;
      }

      result.values.push(calculatedValue);
    },
  );

  return result;
};

export const getTransferAndRequestChartData = (
  dataFromApi: GetShipmentStatisticsResponse | undefined,
): TransferAndRequestProps['chartData'] => {
  const result: TransferAndRequestProps['chartData'] = {
    labels: [],
  };

  if (!dataFromApi) {
    return result;
  }

  const {
    flow,
    quantityGraphs: { timeUnit },
  } = dataFromApi;

  if (!flow || flow === 'return') {
    result.returnClosed = [];
    result.returnInCourse = [];
  }

  if (!flow || flow === 'request') {
    result.requestClosed = [];
    result.requestInCourse = [];
  }

  dataFromApi.quantityGraphs.columns.forEach(
    ({ timeUnitStartDate, return: returnData, request: requestData }) => {
      result.labels.push(getLabelByTimeUnit(timeUnitStartDate, timeUnit));

      if (result.returnClosed && result.returnInCourse) {
        result.returnClosed.push(returnData.closed);
        result.returnInCourse.push(returnData.inCourse);
      }

      if (result.requestClosed && result.requestInCourse) {
        result.requestClosed.push(requestData.closed);
        result.requestInCourse.push(requestData.inCourse);
      }
    },
  );

  return result;
};

export const getAverageDeliveryTimeChartData = (
  dataFromApi: GetShipmentStatisticsResponse | undefined,
): AverageDeliveryTimeProps['chartData'] => {
  const result: AverageDeliveryTimeProps['chartData'] = {
    labels: [],
  };

  if (!dataFromApi) {
    return result;
  }

  const {
    flow,
    averageGraph: { timeUnit },
  } = dataFromApi;

  if (!flow || flow === 'return') {
    result.return = [];
  }

  if (!flow || flow === 'request') {
    result.request = [];
  }

  dataFromApi.averageGraph.columns.forEach(
    ({ timeUnitStartDate, return: returnData, request: requestData }) => {
      result.labels.push(getLabelByTimeUnit(timeUnitStartDate, timeUnit));

      if (result.return) {
        result.return.push(returnData.averageTimeDifference);
      }

      if (result.request) {
        result.request.push(requestData.averageTimeDifference);
      }
    },
  );

  return result;
};
