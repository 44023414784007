import React, { useMemo } from 'react';

import { ButtonProps } from '@atoms/Button/definitions';
import { Typography } from '@atoms/Typography';

const Button = ({
  label,
  type,
  onClick,
  disabled,
  disableUppercase = false,
  className,
}: ButtonProps) => {
  const buttonType = useMemo(() => {
    if (type) {
      return `btn-${type}`;
    }
    return '';
  }, [type]);

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`ui-btn btn py-2 ${buttonType} ${className || ''}`.trim()}
    >
      <Typography
        isUppercase={!disableUppercase}
        size="sm"
        sizeMd="md"
        isBold
        color="inherit"
      >
        {label}
      </Typography>
    </button>
  );
};

export default React.memo(Button);
