import React from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { DatePickerComponentProps } from './definitions';

const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
  className,
  value,
  onChange,
  placeholderText,
  dateFormat,
}) => {
  const { i18n } = useTranslation();
  return (
    <div className={className}>
      <DatePicker
        selected={value}
        onChange={onChange}
        dateFormat={dateFormat}
        placeholderText={placeholderText}
        wrapperClassName={'datePicker'}
        locale={i18n.language}
        formatWeekDay={(nameOfDay) => nameOfDay[0].toUpperCase()}
      />
    </div>
  );
};
export default React.memo(DatePickerComponent);
