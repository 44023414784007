import { ShipmentAddItemCodeModal } from './ShipmentAddItemCodeModal';
import { ShipmentAttachCommentModal } from './ShipmentAttachCommentModal';
import { ShipmentItemDetailsModal } from './ShipmentItemDetailsModal';
import { ShipmentRemarksModal } from './ShipmentRemarksModal';
import { ShipmentImagePreviewModal } from './ShipmentRemarksModal/ShipmentImagePreviewModal';
import { ShipmentResumeModal } from './ShipmentResumeModal';
import { ShipmentSelectAddressModal } from './ShipmentSelectAddressModal';
import { ShipmentSelectPackagingModal } from './ShipmentSelectPackagingModal';
import { ShipmentSelectProductsModal } from './ShipmentSelectProductsModal';

const ShipmentModals = {
  SelectAddress: ShipmentSelectAddressModal,
  SelectProducts: ShipmentSelectProductsModal,
  AddItemCode: ShipmentAddItemCodeModal,
  ItemDetailsModal: ShipmentItemDetailsModal,
  SelectPackaging: ShipmentSelectPackagingModal,
  Remarks: ShipmentRemarksModal,
  Resume: ShipmentResumeModal,
  ImagePreview: ShipmentImagePreviewModal,
  AttachCommentModal: ShipmentAttachCommentModal,
};

export { ShipmentModals };
