import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@atoms/Button';
import { Card } from '@atoms/Card';
import { Spinner } from '@atoms/Spinner';
import { Typography } from '@atoms/Typography';
import { SearchInput } from '@molecules/Form/SearchInput';
import { Table } from '@molecules/Table';

import { AdminTableCardProps } from './definitions';

const AdminTableCard = <TData,>({
  title,
  search,
  table,
  isLoading,
  noDataMessage,
  footerActions,
}: AdminTableCardProps<TData>) => {
  const { t } = useTranslation();

  const Content = useMemo(() => {
    if (isLoading) {
      return (
        <div className="min-h-[100px] flex items-center justify-center">
          <Spinner />
        </div>
      );
    }

    if (table.data.length === 0) {
      return (
        <div className="w-full min-h-[100px] flex items-center justify-center">
          <Typography size="sm" sizeMd="lg">
            {noDataMessage || t('General.noData')}
          </Typography>
        </div>
      );
    }

    return <Table {...table} />;
  }, [isLoading, noDataMessage, t, table]);

  return (
    <Card hasPadding={false}>
      {(title || search) && (
        <div className="w-full flex flex-row justify-between gap-3 px-3 py-6 items-center">
          {title && (
            <Typography isUppercase isBold size="xl">
              {title}
            </Typography>
          )}
          {search && (
            <SearchInput
              name={search.name}
              value={search.value}
              placeholder={search.placeholder ?? t('General.search')}
              onChange={(newValue) => search.onChange(newValue)}
              onClear={() => search.onChange('')}
            />
          )}
        </div>
      )}
      <div className="w-full flex flex-col gap-3 pb-3">
        {Content}
        {footerActions &&
          footerActions.map((action, actionIdx) => (
            <div
              key={actionIdx}
              className="px-3 w-full flex flex-col items-stretch"
            >
              <Button
                type={action.type ?? 'secondary'}
                label={action.label}
                onClick={action.onClick}
              />
            </div>
          ))}
      </div>
    </Card>
  );
};

export default React.memo(AdminTableCard) as typeof AdminTableCard;
