import { Link } from 'react-router-dom';

import { CustomLinkProps } from './definitions.ts';

const CustomLink = ({ children, to, onClick, className }: CustomLinkProps) => {
  return (
    <Link onClick={onClick} to={to} className={className}>
      {children}
    </Link>
  );
};
export default CustomLink;
