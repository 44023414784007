import React, { useMemo } from 'react';

import { TypographySizes } from '@theme/definitions';

import { TypographyProps } from './definitions';

const sizes: Record<TypographySizes, string> = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-md',
  lg: 'text-lg',
  xl: 'text-xl',
  xxl: 'text-xxl',
};

const sizesMd: Record<TypographySizes, string> = {
  xs: 'md:text-xs',
  sm: 'md:text-sm',
  md: 'md:text-md',
  lg: 'md:text-lg',
  xl: 'md:text-xl',
  xxl: 'md:text-xxl',
};

const Typography = ({
  as = 'p',
  isUppercase = false,
  isBold = false,
  size = 'md',
  sizeMd,
  color = 'text-Primary-00',
  colorMd,
  className = '',
  children,
}: TypographyProps) => {
  const Component = as;

  const typographyClass = useMemo(() => {
    const classes = [
      className,
      sizes[size],
      color,
      colorMd ? `md:${colorMd}` : '',
    ];

    if (sizeMd) {
      classes.push(sizesMd[sizeMd]);
    }

    if (isUppercase) {
      classes.push('uppercase');
    }

    if (isBold) {
      classes.push('font-bold');
    } else {
      classes.push('font-normal');
    }

    return classes.filter(Boolean).join(' ');
  }, [className, size, color, colorMd, sizeMd, isUppercase, isBold]);

  return <Component className={typographyClass}>{children}</Component>;
};
export default React.memo(Typography);
