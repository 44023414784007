import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useWindow from '@hooks/useWindow';
import { FormField } from '@molecules/Form/FormField';
import { Select } from '@molecules/Form/Select';

import { FilterCommonProps } from './definitions';

export const ShipmentTypeFilter = ({ value, onChange }: FilterCommonProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindow();

  const options = useMemo(() => {
    return [
      { value: 'return', label: t('Filters.shipmentType.options.transfer') },
      { value: 'request', label: t('Filters.shipmentType.options.request') },
    ];
  }, [t]);

  return (
    <FormField
      label={t('Filters.shipmentType.label')}
      direction={isMobile ? 'row' : 'column'}
    >
      <Select
        value={value}
        onChange={onChange}
        options={options}
        placeholder={t('Filters.shipmentType.placeholder')}
      />
    </FormField>
  );
};
