import React, { useCallback, useEffect, useState } from 'react';

import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import { AccordionProps } from '@molecules/Accordion/definitions';
import { isNullOrUndefined } from '@utils/typeCheck';

const Accordion: React.FC<AccordionProps> = ({
  open: openFromProps,
  onChange: onChangeFromProps,
  name,
  icon,
  label,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isNullOrUndefined(openFromProps)) {
      setIsOpen(openFromProps);
    }
  }, [openFromProps]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.checked;
      setIsOpen(newValue);
      onChangeFromProps?.(newValue);
    },
    [onChangeFromProps],
  );

  return (
    <div className="ui-collapse transition-none">
      <input
        type="checkbox"
        name={name}
        checked={isOpen}
        onChange={handleChange}
      />

      <div className="ui-collapse-title flex items-center py-3 px-0">
        {icon?.name ? (
          <IconSVG icon={icon.name} size={16} />
        ) : icon?.pathName ? (
          <img src={icon.pathName} className="w-[16px]" />
        ) : null}
        <Typography isBold size="lg" className="w-full ml-8">
          {label}
        </Typography>
        <IconSVG
          className="accordion-arrow"
          icon={isOpen ? 'chevron-up' : 'chevron-down'}
          size={16}
        />
      </div>
      <div className="ui-collapse-content pr-0">
        <div className="pl-15 flex flex-col gap-5">{children}</div>
      </div>
    </div>
  );
};

export default React.memo(Accordion);
