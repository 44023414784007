import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@atoms/Button';
import { useModalsContext } from '@contexts/modals';
import { useReceiveContext } from '@contexts/receive';
import useWindow from '@hooks/useWindow';

import ReceiveBaseModal from '../ReceiveBaseModal/ReceiveBaseModal';
import { ReceiveSelectCodeModalProps } from './definitions';

const ReceiveSelectCodeModal: React.FC<ReceiveSelectCodeModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useWindow();
  const { showReceiveShipmentIdModal } = useModalsContext();
  const { flow } = useReceiveContext();
  return (
    <ReceiveBaseModal
      open={open}
      id={'receiveSelectCodeModal'}
      onClose={onClose}
      size={isMobile ? 'large' : 'small'}
      title={t('Modals.ReceiveFlow.SelectCode.title', { context: flow })}
    >
      <div className="flex flex-col gap-2 mt-4">
        {isMobile && (
          <Button
            type="primary"
            label={t('Modals.ReceiveFlow.SelectCode.QRCodeButton')}
            onClick={() => showReceiveShipmentIdModal({ isQRCode: true })}
          />
        )}
        <Button
          type="primary"
          label={t('Modals.ReceiveFlow.SelectCode.shipmentIDButton')}
          onClick={() => showReceiveShipmentIdModal({ isQRCode: false })}
        />
      </div>
    </ReceiveBaseModal>
  );
};

export default React.memo(ReceiveSelectCodeModal);
