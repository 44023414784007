import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { Card } from '@atoms/Card';
import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { useUserContext } from '@contexts/user';
import { StatusHistory } from '@definitions/Shipment';
import { useShipmentData } from '@hooks/useShipmentData';
import { ActivityCardProps } from '@molecules/ActivityCard/definitions';

const ActivityCardMobileLite = ({ shipment }: ActivityCardProps) => {
  const { code, status, ownerOrganization, createdAt } = shipment;

  const acceptedWithReserveCount = useMemo(
    () =>
      shipment?.statusHistory.filter(
        (el: StatusHistory) => el.withReserve === true,
      ).length,
    [shipment?.statusHistory],
  );

  const { t } = useTranslation();
  const { getShipmentType, getShipmentTotalProducts } = useShipmentData();
  const { user } = useUserContext();

  const shipmentType = getShipmentType(shipment, user);
  const shipmentTotalItems = getShipmentTotalProducts(shipment);
  const entryDate = createdAt && dayjs(createdAt).format('DD/MM/YYYY');

  const icon = useMemo(
    () => (shipmentType === 'incoming' ? 'download-box' : 'upload-box'),
    [shipmentType],
  );

  const { showShipmentDetailsModal } = useModalsContext();

  return (
    <Card
      backgroundColor="bg-[#F8F8F8]"
      className="my-2 py-2"
      onClick={() => showShipmentDetailsModal({ shipment })}
    >
      <div className="flex w-full items-center">
        <div className="flex w-full items-center">
          <IconSVG icon={icon} size={24} className="my-2 mr-3" />
          <div className="px-2 border-l border-Primary-02 flex items-start flex-col ">
            <div className="flex gap-1">
              {acceptedWithReserveCount > 0 && (
                <div className="flex items-center">
                  <IconSVG icon="shield-warning" size={16} />
                </div>
              )}
              <Typography isUppercase isBold size="sm" sizeMd="xl">
                {t('Home.ActivityCard.ID')}: {code}
              </Typography>
            </div>
            {shipmentTotalItems && (
              <div className="text-center col-start-3 text-sm text-Primary-03">
                {shipmentTotalItems}
              </div>
            )}
          </div>
        </div>
        <Typography isBold size="sm" className="md:mr-8 capitalize">
          {status}
        </Typography>
      </div>
      <div className="w-full flex gap-23 items-center">
        <div className="w-full">
          <div className="mt-3">
            <div className="w-full flex gap-2 flex-col">
              <div className="grid grid-cols-3 gap-4">
                <Typography color="text-Primary-02">
                  {t('Home.ActivityCard.address')}
                </Typography>
                <div className="col-span-2">
                  <Typography className="overflow-hidden text-ellipsis ">
                    {ownerOrganization?.address}
                  </Typography>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <Typography color="text-Primary-02">
                  {t('Home.ActivityCard.owner')}
                </Typography>
                <div className="col-span-2">
                  <Typography className="overflow-hidden text-ellipsis ">
                    {ownerOrganization?.name}
                  </Typography>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <Typography color="text-Primary-02">
                  {t('Home.ActivityCard.entryDate')}
                </Typography>
                <div className="col-span-2">
                  <Typography className="overflow-hidden text-ellipsis ">
                    {entryDate}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default React.memo(ActivityCardMobileLite);
