import React from 'react';

import { IconSVG } from '@atoms/IconSVG';
import { FloatingButtonProps } from '@molecules/FloatingButton/definitions';

const FloatingButton: React.FC<FloatingButtonProps> = ({
  onClick,
  className,
  icon,
}) => {
  return (
    <button
      onClick={onClick}
      className={`ui-btn absolute z-[1] bg-Primary-01 border-0 hover:bg-Primary-01 w-[55px] h-[55px] rounded-full ${className || ''}`.trim()}
      style={{
        boxShadow: '3px 6px 20px 0px #B1B1B1',
      }}
    >
      <IconSVG icon={icon} size={20} color="white" />
    </button>
  );
};

export default React.memo(FloatingButton);
