import { useCallback } from 'react';

import usePrivateApi from '@api/base/private';
import usePublicApi from '@api/base/public';
import { QueryKeys } from '@definitions/QueryKeys';
import { useLogger } from '@hooks/useLogger';
import { useMutation, useQuery } from '@tanstack/react-query';

import { GetPasswordPolicyResponse, LoginResponse } from './definitions';

export const useAuthApi = () => {
  const { logError } = useLogger();
  const publicApi = usePublicApi();
  const privateApi = usePrivateApi();

  //POST /login
  const login = useCallback(
    async (payload: { email: string; password: string }) => {
      try {
        const { data } = await publicApi.post<LoginResponse>('/login', payload);
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, publicApi],
  );

  const loginMutation = useMutation({ mutationFn: login });

  //GET /password-policy
  const _getPasswordPolicy = useCallback(async () => {
    try {
      const { data } =
        await publicApi.get<GetPasswordPolicyResponse>('/password-policy');
      return data;
    } catch (error) {
      logError(error);
      throw error;
    }
  }, [logError, publicApi]);

  const useGetPasswordPolicy = () => {
    return useQuery({
      queryKey: [QueryKeys.LOGIN_PASSWORD_POLICY],
      queryFn: () => _getPasswordPolicy(),
    });
  };

  //POST /forgot-password
  const forgotPassword = useCallback(
    async (payload: { email: string }) => {
      try {
        const { data } = await publicApi.post('/forgot-password', payload);
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, publicApi],
  );

  const forgotPasswordMutation = useMutation({ mutationFn: forgotPassword });

  //PUT /reset-password
  const resetPassword = useCallback(
    async (payload: { password: string; token: string }) => {
      try {
        const { data } = await publicApi.put('/reset-password', payload);
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, publicApi],
  );

  const resetPasswordMutation = useMutation({ mutationFn: resetPassword });

  //PUT /change-password
  const changePassword = useCallback(
    async (payload: { oldPassword: string; newPassword: string }) => {
      try {
        const { data } = await privateApi.put('/change-password', payload);
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const changePasswordMutation = useMutation({ mutationFn: changePassword });

  //POST /logout
  const logout = useCallback(
    async (payload: { refreshToken: string }) => {
      try {
        const { data } = await privateApi.post('/logout', payload);
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  // Return API functions
  return {
    login: loginMutation,
    getPasswordPolicy: useGetPasswordPolicy,
    forgotPassword: forgotPasswordMutation,
    resetPassword: resetPasswordMutation,
    changePassword: changePasswordMutation,
    logout,
  };
};
