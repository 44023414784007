import flexBasis from './basis.ts';
import colors from './colors.ts';
import fontFamily from './fontFamily.ts';
import fontSize from './fontSize.ts';
import screens from './screens.ts';
import spacing from './spacing.ts';

const theme = {
  colors,
  fontSize: fontSize,
  spacing,
  extend: {
    flexBasis,
    screens,
    backgroundSize: { full: 'auto 100%' },
    fontFamily: fontFamily,
  },
};
export default theme;
