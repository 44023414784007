import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@atoms/Button';
import { Typography } from '@atoms/Typography';
import BaseModal from '@organisms/Modals/BaseModal/BaseModal';

import { ResumeConfirmationModalProps } from './definitions';

const ResumeConfirmationModal: React.FC<ResumeConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  code,
  primaryButtonProps,
}) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      id="resumeConfirmationModal"
      size="small"
      open={open}
      onClose={onClose}
      title={title}
      showCloseButton={false}
      footer={
        <div className="flex flex-col gap-2">
          <Button
            type="primary"
            label={primaryButtonProps?.label || t('General.ok')}
            onClick={() => {
              onConfirm();
              onClose();
            }}
          />
        </div>
      }
    >
      <div className="flex flex-col gap-4 mb-9">
        <Typography size="sm" sizeMd="md" color="text-Primary-02">
          {content}
        </Typography>
        <Typography size="md" sizeMd="lg" isBold className="text-center">
          <b>{code}</b>
        </Typography>
      </div>
    </BaseModal>
  );
};

export default React.memo(ResumeConfirmationModal);
