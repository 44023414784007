import { BaseLayout } from '@templates/BaseLayout';

const InStockPage = () => {
  return (
    <BaseLayout>
      <h1>InStockPage</h1>
    </BaseLayout>
  );
};
export default InStockPage;
