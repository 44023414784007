import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

export const useValiadtionSchema = () => {
  const { t } = useTranslation();

  return yup.object({
    name: yup.string().required(t('Form.errors.required')),
    address: yup.string().required(t('Form.errors.required')),
    type: yup.string().required(t('Form.errors.required')),
  });
};
