import { useCallback } from 'react';

import usePrivateApi from '@api/base/private';
import { User } from '@definitions/Users';
import { useLogger } from '@hooks/useLogger';

import {
  CreateUserParams,
  GetUserListParams,
  GetUserListResponse,
  UpdateUserStatusParams,
} from './definitions';

export const useUserApi = () => {
  const { logError } = useLogger();

  const privateApi = usePrivateApi();

  //GET /users/me
  const getUserMe = useCallback(async () => {
    try {
      const { data } = await privateApi.get<User>('/users/me');
      return data;
    } catch (error) {
      logError(error);
      throw error;
    }
  }, [logError, privateApi]);

  //PUT /users/change-language

  const changeLanguage = useCallback(
    async (payload: { languageId: User['language']['id'] }) => {
      try {
        const { data } = await privateApi.put(
          '/users/change-language',
          payload,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const getUserList = useCallback(
    async (params?: GetUserListParams) => {
      try {
        const { data } = await privateApi.get<GetUserListResponse>('/users', {
          params,
        });
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const updateUserStatus = useCallback(
    async ({ id, enabled }: UpdateUserStatusParams) => {
      try {
        await privateApi.patch(`/users/${id}/toggle`, {
          enabled,
        });
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const createUser = useCallback(
    async (params: CreateUserParams) => {
      try {
        const { data } = await privateApi.post('/users', params);
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  // Return API functions
  return {
    getUserMe,
    changeLanguage,
    getUserList,
    updateUserStatus,
    createUser,
  };
};
