import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthApi } from '@api/authApi';
import { Typography } from '@atoms/Typography';
import { useLogger } from '@hooks/useLogger';
import { emailRegex } from '@hooks/useRegex';
import useToast from '@hooks/useToast.tsx';
import { Input } from '@molecules/Form/Input';
import { LoginLayout } from '@templates/LoginLayout';

const ForgotPassword = () => {
  const [userEmail, setUserEmail] = useState('');
  const { logError } = useLogger();

  const { t } = useTranslation();

  const { showCustomToast, showWarningToast } = useToast();

  const { forgotPassword } = useAuthApi();

  const isFormValid = useMemo(() => emailRegex.test(userEmail), [userEmail]);

  const forgotPasswordHandler = useCallback(() => {
    forgotPassword
      .mutateAsync({ email: userEmail })
      .then(() => {
        showCustomToast('email', t('Login.ForgotPasswordPage.toastMessage'));
        setUserEmail('');
      })
      .catch((error) => {
        showWarningToast(t('Login.ForgotPasswordPage.invalidEmail'));
        setUserEmail('');
        logError(error);
      });
  }, [
    forgotPassword,
    logError,
    showCustomToast,
    showWarningToast,
    t,
    userEmail,
  ]);

  return (
    <LoginLayout
      CTATitle={t('Login.ForgotPasswordPage.CTA')}
      CTAHandler={forgotPasswordHandler}
      showGoToPreviousPageCTA
      CTADisabled={!isFormValid}
    >
      <Typography isUppercase color="text-Primary-05" size="xl" isBold>
        {t('Login.ForgotPasswordPage.title')}
      </Typography>
      <Typography color="text-Primary-04" size="sm" className="mt-4">
        {t('Login.ForgotPasswordPage.description')}
      </Typography>
      <div className="mt-10">
        <Input
          variant="light"
          placeholder={t('Login.ForgotPasswordPage.emailPlaceholder')}
          placeholderUppercase
          name={t('Login.LoginPage.username')}
          type="email"
          value={userEmail}
          onChange={setUserEmail}
        />
      </div>
    </LoginLayout>
  );
};

export default ForgotPassword;
