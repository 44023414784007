import { useCallback } from 'react';

import { useAuthentication } from '@contexts/auth';
import { TokenPair } from '@contexts/auth/definitions';
import { useModalsContext } from '@contexts/modals';
import { useShipmentContext } from '@contexts/shipment';
import { useUserContext } from '@contexts/user';
import { useQueryClient } from '@tanstack/react-query';

export const useAuthActions = () => {
  const { setUser } = useUserContext();
  const { setTokens, removeTokens } = useAuthentication();
  const { hideAllShipmentModals } = useModalsContext();
  const { reset: resetShipmentContext } = useShipmentContext();
  const queryClient = useQueryClient();

  const signIn = useCallback(
    async (tokenData: TokenPair) => {
      setTokens(tokenData);
    },
    [setTokens],
  );

  const signOut = useCallback(async () => {
    removeTokens();

    queryClient.clear();

    setUser(null);

    // Reset shipment state
    hideAllShipmentModals();
    resetShipmentContext();
  }, [
    hideAllShipmentModals,
    queryClient,
    removeTokens,
    resetShipmentContext,
    setUser,
  ]);

  return {
    signIn,
    signOut,
  };
};
