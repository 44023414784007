import { Organization } from '@definitions/Organization';
import { TranslationKey } from '@translations/definitions';

export type User = {
  id: number;
  role: UserRole;
  organizationId: number;
  email: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  firstPassword: string;
  organization: Organization;
  language: {
    id: number;
    code: TranslationKey;
  };
};

export type UserRole = {
  id: number;
  name: Roles;
};

export type UserOrganization = {
  id: number;
  name: string;
  organizationTypeId: number;
};

export enum Roles {
  ADMIN = 'admin',
  USER = 'user',
  DRIVER = 'driver',
}
