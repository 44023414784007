import { useCallback } from 'react';

import usePrivateApi from 'src/api/base/private';

import { useLogger } from '@hooks/useLogger';

import {
  GetNotificationSettingsResponse,
  UpdateNotificationSettingsPayload,
} from './definitions';

export const useReasonForRequestApi = () => {
  const { logError } = useLogger();

  const privateApi = usePrivateApi();

  const getNotificationSettings = useCallback(async () => {
    try {
      const { data } = await privateApi.get<GetNotificationSettingsResponse>(
        '/notification-settings',
      );
      return data;
    } catch (error) {
      logError(error);
      throw error;
    }
  }, [logError, privateApi]);

  const updateNotificationSettings = useCallback(
    async (payload: UpdateNotificationSettingsPayload) => {
      try {
        await privateApi.patch('notification-settings', {
          ...payload,
        });
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  // Return API functions
  return {
    getNotificationSettings,
    updateNotificationSettings,
  };
};
