import { toast } from 'react-toastify';

import { IconSVG } from '@atoms/IconSVG';
import { IconNames } from '@atoms/IconSVG/definitions.ts';

import { theme } from '../theme';


const useToast = () => {
  const showCustomToast = (icon: IconNames, message: string) => {
    toast(message, {
      toastId: 0,
      icon: <IconSVG icon={icon} size={24} color={theme.colors['Primary-05']} />,
    });
  };

  const showSuccessToast = (message: string) => {
    toast(message, {
      toastId: 1,
      icon: <IconSVG icon={'success_circle'} size={24} />,
    });
  };

  const showWarningToast = (message: string) => {
    toast(message, {
      toastId: 2,
      icon: <IconSVG icon={'warning_circle'} size={24} />,
    });
  };

  return {
    showCustomToast,
    showSuccessToast,
    showWarningToast,
  };
};

export default useToast;
