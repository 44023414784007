import { useCallback, useEffect, useRef } from 'react';

import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import { StorageKey } from 'src/definitions/Navigation';

import { TokenData } from '@api/authApi/definitions';
import { ServerErrorResponseCode } from '@api/definitions';
import { useAuthentication } from '@contexts/auth';
import { useAuthActions } from '@hooks/useAuthActions';
import { useLogger } from '@hooks/useLogger';

/**
 * Flag used to check when a refresh token call is happening
 * In this way the refresh token api call will not happen multiple times
 * Needs to be in global scope to work
 */
let isRefreshingToken = false;

const usePrivateApi = () => {
  const { setTokens } = useAuthentication();
  const { logError } = useLogger();
  const { signOut } = useAuthActions();
  const baseURL = import.meta.env.VITE_BASE_URL;

  const privateApi = useRef<AxiosInstance>(
    axios.create({
      timeout: 20000,
      baseURL,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    }),
  );

  /**
   * We are forced to use the privateApi instance here
   * because using useAuthApi will cause a circular dependency
   */
  const getNewToken = useCallback(
    async (payload: { refreshToken: string }) => {
      try {
        const { data } = await privateApi.current.post<{
          tokenData: TokenData;
        }>('/refresh-token', payload);
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  useEffect(() => {
    const apiInstance = privateApi.current;

    const interceptor = apiInstance.interceptors.request.use(
      (config) => {
        const storedToken = Cookies.get(StorageKey.Token);
        const authHeader = `Bearer ${storedToken}`;

        if (config.headers) {
          if (authHeader) {
            config.headers.Authorization = authHeader;
          }
        }

        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      },
    );

    // useEffect cleanup function to remove interceptor before re-adding it
    return () => apiInstance.interceptors.request.eject(interceptor);
  }, []);

  useEffect(() => {
    const apiInstance = privateApi.current;

    const interceptor = apiInstance.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        const errorStatus = error?.response?.status;
        const errorCode = error?.response?.data?.code;
        const originalRequestConfig = error.config;

        if (
          errorStatus === 401 &&
          errorCode === ServerErrorResponseCode.E_UNAUTHORIZED &&
          !isRefreshingToken
        ) {
          const refreshToken = Cookies.get(StorageKey.RefreshToken);

          // If refresh token not found or refresh token call already done
          // Log the user out of the system
          if (!refreshToken || originalRequestConfig._retry) {
            signOut();
            return Promise.reject(error);
          }

          isRefreshingToken = true;
          originalRequestConfig._retry = true;

          return getNewToken({ refreshToken })
            .then((data) => {
              // Update access token and refresh token
              const newAccessToken = data?.tokenData?.accessToken;
              const newAccessTokenExpires = data?.tokenData?.expiresIn;
              const newRefreshToken = data?.tokenData?.refreshToken;
              const newRefreshTokenExpires = data?.tokenData?.refreshExpiresIn;

              if (newAccessToken && newRefreshToken) {
                setTokens({
                  token: newAccessToken,
                  refreshToken: newRefreshToken,
                  tokenExpires: newAccessTokenExpires,
                  refreshTokenExpires: newRefreshTokenExpires,
                });

                // Instantly update authorization header for this request
                apiInstance.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
                originalRequestConfig.headers.Authorization = `Bearer ${newAccessToken}`;

                return apiInstance(originalRequestConfig);
              }

              return Promise.reject(
                new Error('New access token or refresh token not received'),
              );
            })
            .catch((error) => {
              // If error during refresh token call
              // Log the user out of the system
              signOut();
              return Promise.reject(error);
            })
            .finally(() => {
              isRefreshingToken = false;
            });
        }

        return Promise.reject(error);
      },
    );

    // useEffect cleanup function to remove interceptor before re-adding it
    return () => apiInstance.interceptors.response.eject(interceptor);
  }, [getNewToken, setTokens, signOut]);

  return privateApi.current;
};

export default usePrivateApi;
