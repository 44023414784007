import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@atoms/Typography';
import Items from '@organisms/Items';
import { BaseLayout } from '@templates/BaseLayout';

const ItemsPage = () => {
  const { t } = useTranslation();

  return (
    <BaseLayout>
      <div className="flex flex-col">
        <div className="sticky z-[2] top-0 px-4 bg-Primary-05 md:bg-Primary-04 py-6">
          <Typography isUppercase isBold size="xl">
            {t('ItemsPage.title')}
          </Typography>
        </div>
        <div className="z-[1] px-4 pb-4 flex flex-col gap-3">
          <Items.ProductCategories />
          <Items.Supports />
          <Items.Packagings />
          <Items.ReasonForRequest />
          <Items.BillableCenterCost />
        </div>
      </div>
    </BaseLayout>
  );
};

export default React.memo(ItemsPage);
