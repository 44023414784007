import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePackagingApi } from '@api/packagingApi';
import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import { QueryKeys } from '@definitions/QueryKeys';
import { useQuery } from '@tanstack/react-query';

import { PackagingSectionProps } from './definitions';

const PackagingSection = ({
  onEdit,
  packagings: packagingsFromProps,
}: PackagingSectionProps) => {
  const { t } = useTranslation();
  const { getPackagings } = usePackagingApi();

  const { data: apiPackagings } = useQuery({
    queryKey: [QueryKeys.SHIPMENT_PACKAGINGS],
    queryFn: () => getPackagings(),
  });

  const parsedPackagings = useMemo(() => {
    return packagingsFromProps.map((packaging) => {
      const matchingPackaging = apiPackagings?.find(
        (apiPackaging) => apiPackaging.id === packaging.id,
      );
      return {
        ...packaging,
        label: matchingPackaging?.label || '',
      };
    });
  }, [apiPackagings, packagingsFromProps]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-start gap-2">
        <Typography size="sm" sizeMd="md" color="text-Primary-02">
          {t('Modals.GenericShipmentResume.packaging')}:
        </Typography>
        <div className="flex-1 flex flex-col gap-1">
          {parsedPackagings.map((packaging) => {
            return (
              <Typography size="sm" sizeMd="md" key={packaging.id}>
                {packaging.quantity} {packaging.label}
              </Typography>
            );
          })}
        </div>
        <IconSVG icon="edit" size={24} onClick={onEdit} />
      </div>
    </div>
  );
};

export default React.memo(PackagingSection);
