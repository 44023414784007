import React, { useCallback } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@atoms/Button';
import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFilePreview } from '@hooks/useFilePreview';
import useToast from '@hooks/useToast';
import { FileInputButton } from '@molecules/FileInputButton';
import { FormField } from '@molecules/Form/FormField';
import { Input } from '@molecules/Form/Input';
import { useMutation } from '@tanstack/react-query';
import imagePlaceholder from '@theme/assets/image-placeholder.svg';
import { LanguageId } from '@translations/definitions';

import { BaseModal } from '../BaseModal';
import {
  AddGenericItemModalProps,
  CreateItemParams,
  FormValues,
} from './definitions';
import { useValidationSchema } from './validation';

const AddGenericItemModal = ({
  open,
  onClose,
  createItem,
  addItemLabelNs,
  addEditItemLabelNs,
  showImageField,
  onItemCreated,
}: AddGenericItemModalProps) => {
  const { t } = useTranslation();

  const validation = useValidationSchema();

  const { showSuccessToast, showWarningToast } = useToast();

  const { control, handleSubmit, reset } = useForm<FormValues>({
    resolver: yupResolver(validation),
    defaultValues: {
      nameFR: '',
      nameEN: '',
      image: undefined,
    },
  });

  const imageFileValue = useWatch({
    control,
    name: 'image',
  });

  const { previewUrl } = useFilePreview(imageFileValue ?? null);

  const handleClose = useCallback(() => {
    onClose?.();
    reset();
  }, [onClose, reset]);

  const { mutateAsync: callCreateItem, isPending: isCreatingItem } =
    useMutation({
      mutationFn: createItem,
      onSuccess: () => {
        showSuccessToast(t(`Modals.${addItemLabelNs}.successMessage`));
        onItemCreated?.();
        handleClose();
      },
      onError: () => {
        showWarningToast(t(`Modals.${addItemLabelNs}.errorMessage`));
      },
    });

  const isImageChanged = useWatch({
    control,
    name: 'image',
  });

  const onSubmit = useCallback<SubmitHandler<FormValues>>(
    async ({ nameFR, nameEN, image }) => {
      const params: CreateItemParams = {
        name: nameFR,
        labels: nameEN
          ? [
              { languageId: LanguageId.FR, label: nameFR },
              { languageId: LanguageId.EN, label: nameEN },
            ]
          : [
              { languageId: LanguageId.FR, label: nameFR },
              { languageId: LanguageId.EN, label: nameFR },
            ],
      };

      if (image) {
        params.image = image;
      }

      await callCreateItem(params);
    },
    [callCreateItem],
  );

  return (
    <BaseModal
      id="addProductCategoryModal"
      title={t(`Modals.${addItemLabelNs}.title`)}
      size="medium"
      showCloseButton={true}
      open={open}
      onClose={handleClose}
    >
      <div className="flex flex-col gap-9 items-stretch mt-4">
        <div className="flex flex-col gap-6 items-stretch">
          <div className="flex flex-col gap-2">
            <Typography size="lg" isBold>
              {t(`Modals.${addEditItemLabelNs}.fields.name.title`)}
            </Typography>
            <Typography size="md">
              {t(`Modals.${addEditItemLabelNs}.fields.name.description`, '')}
              {/* fallback to empty string if description is not defined */}
            </Typography>
            <Controller
              control={control}
              name="nameFR"
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <FormField
                  label={t(`Modals.${addEditItemLabelNs}.fields.name.label`, {
                    context: 'fr',
                  })}
                  required
                  direction="column"
                >
                  <Input
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder={t(
                      `Modals.${addEditItemLabelNs}.fields.name.placeholder`,
                    )}
                    error={error?.message}
                    disabled={isCreatingItem}
                  />
                </FormField>
              )}
            />
            <Controller
              control={control}
              name="nameEN"
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <FormField
                  label={t(`Modals.${addEditItemLabelNs}.fields.name.label`, {
                    context: 'en',
                  })}
                  direction="column"
                >
                  <Input
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder={t(
                      `Modals.${addEditItemLabelNs}.fields.name.placeholder`,
                    )}
                    error={error?.message}
                    disabled={isCreatingItem}
                  />
                </FormField>
              )}
            />
          </div>
          {showImageField && (
            <div className="flex flex-col gap-2">
              <Typography size="lg" isBold>
                {t(`Modals.${addEditItemLabelNs}.fields.image.title`)}
              </Typography>
              <Controller
                control={control}
                name="image"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <div className="flex flex-col gap-2 items-start">
                    <div className="flex flex-row gap-5 items-start">
                      {previewUrl ? (
                        <div className="w-[70px] h-[70px] border border-Primary-04 rounded-[5px] flex flex-row items-center justify-center overflow-hidden">
                          <img className="w-[40px] h-auto" src={previewUrl} />
                        </div>
                      ) : (
                        <div className="w-[70px] h-[70px] border border-Primary-04 rounded-[5px] flex flex-row items-center justify-center overflow-hidden">
                          <img
                            className="w-[70px] h-auto"
                            src={imagePlaceholder}
                          />
                        </div>
                      )}
                      <div className="flex-1 flex flex-col items-start gap-2">
                        <Typography size="md">
                          {t(`Modals.${addEditItemLabelNs}.fields.image.label`)}
                        </Typography>
                        <Typography size="md" color="text-Primary-03">
                          {t(
                            `Modals.${addEditItemLabelNs}.fields.image.acceptedFiles`,
                          )}
                        </Typography>
                        <FileInputButton
                          onChange={onChange}
                          accept="image/*"
                          element={({ onClick, onClear }) => (
                            <>
                              <Button
                                type="secondary"
                                label={t(
                                  `Modals.${addEditItemLabelNs}.fields.image.buttonLabel`,
                                )}
                                onClick={onClick}
                                disabled={isCreatingItem}
                              />
                              <div className="w-full flex flex-row items-center justify-between">
                                <Typography
                                  size="md"
                                  color="text-Primary-02"
                                  className="truncate overflow-hidden text-ellipsis w-[90%]"
                                >
                                  {value
                                    ? value.name
                                    : t(
                                        `Modals.${addEditItemLabelNs}.fields.image.noFile`,
                                      )}
                                </Typography>
                                {isImageChanged && (
                                  <IconSVG
                                    icon="trash"
                                    size={18}
                                    onClick={onClear}
                                    disabled={isCreatingItem}
                                  />
                                )}
                              </div>
                            </>
                          )}
                        />
                      </div>
                    </div>
                    {error && (
                      <Typography size="xs" sizeMd="sm" color="text-System-red">
                        {error?.message}
                      </Typography>
                    )}
                  </div>
                )}
              />
            </div>
          )}
        </div>
        <div className="flex flex-row gap-3 items-center">
          <Button
            className="flex-1"
            type="secondary"
            label={t('General.cancel')}
            onClick={handleClose}
          />
          <Button
            className="flex-1"
            type="primary"
            label={t('General.save')}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default React.memo(AddGenericItemModal);
