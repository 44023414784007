import { useCallback } from 'react';

import usePrivateApi from 'src/api/base/private';

import { useLogger } from '@hooks/useLogger';

import { GetLanguagesResponse } from './definitions';

export const useLanguageApi = () => {
  const { logError } = useLogger();

  const privateApi = usePrivateApi();

  const getLanguages = useCallback(async () => {
    try {
      const { data } = await privateApi.get<GetLanguagesResponse>('/languages');
      return data;
    } catch (error) {
      logError(error);
      throw error;
    }
  }, [logError, privateApi]);

  // Return API functions
  return {
    getLanguages,
  };
};
