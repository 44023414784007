import React from 'react';

import { TextareaProps } from '@atoms/Textarea/definitions';
import { Typography } from '@atoms/Typography';

const Textarea: React.FC<TextareaProps> = ({
  value,
  onChange,
  rows = 3,
  maxLength,
  showCounter = false,
  ...propsRest
}) => {
  return (
    <div>
      <textarea
        className="ui-textarea ui-textarea-bordered textarea-md w-full rounded-none focus:outline-0"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        rows={rows}
        maxLength={maxLength}
        {...propsRest}
      />
      {showCounter && maxLength && (
        <Typography
          size="xs"
          color="text-Primary-03"
          className="text-right"
        >{`${String(value).length} / ${maxLength}`}</Typography>
      )}
    </div>
  );
};

export default React.memo(Textarea);
