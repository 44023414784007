import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useBillableCenterCostApi } from '@api/billableCenterCost';
import { useProductApi } from '@api/productApi';
import { useReasonForRequestApi } from '@api/reasonForRequestApi';
import { Button } from '@atoms/Button';
import { Textarea } from '@atoms/Textarea';
import { useShipmentContext } from '@contexts/shipment';
import { QueryKeys } from '@definitions/QueryKeys';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormField } from '@molecules/Form/FormField';
import { Input } from '@molecules/Form/Input';
import { Radio } from '@molecules/Form/Radio';
import { Select } from '@molecules/Form/Select';
import BaseModal from '@organisms/Modals/BaseModal/BaseModal';
import { useQuery } from '@tanstack/react-query';
import { isNullOrUndefined } from '@utils/typeCheck';

import { useItemDetailsModalSchema } from './ItemDetailsModalSchema';
import { FormValues, ShipmentItemDetailsModalProps } from './definitions';

const ShipmentItemDetailsModal: React.FC<ShipmentItemDetailsModalProps> = ({
  open,
  onClose: onCloseModal,
  type: typeFromProps,
  product,
}) => {
  const { t } = useTranslation();
  const { getReasonForRequests } = useReasonForRequestApi();
  const { getBillableCenterCosts } = useBillableCenterCostApi();
  const { getProductCategories } = useProductApi();
  const {
    setProduct,
    removeAllProducts,
    removeAllSupports,
    products: ctxProducts,
  } = useShipmentContext();

  // Disable main field only if product has an id
  const shouldDisableBaseDetailsFields =
    !!product &&
    !isNullOrUndefined(product) &&
    !isNullOrUndefined(product.productId) &&
    product.productId > 0;

  // Show less fields if type is sts or consignment
  const isFullForm = typeFromProps !== 'sts' && typeFromProps !== 'consignment';

  const { data: productCategories } = useQuery({
    enabled: open,
    queryKey: [QueryKeys.PRODUCT_CATEGORIES],
    queryFn: () => getProductCategories(),
  });

  const { data: billableCenterCosts } = useQuery({
    enabled: open,
    queryKey: [QueryKeys.SHIPMENT_BILLABLE_CENTER_COSTS],
    queryFn: getBillableCenterCosts,
  });

  const { data: reasonForRequests } = useQuery({
    enabled: open,
    queryKey: [QueryKeys.SHIPMENT_REASON_FOR_REQUESTS],
    queryFn: () => getReasonForRequests(),
  });

  const {
    handleSubmit,
    control,
    formState,
    reset: resetForm,
    watch,
    trigger,
  } = useForm({
    resolver: yupResolver(
      useItemDetailsModalSchema({
        type: typeFromProps,
        isFullForm,
        isTypeOther: typeFromProps === 'other',
      }),
    ),
    reValidateMode: 'onBlur',
  });

  const watchedBillable = watch('Billable');
  const watchedReasonForRequest = watch('ReasonForRequest');

  useEffect(() => {
    const matchingCategory = productCategories?.find(
      (cat) => cat.id === product?.productCategoryId,
    );

    const matchingBillableCenterCost = billableCenterCosts?.find(
      (b) => b.id === product?.billableCenterCostId,
    );

    const matchingReasonForRequest = reasonForRequests?.find(
      (r) => r.id === product?.reasonForRequestId,
    );

    const calculatedBillable =
      typeof product?.billable === 'undefined'
        ? 'yes'
        : product?.billable
          ? 'yes'
          : 'no';

    resetForm({
      StyleID: product?.styleId || '',
      Category: matchingCategory?.label || '',
      NameOfProduct: product?.name || '',
      Size: product?.size || '',
      Billable: calculatedBillable,
      BillableCenterCost: matchingBillableCenterCost?.name || '',
      ReasonForRequest: matchingReasonForRequest?.name || '',
      Comment: product?.comment || '',
    });
  }, [
    billableCenterCosts,
    isFullForm,
    product,
    productCategories,
    reasonForRequests,
    resetForm,
  ]);

  const handleClose = useCallback(() => {
    onCloseModal?.();
    resetForm();
  }, [onCloseModal, resetForm]);

  const onSubmitHandler = useCallback(
    (data: FormValues) => {
      const matchingBillableCenterCost = billableCenterCosts?.find(
        (b) => b.name === data.BillableCenterCost,
      );

      const matchingReasonForRequest = reasonForRequests?.find(
        (r) => r.name === data.ReasonForRequest,
      );

      // edit mode
      if (product) {
        setProduct({
          productCategoryId: product.productCategoryId,
          productId: product.productId,
          name: product.name,
          styleId: data.StyleID,
          styleIdType: product?.styleIdType,
          size: data.Size || '',
          reasonForRequestId: matchingReasonForRequest!.id,
          billableCenterCostId:
            data.Billable === 'no' ? undefined : matchingBillableCenterCost?.id,
          billable: data.Billable === 'yes',
          comment: data.Comment || '',
        });
      } else {
        // new product
        if (typeFromProps === 'sts' || typeFromProps === 'consignment') {
          // STS and Consignment only allow one product
          removeAllProducts();
          removeAllSupports();
        }

        const matchingProductCategory = productCategories?.find(
          (cat) => cat.label === data.Category,
        );

        setProduct({
          productCategoryId: data.Category
            ? matchingProductCategory!.id
            : undefined,
          // set negative id if product is manually added, it will be parsed before sending to the API
          productId: (ctxProducts.length || 1) * -1,
          name: data.NameOfProduct || '',
          styleId: data.StyleID,
          styleIdType: typeFromProps,
          size: data.Size || '',
          reasonForRequestId: matchingReasonForRequest!.id,
          billableCenterCostId:
            data.Billable === 'no' ? undefined : matchingBillableCenterCost?.id,
          billable: data.Billable === 'yes',
          comment: data.Comment || '',
        });
      }

      handleClose();
    },
    [
      billableCenterCosts,
      ctxProducts.length,
      handleClose,
      product,
      productCategories,
      reasonForRequests,
      removeAllProducts,
      removeAllSupports,
      setProduct,
      typeFromProps,
    ],
  );

  const [categorySearchValue, setCategorySearchValue] = useState('');
  const categoryOptions = useMemo(
    () =>
      productCategories
        ?.map((cat) => ({
          label: cat.label,
          value: cat.label,
        }))
        .filter(
          (cat) =>
            cat.label.toLowerCase().indexOf(categorySearchValue.toLowerCase()) >
            -1,
        ) || [],
    [categorySearchValue, productCategories],
  );

  const [billableCostCenterSearchValue, setBillableCostCenterSearchValue] =
    useState('');
  const billableCostCenterOptions = useMemo(
    () =>
      billableCenterCosts
        ?.map((cost) => ({
          label: cost.name,
          value: cost.name,
        }))
        .filter(
          (cost) =>
            cost.label
              .toLowerCase()
              .indexOf(billableCostCenterSearchValue.toLowerCase()) > -1,
        ) || [],
    [billableCenterCosts, billableCostCenterSearchValue],
  );

  const [reasonForRequestSearchValue, setReasonForRequestSearchValue] =
    useState('');
  const reasonForRequestOptions = useMemo(
    () =>
      reasonForRequests
        ?.map((reason) => ({
          label: reason.label,
          value: reason.name,
        }))
        .filter(
          (reason) =>
            reason.label
              .toLowerCase()
              .indexOf(reasonForRequestSearchValue.toLowerCase()) > -1,
        ) || [],
    [reasonForRequestSearchValue, reasonForRequests],
  );

  const validationTimeout = useRef<NodeJS.Timeout | undefined>();
  const customValidate = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (fieldName: keyof FormValues, onChange: (...event: any[]) => void) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (value: any) => {
        onChange(value);

        clearTimeout(validationTimeout.current);

        validationTimeout.current = setTimeout(() => {
          trigger(fieldName);
        }, 300);
      },
    [trigger],
  );

  return (
    <BaseModal
      size="medium"
      showCloseButton
      id="item-details-modal"
      open={open}
      onClose={handleClose}
      title={t('Modals.ItemDetails.title', {
        type: typeFromProps?.toUpperCase(),
      })}
      containerClassName="p-4"
    >
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <Controller
          control={control}
          name="StyleID"
          render={({
            field: { onChange, onBlur, value, name },
            fieldState: { error },
          }) => (
            <FormField
              disabled={shouldDisableBaseDetailsFields}
              label={t(`Modals.ItemDetails.${name}`, {
                context: typeFromProps,
              })}
              required
            >
              <Input
                name={name}
                value={value}
                placeholder={t(`Modals.ItemDetails.${name}Placeholder`)}
                onChange={customValidate(name, onChange)}
                onBlur={onBlur}
                error={error?.message}
                disabled={shouldDisableBaseDetailsFields}
              />
            </FormField>
          )}
        />

        {isFullForm && (
          <Controller
            control={control}
            name="Category"
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => {
              const isDisabled = shouldDisableBaseDetailsFields;
              return (
                <FormField
                  disabled={isDisabled}
                  label={t(`Modals.ItemDetails.${name}`)}
                  required
                >
                  <Select
                    variant={isDisabled ? 'default' : 'search'}
                    value={value!}
                    placeholder={t('General.search')}
                    onChange={onChange}
                    onSearchChange={setCategorySearchValue}
                    options={categoryOptions}
                    disabled={isDisabled}
                    error={error?.message}
                  />
                </FormField>
              );
            }}
          />
        )}

        {isFullForm && (
          <Controller
            control={control}
            name="NameOfProduct"
            render={({
              field: { onChange, onBlur, value, name },
              fieldState: { error },
            }) => (
              <FormField
                disabled={shouldDisableBaseDetailsFields}
                label={t(`Modals.ItemDetails.${name}`)}
                required
              >
                <Input
                  name={name}
                  value={value!}
                  placeholder={t(`Modals.ItemDetails.${name}Placeholder`)}
                  onChange={customValidate(name, onChange)}
                  onBlur={onBlur}
                  error={error?.message}
                  disabled={shouldDisableBaseDetailsFields}
                />
              </FormField>
            )}
          />
        )}

        {isFullForm && (
          <Controller
            control={control}
            name="Size"
            render={({
              field: { onChange, onBlur, value, name },
              fieldState: { error },
            }) => (
              <FormField
                disabled={shouldDisableBaseDetailsFields}
                label={t(`Modals.ItemDetails.${name}`)}
                required={typeFromProps !== 'other'}
              >
                <Input
                  name={name}
                  value={value!}
                  placeholder={t(`Modals.ItemDetails.${name}Placeholder`)}
                  onChange={customValidate(name, onChange)}
                  onBlur={onBlur}
                  error={error?.message}
                  disabled={shouldDisableBaseDetailsFields}
                />
              </FormField>
            )}
          />
        )}

        <Controller
          control={control}
          name="Billable"
          render={({ field: { onChange, name, value } }) => (
            <FormField label={t(`Modals.ItemDetails.${name}`)} required>
              <div className="flex items-center gap-2">
                <Radio
                  name={name}
                  value="yes"
                  onChange={onChange}
                  checked={value === 'yes'}
                />
                <label className="ui-radio-label">{t('General.yes')}</label>
                <Radio
                  name={name}
                  value="no"
                  onChange={onChange}
                  checked={value === 'no'}
                />
                <label className="ui-radio-label">{t('General.no')}</label>
              </div>
            </FormField>
          )}
        />

        <Controller
          control={control}
          name="BillableCenterCost"
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }) => (
            <FormField
              label={t(`Modals.ItemDetails.${name}`)}
              required={watchedBillable === 'yes'}
            >
              <Select
                variant="search"
                disabled={watchedBillable === 'no'}
                value={value!}
                placeholder={t('General.search')}
                onChange={onChange}
                onSearchChange={setBillableCostCenterSearchValue}
                options={billableCostCenterOptions}
                error={error?.message}
              />
            </FormField>
          )}
        />

        <Controller
          control={control}
          name="ReasonForRequest"
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }) => (
            <FormField label={t(`Modals.ItemDetails.${name}`)} required>
              <Select
                variant="search"
                value={value}
                placeholder={t('General.search')}
                onChange={onChange}
                onSearchChange={setReasonForRequestSearchValue}
                options={reasonForRequestOptions}
                error={error?.message}
              />
            </FormField>
          )}
        />

        <Controller
          control={control}
          name="Comment"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormField
              label={t('Modals.ItemDetails.Comment')}
              direction="column"
              required={watchedReasonForRequest === 'other'}
            >
              <Textarea
                name={name}
                placeholder={t(`Modals.ItemDetails.${name}Placeholder`)}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                maxLength={250}
                showCounter
              />
            </FormField>
          )}
        />

        <Button
          label={t('General.ok')}
          type="primary"
          disabled={!formState.isValid}
        />
      </form>
    </BaseModal>
  );
};

export default React.memo(ShipmentItemDetailsModal);
