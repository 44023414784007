import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { usePendingShipmentContext } from '@contexts/pendingShipment';
import { ItemSelect } from '@molecules/ItemSelect';

import { PendingShipmentBaseModal } from '../PendingShipmentBaseModal';
import { PendingShipmentSelectProductsModalProps } from './definitions';

const PendingShipmentSelectProductsModal = ({
  open,
  onClose,
}: PendingShipmentSelectProductsModalProps) => {
  const { t } = useTranslation();
  const { showPendingShipmentSelectPackagingModal } = useModalsContext();
  const {
    selectedShipment,
    products: ctxProducts,
    setProduct: setCtxProduct,
    removeProduct: removeCtxProduct,
  } = usePendingShipmentContext();

  const hasSelectedAtLeastOneProduct = ctxProducts.length > 0;

  const handleChekboxChange = useCallback(
    (id: number) => (_: unknown, checked: boolean) => {
      if (checked) {
        const matchingProduct = selectedShipment?.shipmentProducts.find(
          (product) => product.productId === id,
        );
        if (matchingProduct) {
          setCtxProduct(matchingProduct);
        }
      } else {
        removeCtxProduct(id);
      }
    },
    [removeCtxProduct, selectedShipment?.shipmentProducts, setCtxProduct],
  );

  return (
    <PendingShipmentBaseModal
      id="pendingShipmentSelectProductsModal"
      title={t('Modals.PendingShipmentSelectProducts.title')}
      open={open}
      onClose={onClose}
      isNextDisabled={!hasSelectedAtLeastOneProduct}
      onNext={showPendingShipmentSelectPackagingModal}
    >
      <div className="flex flex-col gap-3 mt-6">
        {selectedShipment?.shipmentProducts.map((item) => {
          return (
            <ItemSelect
              key={item.productId}
              checkbox={{
                name: item.id.toString(),
                value: item.product.name,
                checked: !!ctxProducts.find(
                  (product) => product.productId === item.productId,
                ),
                onChange: handleChekboxChange(item.productId),
                size: 'xs',
              }}
            >
              <Typography
                as="span"
                size="sm"
                sizeMd="md"
                color="text-Primary-02"
              >
                {item.product.styleIdType.toUpperCase()}: &nbsp;
                <Typography
                  as="span"
                  size="sm"
                  sizeMd="md"
                  className="underline"
                >
                  {item.product.styleId}
                </Typography>
              </Typography>
            </ItemSelect>
          );
        })}
      </div>
    </PendingShipmentBaseModal>
  );
};
export default React.memo(PendingShipmentSelectProductsModal);
