import React, { useEffect, useState } from 'react';

import useWindow from '@hooks/useWindow';
import { BaseModal } from '@organisms/Modals/BaseModal';
import { ShipmentImagePreviewModalProps } from '@organisms/Modals/Shipment/ShipmentRemarksModal/ShipmentImagePreviewModal/definitions';

const ShipmentImagePreviewModal: React.FC<ShipmentImagePreviewModalProps> = ({
  image,
  open,
  onClose,
}) => {
  const { isMobile } = useWindow();

  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (image) {
      const url = URL.createObjectURL(image);
      setImageUrl(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [image]);

  return (
    <BaseModal
      id={'shipment-image-preview'}
      open={open}
      size={isMobile ? 'small' : 'medium'}
      contentClassName="h-auto sm:h-[500px]"
      showCloseButton
      onClose={onClose}
    >
      {imageUrl && <img src={imageUrl} alt={image?.name} />}
    </BaseModal>
  );
};

export default React.memo(ShipmentImagePreviewModal);
