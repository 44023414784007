import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthApi } from '@api/authApi';
import { Button } from '@atoms/Button';
import { Typography } from '@atoms/Typography';
import { useLogger } from '@hooks/useLogger';
import { passwordRegex } from '@hooks/useRegex';
import useToast from '@hooks/useToast';
import { Input } from '@molecules/Form/Input';

import BaseModal from '../BaseModal/BaseModal';
import { ChangePasswordModalProps } from './definitions';

const ChangePasswordModal = ({ open, onClose }: ChangePasswordModalProps) => {
  const { t } = useTranslation();
  const { logError } = useLogger();

  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  const { getPasswordPolicy, changePassword } = useAuthApi();
  const { data } = getPasswordPolicy();

  const { showSuccessToast, showWarningToast } = useToast();

  const { length, upperCase, specialChars, digits } = data || {
    length: 8,
    upperCase: 1,
    specialChars: 1,
    digits: 1,
  };

  const resetPasswords = useCallback(() => {
    setConfirmPassword('');
    setNewPassword('');
    setOldPassword('');
  }, []);

  const resetErrors = useCallback(() => {
    setConfirmPasswordError('');
    setNewPasswordError('');
    setOldPasswordError('');
  }, []);

  const resetComponent = useCallback(() => {
    resetPasswords();
    resetErrors();
  }, [resetErrors, resetPasswords]);

  const formIsValid = useMemo(() => {
    return (
      passwordRegex.test(oldPassword) &&
      passwordRegex.test(newPassword) &&
      newPassword === confirmPassword
    );
  }, [confirmPassword, newPassword, oldPassword]);

  const oldPasswordChangeHandler = useCallback(
    (value: string) => {
      setOldPassword(value);
      setOldPasswordError('');

      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      setTypingTimeout(
        setTimeout(() => {
          if (!passwordRegex.test(value)) {
            setOldPasswordError(t('General.invalidPsw'));
          } else {
            setOldPasswordError('');
          }
        }, 1000),
      );
    },
    [t, typingTimeout],
  );

  const newPasswordChangeHandler = useCallback(
    (value: string) => {
      setNewPassword(value);
      setNewPasswordError('');

      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      setTypingTimeout(
        setTimeout(() => {
          if (!passwordRegex.test(value)) {
            setNewPasswordError(t('General.invalidPsw'));
          } else {
            setNewPasswordError('');
          }
        }, 1000),
      );
    },
    [t, typingTimeout],
  );

  const confirmPasswordChangeHandler = useCallback(
    (value: string) => {
      setConfirmPassword(value);
      setConfirmPasswordError('');

      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      setTypingTimeout(
        setTimeout(() => {
          if (newPassword !== value) {
            setConfirmPasswordError(t('ChangePassword.passwordsDoNotMatch'));
          } else {
            setConfirmPasswordError('');
          }
        }, 1000),
      );
    },
    [newPassword, t, typingTimeout],
  );

  const submitHandler = () => {
    changePassword
      .mutateAsync({ oldPassword: oldPassword, newPassword: newPassword })
      .then(() => {
        resetComponent();
        onClose?.();
        showSuccessToast(t('ChangePassword.toastSuccessMessage'));
      })
      .catch((error) => {
        resetComponent();
        showWarningToast(t('ChangePassword.toastWarningMessage'));
        logError(error);
      });
  };

  const onCloseHandler = useCallback(() => {
    resetComponent();
    onClose?.();
  }, [onClose, resetComponent]);

  return (
    <BaseModal
      id="changePasswordModal"
      title={t('ChangePassword.title')}
      open={open}
      onClose={onCloseHandler}
    >
      <div className="bg-Primary-05">
        <Typography size="sm" className="mt-2 max-w-[310px]">
          {t('ChangePassword.description', {
            length,
            upperCase,
            specialChars,
            digits,
          })}
        </Typography>

        <div className="mt-8 flex flex-col gap-8">
          <Input
            title={t('ChangePassword.oldPassword')}
            placeholder={t('ChangePassword.oldPasswordPlaceholder')}
            placeholderUppercase
            name={t('ChangePassword.oldPassword')}
            type="password"
            value={oldPassword}
            onChange={oldPasswordChangeHandler}
            error={oldPasswordError}
          />
          <Input
            title={t('ChangePassword.newPassword')}
            placeholder={t('ChangePassword.newPasswordPlaceholder')}
            placeholderUppercase
            name={t('ChangePassword.newPassword')}
            type="password"
            value={newPassword}
            onChange={newPasswordChangeHandler}
            error={newPasswordError}
          />
          <Input
            title={t('ChangePassword.confirmPassword')}
            placeholder={t('ChangePassword.confirmPasswordPlaceholder')}
            placeholderUppercase
            name={t('ChangePassword.confirmPassword')}
            type="password"
            value={confirmPassword}
            onChange={confirmPasswordChangeHandler}
            error={confirmPasswordError}
          />
        </div>
        <Button
          label={t('General.ok')}
          className="w-full mt-12"
          type="primary"
          onClick={submitHandler}
          disabled={!formIsValid}
        />

        <Button
          label={t('ChangePassword.cancel')}
          className="w-full mt-2"
          type="secondary"
          onClick={onCloseHandler}
        ></Button>
      </div>
    </BaseModal>
  );
};
export default ChangePasswordModal;
