import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';

import { RemarksSectionProps } from './definitions';

const RemarksSection = ({
  showAttachments,
  onEdit,
  remarks,
}: RemarksSectionProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-start justify-between mb-5">
        <div className="flex flex-col gap-1">
          <Typography size="sm" sizeMd="md" color="text-Primary-02">
            {t('Modals.GenericShipmentResume.remarks')}:
          </Typography>
          <Typography size="sm" sizeMd="md">
            {remarks?.comment}
          </Typography>
        </div>
        <IconSVG icon="edit" size={24} onClick={onEdit} />
      </div>

      {showAttachments && (
        <div className="flex gap-2">
          <IconSVG icon="attachment" size={24} />
          <Typography size="sm" sizeMd="md" color="text-Primary-02">
            {remarks?.image?.name}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default React.memo(RemarksSection);
