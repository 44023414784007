import { useEffect, useRef } from 'react';

import { ClickOutsideCallback, UseClickOutsideProps } from './definitions';

function useClickOutside<ElementType extends HTMLElement>({
  clickOutsideFn,
}: UseClickOutsideProps) {
  const wrapperRef = useRef<ElementType | null>(null);
  const callbackRef = useRef<ClickOutsideCallback | null>(null);

  const setWrapperRef = (element: ElementType) => {
    wrapperRef.current = element;
  };

  useEffect(() => {
    if (typeof clickOutsideFn === 'function') {
      callbackRef.current = clickOutsideFn;
    }
  }, [clickOutsideFn]);

  useEffect(() => {
    const listenerCallback = (event: MouseEvent) => {
      if (
        callbackRef.current &&
        !wrapperRef.current?.contains(event.target as Node)
      ) {
        callbackRef.current(event);
      }
    };

    window.addEventListener('mousedown', listenerCallback);

    return () => {
      // It's usually a good idea to remove, here, our event listeners.
      window.removeEventListener('mousedown', listenerCallback);
    };
  }, []);

  return { setWrapperRef };
}

export default useClickOutside;
