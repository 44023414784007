import { useCallback, useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';

import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';

import { BaseModalProps } from './definitions';

const BaseModal = ({
  id: modalId,
  open,
  onClose,
  onBottomReached,
  children,
  size = 'small',
  title,
  header: headerFromProps,
  footer,
  showCloseButton = false,
  containerClassName = '',
  contentClassName = '',
}: BaseModalProps) => {
  const DefaultHeader = useMemo(() => {
    return (
      <div className="flex flex-col gap-3 mb-2">
        {showCloseButton && (
          <button onClick={onClose} className="self-end">
            <IconSVG icon="close" size={18} />
          </button>
        )}
        {title && (
          <Typography isUppercase isBold size="sm" sizeMd="md">
            {title}
          </Typography>
        )}
      </div>
    );
  }, [onClose, showCloseButton, title]);

  const contentRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;

      if (scrollTop + clientHeight >= scrollHeight) {
        onBottomReached?.();
      }
    }
  }, [onBottomReached]);

  useEffect(() => {
    contentRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      contentRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, contentRef]);

  return open
    ? createPortal(
        <dialog
          open={open}
          onClose={onClose}
          id={modalId}
          className={`ui-modal bg-Modal-gray ${size === 'large' ? 'ysl-modal-large' : size === 'medium' ? ' ysl-modal-medium' : size === 'small' ? 'ysl-modal-small' : ''} ${containerClassName}`}
        >
          <div
            className={`ui-modal-box flex flex-col w-full sm:w-[92%] ${size === 'large' ? 'sm:max-w-[880px] h-full md:min-h-[750px] sm:h-auto max-h-[100vh]' : size === 'medium' ? ' max-w-[500px] h-full sm:h-auto max-h-[100vh]' : ' max-w-[350px]'} p-4 md:p-5 rounded-none`}
          >
            <div id={`${modalId}-header`} className="sticky top-0">
              {headerFromProps ? (
                <div className="flex justify-between items-center py-3 bg-Primary-05">
                  {headerFromProps.leftElement}
                  {headerFromProps.centerElement}
                  {headerFromProps.rightElement}
                </div>
              ) : (
                DefaultHeader
              )}
            </div>
            <div
              ref={contentRef}
              id={`${modalId}-content`}
              className={`overflow-scroll overflow-x-auto flex-1 sm:flex-auto ${contentClassName}`.trim()}
            >
              {children}
            </div>
            {footer && <div id={`${modalId}-footer`}>{footer}</div>}
          </div>
        </dialog>,
        document.body,
      )
    : null;
};

export default BaseModal;
