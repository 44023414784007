import { GenericShipmentBaseModal } from '@organisms/Modals/GenericShipmentBaseModal';

import { ShipmentBaseModalProps } from './definitions';
import { useShipmentBaseModal } from './hooks';

const ShipmentBaseModal = ({
  id,
  open,
  onBottomReached,
  onClose,
  onNext,
  title,
  isNextDisabled,
  nextLabel,
  children,
}: ShipmentBaseModalProps) => {
  const { onDismiss } = useShipmentBaseModal({ onClose });

  return (
    <GenericShipmentBaseModal
      id={id}
      open={open}
      title={title}
      onClose={onDismiss}
      onBack={onClose}
      onBottomReached={onBottomReached}
      onNext={onNext}
      isNextDisabled={isNextDisabled}
      nextLabel={nextLabel}
    >
      {children}
    </GenericShipmentBaseModal>
  );
};
export default ShipmentBaseModal;
