import { IconNames } from '@atoms/IconSVG/definitions';
import { AuthenticatedRoutes } from '@navigation/routes';

export type NavigationItemProps = {
  label: string;
  icon: IconNames;
  isActive?: boolean;
  goToRoute: AuthenticatedRoutes;
  isVertical?: boolean;
};

export type NavigationItemEnumProps =
  | 'home'
  | 'pending'
  | 'history'
  | 'inCourse'
  | 'statistics'
  | 'inStock'
  | 'items'
  | 'users'
  | 'organizations';

export type NavigationProps = {
  isMobileNavigation?: boolean;
};

export const NavigationItemEnum: { [key: string]: string } = {
  HOME_PAGE: 'home',
  PENDING_PAGE: 'pending',
  HISTORY_PAGE: 'history',
  IN_COURSE_PAGE: 'inCourse',
  STATISTICS_PAGE: 'statistics',
  IN_STOCK_PAGE: 'inStock',
  ITEMS_PAGE: 'items',
  USERS_PAGE: 'users',
  ORGANIZATIONS_PAGE: 'organizations',
};
