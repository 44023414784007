import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'use-debounce';

import { useProductApi } from '@api/productApi';
import { Spinner } from '@atoms/Spinner';
import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { useShipmentContext } from '@contexts/shipment';
import { Product } from '@definitions/Products';
import { QueryKeys } from '@definitions/QueryKeys';
import { FloatingButton } from '@molecules/FloatingButton';
import { SearchInput } from '@molecules/Form/SearchInput';
import { ItemSelect } from '@molecules/ItemSelect';
import { ProductCategoryAccordion } from '@molecules/ProductCategoryAccordion';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { isNullOrUndefined } from '@utils/typeCheck';

import { ProductsTabProps } from './definitions';
import { useParsedProducts } from './hooks';

const SEARCH_MINIMUM_CHARACTERS = 3;

const ProductsTab = ({
  className: classNameFromProps = '',
}: ProductsTabProps) => {
  const { t } = useTranslation();

  const {
    getProductsStockCount,
    getProductCategories,
    getProductsStock,
    getProductsStockBySearch,
  } = useProductApi();

  const { products: ctxProducts, removeProduct: ctxRemoveProduct } =
    useShipmentContext();
  const { showShipmentItemDetailsModal, showShipmentAddItemCodeModal } =
    useModalsContext();

  const [searchValue, setSearchValue] = useState('');
  const [apiSearchValue] = useDebounce(searchValue, 300);
  const hasSearchMinimumCharaters =
    apiSearchValue.length >= SEARCH_MINIMUM_CHARACTERS;

  const parsedApiSearchValue = useMemo(() => {
    return hasSearchMinimumCharaters ? apiSearchValue : '';
  }, [apiSearchValue, hasSearchMinimumCharaters]);

  // Categories
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>();

  const { data: productsStockCount, isFetching: isFetchingProductsStockCount } =
    useQuery({
      queryKey: [QueryKeys.PRODUCTS_STOCK_COUNT],
      queryFn: getProductsStockCount,
    });

  const { data: productCategories } = useQuery({
    queryKey: [QueryKeys.PRODUCT_CATEGORIES],
    queryFn: () => getProductCategories(),
  });

  // Products
  const { data: products } = useInfiniteQuery({
    // enable when search value is empty
    enabled:
      !hasSearchMinimumCharaters && !isNullOrUndefined(selectedCategoryId),
    queryKey: [
      QueryKeys.SHIPMENT_PRODUCTS,
      selectedCategoryId,
      hasSearchMinimumCharaters,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getProductsStock({
        page: pageParam,
        perPage: 20,
        productCategoryId: selectedCategoryId!,
      }),

    initialPageParam: 1,
    getNextPageParam: (thisPage) =>
      thisPage.meta.currentPage + 1 > thisPage.meta.lastPage
        ? null
        : thisPage.meta.currentPage + 1,
  });

  const { data: productsBySearch } = useQuery({
    // enable when search value is not empty
    enabled: hasSearchMinimumCharaters,
    queryKey: [QueryKeys.SHIPMENT_PRODUCTS_SEARCH, parsedApiSearchValue],
    queryFn: () =>
      getProductsStockBySearch({ textFilter: parsedApiSearchValue }),
  });

  const parsedProducts = useParsedProducts({
    products,
    productsBySearch,
  });

  const handleAccordionChange = useCallback(
    (categoryId: number) => (isOpen: boolean) => {
      setSelectedCategoryId(isOpen ? categoryId : undefined);
    },
    [],
  );

  const handleProductSelect = useCallback(
    (product: Product) => (_: unknown, checked: boolean) => {
      if (checked) {
        showShipmentItemDetailsModal({
          product: {
            ...product,
            productId: product.id,
          },
          type: product.styleIdType,
        });
      } else {
        ctxRemoveProduct(product.id);
      }
    },
    [ctxRemoveProduct, showShipmentItemDetailsModal],
  );

  return (
    <div className={`${classNameFromProps} pr-3`}>
      <div className="mb-5 sticky top-[44px] bg-Primary-05 z-[2]">
        <SearchInput
          name="search-products"
          value={searchValue}
          placeholder={t('General.search')}
          onChange={setSearchValue}
          onClear={() => setSearchValue('')}
        />
      </div>

      {productsStockCount?.map(
        ({
          productCategoryId,
          productCategoryName,
          productCategoryLabel,
          quantity,
        }) => {
          const isAccordionOpenBySearch =
            hasSearchMinimumCharaters &&
            parsedProducts.some(
              (group) => group.categoryId === productCategoryId,
            );

          const iconPathName = productCategories?.find(
            (cat) => cat.id === productCategoryId,
          )?.image;

          return (
            <ProductCategoryAccordion
              open={isAccordionOpenBySearch}
              onChange={handleAccordionChange(productCategoryId)}
              name={productCategoryName}
              key={productCategoryId}
              icon={{
                pathName: iconPathName,
              }}
              label={productCategoryLabel}
              itemsQty={quantity}
            >
              {parsedProducts
                .filter((group) => group.categoryId === productCategoryId)
                .map((group) => {
                  return group.items.map((item) => {
                    const isProductStoredInContext = ctxProducts.some(
                      (p) => p.productId === item.id,
                    );
                    return (
                      <ItemSelect
                        key={item.id}
                        checkbox={{
                          size: 'xs',
                          name: item.styleId,
                          value: item.id,
                          checked: isProductStoredInContext,
                          onChange: handleProductSelect(item),
                        }}
                      >
                        <Typography
                          size="sm"
                          className="flex items-center gap-2"
                        >
                          <span className="text-Primary-03 uppercase">
                            {item.styleIdType}:
                          </span>
                          <span className="text-xs underline">
                            {item.styleId}
                          </span>
                        </Typography>
                      </ItemSelect>
                    );
                  });
                })}
            </ProductCategoryAccordion>
          );
        },
      )}

      {isFetchingProductsStockCount && <Spinner className="mt-4" />}

      <FloatingButton
        icon="plus"
        onClick={() =>
          showShipmentAddItemCodeModal({
            product: undefined,
          })
        }
        className="bottom-[100px] right-[60px]"
      />
    </div>
  );
};

export default ProductsTab;
