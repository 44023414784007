import { useEffect, useState } from 'react';

import { Product } from '@definitions/Products';

import { UseParsedProductsHookProps } from './definitions';

export const useParsedProducts = ({
  products,
  productsBySearch,
}: UseParsedProductsHookProps) => {
  /**
   * Parsed products to be rendered
   * They can be products from the getProducts api or from the getProductsBySearch api
   */
  const [parsedProducts, setParsedProducts] = useState<
    Array<{
      categoryId: number;
      items: Product[];
    }>
  >([]);

  /**
   * getProducts callback
   * Map products group internally because api handles only
   * one category at a time
   */
  useEffect(() => {
    if (products && products.pages.length > 0) {
      const allProductsPages = products.pages.flatMap((page) => page.data);
      const catId = allProductsPages[0].productCategoryId;

      setParsedProducts((prevParsedProducts) => {
        const newParsedProducts = [...prevParsedProducts];
        const categoryPosition = newParsedProducts.findIndex(
          (p) => p.categoryId === catId,
        );
        const foundCategory = categoryPosition > -1;

        // Replace category if already exists
        if (foundCategory) {
          return newParsedProducts.toSpliced(categoryPosition, 1, {
            categoryId: catId,
            items: allProductsPages,
          });
        }

        // Add new category
        return [
          ...newParsedProducts,
          {
            categoryId: catId,
            items: allProductsPages,
          },
        ];
      });
    }
  }, [products]);

  /**
   * getProducts callback
   * Map products group internally because api handles only
   * one category at a time
   */
  useEffect(() => {
    if (productsBySearch && productsBySearch.length > 0) {
      const newParsedProducts: typeof parsedProducts = [];

      productsBySearch.forEach((_product) => {
        const catId = _product.productCategoryId;

        const categoryPosition = newParsedProducts.findIndex(
          (p) => p.categoryId === catId,
        );
        const foundCategory = categoryPosition > -1;

        // Add items to category if already exists
        if (foundCategory) {
          newParsedProducts[categoryPosition].items = [
            ...newParsedProducts[categoryPosition].items,
            _product,
          ];
        } else {
          // Add new category
          newParsedProducts.push({
            categoryId: catId,
            items: [_product],
          });
        }
      }); // end forEach

      setParsedProducts(newParsedProducts);
    } else {
      setParsedProducts([]);
    }
  }, [productsBySearch]);

  // Output
  return parsedProducts;
};
