import React from 'react';

import { CheckboxProps } from '@atoms/Checkbox/definitions';

const Checkbox = ({
  name,
  onChange,
  checked,
  value,
  size = 'sm',
}: CheckboxProps) => {
  return (
    <input
      type="checkbox"
      name={name}
      id={name}
      value={value}
      onChange={(e) => onChange(e.target.name, e.target.checked)}
      checked={checked}
      className={`ui-checkbox rounded-none ${size === 'xs' ? 'ui-checkbox-xs' : 'ui-checkbox-sm'}`}
    />
  );
};
export default React.memo(Checkbox);
