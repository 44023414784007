interface BuildQueryParams {
  [paramName: string]: string | number | boolean | string[] | undefined;
}

/**
 * Function for building query params string
 */
export const buildQuery = <T = BuildQueryParams>(params: T): string => {
  const stringedParams = Object.entries(params || {}).reduce(
    (acc, [key, value]) => {
      if (value === undefined || value === null) {
        return acc;
      }
      return {
        ...acc,
        [key]: value.toString(),
      };
    },
    {},
  );

  const query = new URLSearchParams(stringedParams);
  const queryToString = query.toString();

  return queryToString ? `?${queryToString}` : '';
};
