import { PendingShipmentRequestApprovalModal } from './PendingShipmentRequestApprovalModal';
import { PendingShipmentResumeModal } from './PendingShipmentResumeModal';
import { PendingShipmentSelectPackagingModal } from './PendingShipmentSelectPackagingModal';
import { PendingShipmentSelectProductsModal } from './PendingShipmentSelectProductsModal';

const PendingShipmentModals = {
  RequestApproval: PendingShipmentRequestApprovalModal,
  SelectProductsModal: PendingShipmentSelectProductsModal,
  SelectPackagingModal: PendingShipmentSelectPackagingModal,
  ResumeModal: PendingShipmentResumeModal,
};

export { PendingShipmentModals };
