import { useCallback } from 'react';

import * as Sentry from '@sentry/react';

export const useLogger = () => {
  const logError = useCallback((error: unknown) => {
    Sentry.captureException(error);
    if (import.meta.env.DEV) {
      console.error(error);
    }
  }, []);

  return {
    logError,
  };
};
