import { useCallback } from 'react';

import { useSupportsApi } from '@api/supportsApi';
import { Spinner } from '@atoms/Spinner';
import { useShipmentContext } from '@contexts/shipment';
import { QueryKeys } from '@definitions/QueryKeys';
import { ItemCounter } from '@molecules/ItemCounter';
import { useQuery } from '@tanstack/react-query';

import { SupportsTabProps } from './definitions';

const SupportsTab = ({ className: classNameFromProps }: SupportsTabProps) => {
  const { getSupports } = useSupportsApi();
  const { setSupport: ctxSetSupport, supports: ctxSupports } =
    useShipmentContext();

  const { data: supports, isFetching: isFetchingSupports } = useQuery({
    queryKey: [QueryKeys.SHIPMENT_SUPPORTS],
    queryFn: () => getSupports(),
  });

  const handleSupportCountChange = useCallback(
    (id: number) => (count: number) => {
      ctxSetSupport({
        id: id,
        quantity: count,
      });
    },
    [ctxSetSupport],
  );

  return (
    <div className={`flex flex-col gap-5 ${classNameFromProps || ''}`.trim()}>
      {supports?.map(({ image, label, id }) => {
        return (
          <ItemCounter
            key={id}
            icon={{
              pathName: image,
            }}
            label={label}
            onChange={handleSupportCountChange(id)}
            startValue={
              ctxSupports.find((support) => support.id === id)?.quantity || 0
            }
          />
        );
      })}
      {isFetchingSupports && <Spinner className="mt-4" />}
    </div>
  );
};

export default SupportsTab;
