import { Typography } from '@atoms/Typography';
import { useUserContext } from '@contexts/user';

const UserDetails = () => {
  const { user } = useUserContext();

  return (
    <div className="flex gap-4">
      <div>
        <div className="flex items-center justify-center rounded-full w-11 h-11">
          {/* TODO: Add user image from BE */}
          <img
            src={'https://via.placeholder.com/150'}
            alt="User image"
            className="rounded-full"
          />
        </div>
      </div>

      <div>
        <Typography size="md" isBold>
          {user?.firstName} {user?.lastName}
        </Typography>
        <Typography size="md" color="text-Primary-03">
          {user?.organization?.name}
        </Typography>
      </div>
    </div>
  );
};
export default UserDetails;
