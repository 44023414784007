import { useEffect, useMemo, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import Chart, { ChartDataset } from 'chart.js/auto';

import { Card } from '@atoms/Card';
import { Typography } from '@atoms/Typography';
import Pattern from '@theme/assets/barchart-pattern.png';
import colors from '@theme/colors';

import { Legend } from './Legend';
import { TransferAndRequestProps } from './definitions';

export const TransferAndRequest = ({ chartData }: TransferAndRequestProps) => {
  const { t } = useTranslation();

  const chartRef = useRef<Chart<'bar'>>(null);

  const [patternImage, setPatternImage] = useState<CanvasPattern>();

  useEffect(() => {
    const chartInstance = chartRef.current;

    if (!chartInstance) {
      return;
    }

    const ctx = chartInstance.canvas.getContext('2d');
    if (ctx) {
      const image = new Image();
      image.src = Pattern;
      image.onload = () => {
        const createdPattern = ctx.createPattern(image, 'repeat');
        if (createdPattern) {
          setPatternImage(createdPattern);
        }
      };
    }
  }, []);

  const labels = useMemo(() => {
    return chartData.labels;
  }, [chartData.labels]);

  const datasets = useMemo<ChartDataset<'bar'>[]>(() => {
    let result: ChartDataset<'bar'>[] = [];

    const { returnClosed, returnInCourse, requestClosed, requestInCourse } =
      chartData;

    if (returnClosed && returnInCourse) {
      result = [
        ...result,
        {
          data: returnClosed,
          backgroundColor: '#60605C',
          hoverBackgroundColor: '#60605C',
          stack: 'transfer',
        },
        {
          data: returnInCourse,
          backgroundColor: patternImage,
          hoverBackgroundColor: patternImage,
          stack: 'transfer',
        },
      ];
    }

    if (requestClosed && requestInCourse) {
      result = [
        ...result,
        {
          data: requestClosed,
          backgroundColor: '#C7C5C5',
          hoverBackgroundColor: '#C7C5C5',
          stack: 'request',
        },
        {
          data: requestInCourse,
          backgroundColor: patternImage,
          hoverBackgroundColor: patternImage,
          stack: 'request',
        },
      ];
    }

    return result;
  }, [chartData, patternImage]);

  return (
    <Card hasPadding={false}>
      <div className="w-full p-4">
        <div className="pb-6">
          <Typography size="lg" isUppercase isBold>
            {t('Statistics.timechart.transferAndRequest.title')}
          </Typography>
        </div>
        <div className="h-[400px]">
          <Bar
            ref={chartRef}
            data={{
              labels,
              datasets,
            }}
            options={{
              maintainAspectRatio: false,
              datasets: {
                bar: {
                  categoryPercentage: 0.5,
                  barPercentage: 0.8,
                },
              },
              font: {
                family: "'Helvetica', sans-serif",
              },
              scales: {
                y: {
                  beginAtZero: true,
                  border: {
                    display: false,
                  },
                  grid: {
                    tickColor: 'transparent',
                    tickLength: 15,
                    color: '#F2F4F6',
                  },
                  ticks: {
                    precision: 0,
                    color: colors['Primary-02'],
                    font: {
                      size: 12,
                      style: 'normal',
                      weight: 'normal',
                    },
                  },
                },
                x: {
                  border: {
                    display: false,
                  },
                  grid: {
                    display: false,
                    tickLength: 15,
                  },
                  ticks: {
                    color: colors['Primary-02'],
                    font: {
                      size: 12,
                      style: 'normal',
                      weight: 'normal',
                    },
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  position: 'nearest',
                  displayColors: false,
                  backgroundColor: colors['Primary-02'],
                  xAlign: 'left',
                  yAlign: 'bottom',
                  caretSize: 0,
                  caretPadding: 12,
                  padding: {
                    top: 8,
                    bottom: 8,
                    left: 12,
                    right: 12,
                  },
                  titleColor: colors['Primary-03'],
                  titleFont: {
                    size: 12,
                    style: 'normal',
                    weight: 'normal',
                  },
                  bodyColor: colors['Primary-05'],
                  bodyFont: {
                    size: 20,
                    style: 'normal',
                    weight: 'bold',
                  },
                },
              },
            }}
          />
        </div>
        <div className="pt-6">
          <Legend
            items={[
              {
                label: t('Statistics.timechart.transferAndRequest.transfer'),
                colorClassName: 'bg-[#60605C]',
              },
              {
                label: t('Statistics.timechart.transferAndRequest.request'),
                colorClassName: 'bg-[#C7C5C5]',
              },
              {
                label: t('Statistics.timechart.transferAndRequest.inCourse'),
                colorClassName: 'bg-center bg-[length:200%_200%]',
                imageUrl: Pattern,
              },
            ]}
          />
        </div>
      </div>
    </Card>
  );
};
