import GenericReceiveBaseModal from '@organisms/Modals/GenericReceiveBaseModal/GenericReceiveBaseModal';
import { ReceiveBaseModalProps } from '@organisms/Modals/ReceiveFlow/ReceiveBaseModal/definitions';
import { useReceiveBaseModal } from '@organisms/Modals/ReceiveFlow/ReceiveBaseModal/hooks';

const ReceiveBaseModal = ({
  children,
  onClose,
  ...props
}: ReceiveBaseModalProps) => {
  const { onDismiss } = useReceiveBaseModal({ onClose });

  return (
    <GenericReceiveBaseModal {...props} onClose={onDismiss} onBack={onClose}>
      {children}
    </GenericReceiveBaseModal>
  );
};
export default ReceiveBaseModal;
