import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useShipmentApi } from '@api/ShipmentApi';
import { Button } from '@atoms/Button';
import { Spinner } from '@atoms/Spinner';
import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { useReceiveContext } from '@contexts/receive';
import { useShipmentContext } from '@contexts/shipment';
import { useUserContext } from '@contexts/user';
import { QueryKeys } from '@definitions/QueryKeys';
import ActivityCard from '@molecules/ActivityCard/ActivityCard';
import { useQuery } from '@tanstack/react-query';
import { BaseLayout } from '@templates/BaseLayout';

const Homepage = () => {
  // Using translation hook
  const { t } = useTranslation();
  const { showShipmentSelectAddressModal, showReceiveSelectCodeModal } =
    useModalsContext();
  const { setFlow: setShipmentFlow } = useShipmentContext();
  const { setFlow: setReceiveFlow } = useReceiveContext();
  const { user } = useUserContext();
  const userRole = useMemo(() => user?.role.name, [user]);

  const initShipmentFlow = useCallback(
    (flow: Parameters<typeof setShipmentFlow>[0]) => () => {
      setShipmentFlow(flow);
      setReceiveFlow(flow);

      if (flow === 'receive' || flow === 'driver') {
        return showReceiveSelectCodeModal();
      }

      showShipmentSelectAddressModal();
    },
    [
      setReceiveFlow,
      setShipmentFlow,
      showReceiveSelectCodeModal,
      showShipmentSelectAddressModal,
    ],
  );

  const { getShipmentsHome } = useShipmentApi();
  const { data: shipmentsHome, isLoading: isLoadingShipmentsHome } = useQuery({
    queryKey: [QueryKeys.SHIPMENTS_HOME],
    queryFn: () => getShipmentsHome(),
  });

  const ShipmentsSection = useMemo(() => {
    if (isLoadingShipmentsHome) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <Spinner />
        </div>
      );
    }

    if (shipmentsHome?.length === 0) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <Typography size="md" sizeMd="lg" color="text-Primary-03">
            {t('Home.HomePage.noRecentActivity')}
          </Typography>
        </div>
      );
    }

    return (
      <div className="px-4 pt-0 pb-4 flex-1 overflow-scroll">
        <div
          className={`flex flex-col gap-2 ${shipmentsHome?.length === 0 ? 'h-full justify-center' : ''}`}
        >
          {shipmentsHome?.map((shipment) => {
            return (
              <ActivityCard
                key={shipment.id}
                shipment={shipment}
                canChangeStatus={false}
                isLite
              />
            );
          })}
        </div>
      </div>
    );
  }, [isLoadingShipmentsHome, shipmentsHome, t]);

  return (
    <BaseLayout>
      <div className="flex flex-col h-screen">
        <div className="sticky top-0 px-4 bg-Primary-05 md:bg-Primary-04 py-3">
          <Typography
            isUppercase
            color="text-Primary-02"
            colorMd="text-Primary-00"
            size="sm"
            sizeMd="xl"
            className="md:font-bold"
          >
            {t('Home.HomePage.recentActivity')}
          </Typography>
        </div>
        {ShipmentsSection}

        {/* Button group */}
        <div
          id="homepageBottomButtonGroup"
          className="sticky md:relative bottom-0 md:bottom-auto pt-6 md:pb-6 flex flex-col md:flex-row gap-2 px-4 bg-Primary-05 md:bg-Primary-04"
        >
          {userRole === 'driver' && (
            <Button
              label={t('Home.HomePage.takeOverButton')}
              className="h-12 uppercase md:w-[100%]"
              type="primary"
              onClick={initShipmentFlow('driver')}
            />
          )}

          {userRole !== 'driver' && (
            <>
              <Button
                label={t('Home.HomePage.transferButton')}
                className="h-12 uppercase md:w-[33%]"
                type="primary"
                onClick={initShipmentFlow('transfer')}
              />
              <Button
                label={t('Home.HomePage.requestButton')}
                className="h-12 uppercase md:w-[33%]"
                type="primary"
                onClick={initShipmentFlow('request')}
              />
              <Button
                label={t('Home.HomePage.receiveButton')}
                className="h-12 uppercase md:w-[33%]"
                type="secondary"
                onClick={initShipmentFlow('receive')}
              />
            </>
          )}
        </div>
      </div>
    </BaseLayout>
  );
};

export default React.memo(Homepage);
