import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import { NewShipmentProduct } from '@definitions/Products';
import { ShipmentProduct } from '@definitions/Shipment';

import { ProductsSectionProps } from './definitions';

const ProductsSection = ({ onEdit, products }: ProductsSectionProps) => {
  const { t } = useTranslation();

  const getLabel = useCallback((styleIdTypes: string[]) => {
    const uniqueStyleIdTypes = [...new Set(styleIdTypes)];

    return uniqueStyleIdTypes
      .map((type) => {
        return type[0].toUpperCase() + type.slice(1).toLowerCase();
      })
      .join('/')
      .concat(':');
  }, []);

  const { firstStyleId, label } = useMemo(() => {
    let _products = [];

    if (products.every((p) => Object.hasOwn(p, 'product'))) {
      _products = products as ShipmentProduct[];
      return {
        firstStyleId: _products?.[0]?.product.styleId,
        label: getLabel(_products?.map((p) => p.product.styleIdType)),
      };
    }

    _products = products as NewShipmentProduct[];
    return {
      firstStyleId: _products?.[0]?.styleId,
      label: getLabel(_products?.map((p) => p.styleIdType)),
    };
  }, [getLabel, products]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        {products.length > 0 && (
          <Typography size="sm" sizeMd="md" color="text-Primary-02">
            {label}
          </Typography>
        )}
        <div className="flex-1 flex items-center gap-2">
          {products.length > 0 && (
            <Typography size="sm" sizeMd="md">
              {firstStyleId}
              {products.length > 1 ? '...' : ''}
            </Typography>
          )}
          <Typography
            size="xs"
            sizeMd="md"
            color="text-Primary-02"
            className="flex-shrink-0"
          >
            {t('General.totalItems', {
              count: products.length,
            })}
          </Typography>
        </div>
        <IconSVG icon="edit" size={24} onClick={onEdit} />
      </div>
    </div>
  );
};

export default React.memo(ProductsSection);
