import React from 'react';
import { useTranslation } from 'react-i18next';

import { GenericShipmentBaseModal } from '../GenericShipmentBaseModal';
import { GenericShipmentResumeModalProps } from './definitions';

const GenericShipmentResumeModal: React.FC<GenericShipmentResumeModalProps> = ({
  open,
  onClose,
  id,
  title,
  onNext,
  isNextDisabled,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <GenericShipmentBaseModal
      id={id}
      title={title || t('Modals.GenericShipmentResumeModal.title')}
      open={open}
      onNext={onNext}
      onClose={onClose}
      isNextDisabled={isNextDisabled}
      nextLabel={t('General.confirm')}
    >
      {children}
    </GenericShipmentBaseModal>
  );
};

export default React.memo(GenericShipmentResumeModal);
