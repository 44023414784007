import { Typography } from '@atoms/Typography';

import { TabChipProps } from './definitions';

const TabChip = ({ label, onClick, isActive, disabled }: TabChipProps) => {
  return (
    <button
      className={`ui-btn flex-grow rounded-3xl h-[40px] min-h-0 border-0 ${isActive ? 'bg-Primary-04' : 'bg-transparent'} shadow-none ${disabled ? 'text-Primary-03 font-normal bg-transparent hover:bg-transparent cursor-not-allowed' : ''}`}
      onClick={onClick}
    >
      {typeof label === 'string' ? (
        <Typography
          isUppercase
          size="md"
          color={
            isActive
              ? 'text-Primary-00'
              : disabled
                ? 'text-Primary-03'
                : 'text-Primary-02'
          }
          className={`${isActive ? 'font-medium' : 'font-normal'}`}
        >
          {label}
        </Typography>
      ) : (
        label
      )}
    </button>
  );
};

export default TabChip;
