import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@atoms/Typography';

import { SingleOrganizationSectionProps } from './definitions';

const SingleOrganizationSection = ({
  address,
  name,
}: SingleOrganizationSectionProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      {name && (
        <div className="flex items-center gap-2">
          <Typography size="sm" sizeMd="md" color="text-Primary-02">
            {t('Modals.GenericShipmentResume.consignee')}:
          </Typography>
          <Typography size="sm" sizeMd="md">
            {name}
          </Typography>
        </div>
      )}

      {address && (
        <div className="flex items-center gap-2">
          <Typography size="sm" sizeMd="md" color="text-Primary-02">
            {t('Modals.GenericShipmentResume.address')}:
          </Typography>
          <Typography size="sm" sizeMd="md">
            {address}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default React.memo(SingleOrganizationSection);
