import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useShipmentApi } from '@api/ShipmentApi';
import { QrCodeScanner } from '@atoms/QrCodeScanner';
import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { useReceiveContext } from '@contexts/receive';
import { yupResolver } from '@hookform/resolvers/yup';
import useToast from '@hooks/useToast';
import useWindow from '@hooks/useWindow';
import { Input } from '@molecules/Form/Input';
import { ReceiveBaseModal } from '@organisms/Modals/ReceiveFlow/ReceiveBaseModal';
import { useMutation } from '@tanstack/react-query';

import { ReceiveShipmentIdModalProps } from './definitions';
import { useValidationSchema } from './validation';

const ReceiveShipmentIdModal: React.FC<ReceiveShipmentIdModalProps> = ({
  open,
  onClose,
  isQRCode,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useWindow();

  const { showReceiveShipmentDetailsModal } = useModalsContext();
  const {
    setSelectedShipmentId,
    selectedShipmentCode,
    setSelectedShipmentCode,
  } = useReceiveContext();

  const validation = useValidationSchema();

  const {
    control,
    formState,
    getValues,
    reset: resetForm,
  } = useForm<{
    shipmentCode: string;
  }>({
    resolver: yupResolver(validation),
  });

  const { showWarningToast } = useToast();

  const isNextDisabled = useMemo(
    () => !formState.isValid || formState.isSubmitting,
    [formState.isSubmitting, formState.isValid],
  );

  const { verifyQrcode } = useShipmentApi();

  const { mutate: callVerifyQrcode, isPending: isPendingCallVerifyQrcode } =
    useMutation({
      mutationFn: verifyQrcode,
    });

  const onScanHandler = useCallback(
    (result: Array<{ format: string; rawValue: string }>) => {
      setSelectedShipmentCode(undefined);
      callVerifyQrcode(
        { qrcodePayload: result[0].rawValue },
        {
          onSuccess(data) {
            setSelectedShipmentId(data.shipmentId);
            showReceiveShipmentDetailsModal();
          },
          onError() {
            showWarningToast(t('ErrorPage.somethingWrong'));
          },
        },
      );
    },
    [
      callVerifyQrcode,
      setSelectedShipmentCode,
      setSelectedShipmentId,
      showReceiveShipmentDetailsModal,
      showWarningToast,
      t,
    ],
  );

  const onNextHandler = useCallback(() => {
    if (isQRCode) {
      return;
    }

    setSelectedShipmentCode(getValues('shipmentCode'));

    setSelectedShipmentId(undefined);

    showReceiveShipmentDetailsModal();
  }, [
    getValues,
    isQRCode,
    setSelectedShipmentCode,
    setSelectedShipmentId,
    showReceiveShipmentDetailsModal,
  ]);

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  useEffect(() => {
    resetForm({ shipmentCode: '' });
  }, [resetForm, selectedShipmentCode]);

  return (
    <ReceiveBaseModal
      open={open}
      id="receiveShipmentIdModal"
      onClose={handleClose}
      size={isMobile ? 'large' : 'small'}
      title={t('Modals.ReceiveFlow.ShipmentId.title', {
        context: isQRCode ? 'qrCode' : 'digit',
      })}
      isNextDisabled={isNextDisabled}
      onNext={isQRCode ? undefined : onNextHandler}
    >
      {!isQRCode && (
        <div className="flex flex-col gap-7 mb-7">
          <Typography size="sm" color="text-Primary-02">
            {t('Modals.ReceiveFlow.ShipmentId.description')}
          </Typography>
          <Controller
            control={control}
            name="shipmentCode"
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <Input
                maxLength={6}
                name={name}
                type="string"
                value={value}
                onChange={onChange}
                placeholder={t(
                  'Modals.ReceiveFlow.ShipmentId.labelPlaceholder',
                )}
                error={error?.message}
              />
            )}
          />
        </div>
      )}
      {isQRCode && (
        <div className="h-full flex justify-center items-center bg-Primary-01">
          <QrCodeScanner
            onScan={onScanHandler}
            paused={isPendingCallVerifyQrcode}
          />
        </div>
      )}
    </ReceiveBaseModal>
  );
};

export default React.memo(ReceiveShipmentIdModal);
