import { useMemo } from 'react';

import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import { TypographyProps } from '@atoms/Typography/definitions';
import { useNavigation } from '@navigation/useNavigation';
import { theme } from '@theme';

import { NavigationItemProps } from './definitions';

const NavigationItem = ({
  label,
  isVertical,
  icon,
  isActive,
  goToRoute,
}: NavigationItemProps) => {
  const { goTo } = useNavigation();
  const iconSize = !isVertical ? 20 : 24;

  const divClassName = useMemo(() => {
    const classes = ['flex w-full md:px-5 py-4 items-center'];

    if (isVertical) {
      classes.push(
        isActive ? 'flex-row bg-Primary-00' : 'flex-row bg-transparent',
      );
    } else {
      classes.push('flex-col');
    }

    return classes.join(' ');
  }, [isActive, isVertical]);

  const textProps = useMemo((): Pick<
    TypographyProps,
    'color' | 'className' | 'isUppercase'
  > => {
    const classes = ['pt-1 md:pt-0 md:pl-4 truncate'];

    if (isVertical) {
      classes.push(isActive ? 'font-bold pl-4' : 'font-normal pl-4');
    } else {
      classes.push('w-[65%]');
    }

    const getColor = () => {
      if (isVertical) {
        return isActive ? 'text-Primary-05' : 'text-Primary-00';
      }

      return isActive ? 'text-Primary-00' : 'text-Primary-03';
    };

    return {
      color: getColor(),
      className: classes.filter(Boolean).join(' '),
      isUppercase: isVertical,
    };
  }, [isActive, isVertical]);

  const iconColor = useMemo(() => {
    const classes = [];

    if (isVertical) {
      classes.push(
        isActive ? theme.colors['Primary-05'] : theme.colors['Primary-00'],
      );
    } else {
      classes.push(
        isActive ? theme.colors['Primary-00'] : theme.colors['Primary-03'],
      );
    }

    return classes.join(' ');
  }, [isActive, isVertical]);

  return (
    <button className={divClassName} onClick={() => goTo(goToRoute)}>
      <IconSVG icon={icon} size={iconSize} color={iconColor} />
      <Typography size="xs" sizeMd="md" {...textProps}>
        {label}
      </Typography>
    </button>
  );
};
export default NavigationItem;
