import { useTranslation } from 'react-i18next';

import { DividerLine } from '@atoms/DividerLine';
import { Logo } from '@atoms/Logo';
import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { Logout } from '@molecules/Logout';
import { Navigation } from '@molecules/Navigation';
import { UserDetails } from '@molecules/UserDetails';
import { ChangeLanguage } from '@organisms/ChangeLanguage';

import { NavigationMenuDesktopLayoutsProps } from './definitions';

// This is the main component for the desktop layout of the navigation menu
const NavigationMenuDesktopLayouts = ({
  children,
}: NavigationMenuDesktopLayoutsProps) => {
  // Use the translation hook to get the translation function
  const { t } = useTranslation();
  const { showChangePasswordModal, showNotificationModal } = useModalsContext();

  return (
    <main className="flex">
      {/* This div contains the main navigation elements */}
      <aside className="flex flex-col shrink-0 gap-6 lg:w-[325px] p-8 h-screen">
        <div className="flex flex-col justify-between gap-4 h-full">
          <div className="flex flex-col gap-6">
            <Logo type="black" />
            <UserDetails />

            <Navigation />

            <DividerLine />

            {/* This div contains the settings and logout options, and is scrollable */}
            <div className="overflow-y-auto">
              <div className="px-4">
                <Typography isUppercase size="md">
                  {t('General.settings')}
                </Typography>
                <ChangeLanguage />
                <button
                  onClick={showChangePasswordModal}
                  className="text-Primary-00 w-full uppercase text-md font-normal overflow-hidden whitespace-nowrap text-left "
                >
                  {t('ChangePassword.title')}
                </button>
                <button
                  onClick={showNotificationModal}
                  className="text-Primary-00 w-full py-5 pr-4 uppercase text-md font-normal overflow-hidden whitespace-nowrap text-left "
                >
                  {t('Modals.NotificationSettings.title')}
                </button>
              </div>
            </div>
          </div>
          <div className="bg-Primary-05 pl-2">
            <Logout />
          </div>
        </div>
      </aside>

      {/* This div contains the children passed to the NavigationMenuDesktopLayouts component */}
      <div id="pageContent" className="bg-Primary-04 flex-1">
        {children}
      </div>
    </main>
  );
};

export default NavigationMenuDesktopLayouts;
