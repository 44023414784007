import React, { useCallback, useEffect, useRef } from 'react';

import { ScrollableViewProps } from '@molecules/ScrollableView/ScrollableViewProps';

const ScrollableView = ({
  children,
  onBottomReached,
  className = '',
  treshold = 0,
}: ScrollableViewProps) => {
  const listRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;

      if (scrollTop + clientHeight >= scrollHeight - treshold) {
        onBottomReached?.();
      }
    }
  }, [onBottomReached, treshold]);

  useEffect(() => {
    listRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      listRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, listRef]);

  return (
    <div ref={listRef} className={`${className} overflow-y-scroll`}>
      {children}
    </div>
  );
};

export default React.memo(ScrollableView);
