import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLanguageApi } from '@api/LanguageApi';
import { useUserApi } from '@api/UserApi';
import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import { QueryKeys } from '@definitions/QueryKeys';
import { useLogger } from '@hooks/useLogger';
import { ItemSelect } from '@molecules/ItemSelect';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { theme } from '@theme';

const ChangeLanguage = (): ReactElement => {
  const {
    t,
    i18n: { language: currentLanguage, changeLanguage: changeLanguageClient },
  } = useTranslation();
  const queryClient = useQueryClient();
  const { changeLanguage: changeLanguageApi } = useUserApi();

  const { getLanguages } = useLanguageApi();

  const [showLanguages, setShowLanguages] = useState(false);

  const { data: languageList } = useQuery({
    queryKey: [QueryKeys.LANGUAGES],
    queryFn: getLanguages,
  });

  const { mutate: callChangeLanguageApi } = useMutation({
    mutationFn: changeLanguageApi,
  });

  const setShowLanguagesHandler = useCallback(
    () => setShowLanguages((prevState) => !prevState),
    [],
  );

  const { logError } = useLogger();

  const changeLanguageHandler = useCallback(
    (id: number) => {
      callChangeLanguageApi(
        {
          languageId: id,
        },
        {
          onSuccess: () => {
            changeLanguageClient(
              languageList?.find((lang) => lang.id === id)?.code,
            );

            // Refetch all queries after changing language
            queryClient.invalidateQueries();
          },
          onError: (error) => {
            logError(error);
          },
        },
      );
    },
    [
      callChangeLanguageApi,
      changeLanguageClient,
      languageList,
      logError,
      queryClient,
    ],
  );

  return (
    <div className="bg-Primary-05 py-5 pr-4">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={setShowLanguagesHandler}
      >
        <Typography isUppercase size="md" className="font-bold md:font-normal">
          {t('General.changeLanguage')}
        </Typography>
        <IconSVG
          icon={showLanguages ? 'chevron-up' : 'chevron-down'}
          size={16}
          color={theme.colors['Primary-00']}
        />
      </div>

      {showLanguages && (
        <div
          className={
            'pl-8 pt-5 flex flex-col gap-4 overflow-hidden whitespace-nowrap'
          }
        >
          {languageList?.map(({ id, code }) => {
            return (
              <ItemSelect
                key={`${code}-${id}`}
                checkbox={{
                  checked: code === currentLanguage,
                  onChange: () => changeLanguageHandler(id),
                  name: id.toString(),
                  value: t(`General.languages.${code}`),
                }}
              >
                <Typography
                  size="sm"
                  sizeMd="md"
                  color="text-Primary-03"
                  colorMd="text-Primary-00"
                >
                  {t(`General.languages.${code}`)}
                </Typography>
              </ItemSelect>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default React.memo(ChangeLanguage);
