import { useEffect, useState } from 'react';

export const useFilePreview = (file: File | null) => {
  const [previewUrl, setPreviewUrl] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!file) {
      setIsLoading(false);
      setPreviewUrl(undefined);
      return;
    }

    setIsLoading(true);
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      setIsLoading(false);
      const result = event.target?.result;
      if (typeof result === 'string') {
        setPreviewUrl(result);
      }
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  return {
    previewUrl,
    isLoading,
  };
};
