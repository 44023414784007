import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSVG } from '@atoms/IconSVG';
import { useModalsContext } from '@contexts/modals';
import { useShipmentContext } from '@contexts/shipment';
import useToast from '@hooks/useToast';

import { ShipmentBaseModal } from '../ShipmentBaseModal';
import { ShipmentBaseModalProps } from '../ShipmentBaseModal/definitions';
import TabChip from './TabChip/TabChip';
import Tabs from './Tabs';

const ShipmentSelectProductsModal = ({
  open,
  onClose,
}: Pick<ShipmentBaseModalProps, 'open' | 'onClose'>) => {
  const { t } = useTranslation();
  const { showWarningToast } = useToast();
  const { flow, products, supports } = useShipmentContext();
  const { showShipmentSelectPackagingModal, showShipmentRemarksModal } =
    useModalsContext();
  const [activeTabId, setActiveTabId] = useState<
    'products' | 'supports' | 'summary'
  >();

  const hasSelectedOnlyExternalCodes = useMemo(() => {
    return (
      products.length > 0 &&
      products.every(
        (product) =>
          product.styleIdType === 'sts' ||
          product.styleIdType === 'consignment',
      )
    );
  }, [products]);

  const isProductsAndSupportsTabsDisabled = hasSelectedOnlyExternalCodes;

  useEffect(() => {
    if (open) {
      if (hasSelectedOnlyExternalCodes) {
        setActiveTabId('summary');
        return;
      }

      setActiveTabId('products');
    }
  }, [hasSelectedOnlyExternalCodes, open]);

  const hasSelectedProducts = useMemo(() => {
    return products.length > 0;
  }, [products.length]);

  const hasSelectedSupports = useMemo(() => {
    return supports.length > 0;
  }, [supports.length]);

  const hasSelectedProductsOrSupports = useMemo(() => {
    return hasSelectedProducts || hasSelectedSupports;
  }, [hasSelectedProducts, hasSelectedSupports]);

  const isSummaryTabDisabled = !hasSelectedProductsOrSupports;

  const isNextDisabled = useMemo(() => {
    if (activeTabId === 'products' && !hasSelectedProducts) {
      return true;
    }

    if (activeTabId === 'supports' && !hasSelectedSupports) {
      return true;
    }

    return activeTabId === 'summary' && !hasSelectedProducts;
  }, [activeTabId, hasSelectedProducts, hasSelectedSupports]);

  const handleSelectProtectedTab = useCallback(
    (tabId: typeof activeTabId) => {
      if (isProductsAndSupportsTabsDisabled) {
        showWarningToast(
          t('Modals.ShipmentSelectProducts.warningToastMessage'),
        );
        return;
      }

      setActiveTabId(tabId);
    },
    [isProductsAndSupportsTabsDisabled, showWarningToast, t],
  );

  const handleSelectSummaryTab = useCallback(() => {
    if (isSummaryTabDisabled) {
      return;
    }

    setActiveTabId('summary');
  }, [isSummaryTabDisabled]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const onNextHandler = useCallback(() => {
    if (activeTabId === 'products' || activeTabId === 'supports') {
      // After selecting products or supports, show the summary tab
      setActiveTabId('summary');
      return;
    }

    if (flow === 'transfer') {
      showShipmentSelectPackagingModal();
      return;
    }

    if (flow === 'request') {
      showShipmentRemarksModal();
    }
  }, [
    activeTabId,
    flow,
    showShipmentRemarksModal,
    showShipmentSelectPackagingModal,
  ]);

  return (
    <ShipmentBaseModal
      id={`${flow}SelectProductModal`}
      title={t('Modals.ShipmentSelectProducts.title', { context: activeTabId })}
      open={open}
      onClose={handleClose}
      onNext={onNextHandler}
      isNextDisabled={isNextDisabled}
    >
      <div
        role="tablist"
        className="flex gap-4 pt-1 bg-Primary-05 sticky top-0 z-[2]"
      >
        <div className="flex gap-4 flex-1">
          <TabChip
            label={t('Shipment.TabChips.products')}
            onClick={() => handleSelectProtectedTab('products')}
            isActive={activeTabId === 'products'}
            disabled={isProductsAndSupportsTabsDisabled}
          />
          <TabChip
            label={t('Shipment.TabChips.supports')}
            onClick={() => handleSelectProtectedTab('supports')}
            isActive={activeTabId === 'supports'}
            disabled={isProductsAndSupportsTabsDisabled}
          />
        </div>
        <div className="relative w-16 md:w-22 flex justify-center items-center">
          <TabChip
            label={<IconSVG icon="group" size={24} />}
            onClick={handleSelectSummaryTab}
            isActive={activeTabId === 'summary'}
            disabled={isSummaryTabDisabled}
          />
          {hasSelectedProductsOrSupports && (
            <span className="absolute bottom-[7px] right-[20px] sm:right-[30px] w-[10px] h-[10px] bg-Primary-00 rounded-full" />
          )}
        </div>
      </div>

      {activeTabId === 'products' && <Tabs.Products className="mt-2" />}
      {activeTabId === 'supports' && <Tabs.Supports className="mt-2" />}
      {activeTabId === 'summary' && <Tabs.Summary className="mt-2" />}
    </ShipmentBaseModal>
  );
};
export default React.memo(ShipmentSelectProductsModal);
