import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

import { TranslationKey } from './definitions';
import en from './en.json';
import fr from './fr.json';

export const supportedLngs = Object.values(TranslationKey);
export const defaultLocale = TranslationKey.EN;
export const defaultNS = 'common';
export const resources = {
  [TranslationKey.EN]: {
    common: en,
  },
  [TranslationKey.FR]: {
    common: fr,
  },
};

i18n.use(initReactI18next).init({
  supportedLngs,
  fallbackLng: defaultLocale,
  ns: ['common'],
  defaultNS,
  resources,
});

export default i18n;
