import { QueryKeys } from '@definitions/QueryKeys';
import { useQueryClient } from '@tanstack/react-query';

export const useInvalidateQueries = () => {
  const queryClient = useQueryClient();

  /**
   * Invalidate all shipment queries
   *
   * SHIPMENTS_HOME, SHIPMENTS_PENDING, SHIPMENT_IN_COURSE, SHIPMENT_HISTORY, SHIPMENT
   */
  const invalidateAllShipmentQueries = () => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.SHIPMENTS_HOME],
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.SHIPMENTS_PENDING],
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.SHIPMENT_IN_COURSE],
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.SHIPMENT_HISTORY],
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.SHIPMENT],
    });
  };

  return {
    invalidateAllShipmentQueries,
  };
};
