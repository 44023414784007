import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { useShipmentApi } from '@api/ShipmentApi';
import { useModalsContext } from '@contexts/modals';
import { ModalKey } from '@contexts/modals/definitions';
import { usePendingShipmentContext } from '@contexts/pendingShipment';
import { useInvalidateQueries } from '@hooks/useInvalidateQueries';
import { GenericShipmentResumeModal } from '@organisms/Modals/GenericShipmentResumeModal';
import DatesSection from '@organisms/Modals/GenericShipmentResumeModal/Sections/Dates';
import PackagingSection from '@organisms/Modals/GenericShipmentResumeModal/Sections/Packaging';
import ProductsSection from '@organisms/Modals/GenericShipmentResumeModal/Sections/Products';
import SingleOrganizationSection from '@organisms/Modals/GenericShipmentResumeModal/Sections/SingleOrganization';
import { useMutation } from '@tanstack/react-query';
import { isNullOrUndefined } from '@utils/typeCheck';

import { usePendingShipmentBaseModal } from '../PendingShipmentBaseModal/hooks';
import { PendingShipmentResumeModalProps } from './definitions';

const PendingShipmentResumeModal: React.FC<PendingShipmentResumeModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const { invalidateAllShipmentQueries } = useInvalidateQueries();
  const {
    showResumeConfirmationModal,
    hideAllPendingShipmentModals,
    hideModal,
  } = useModalsContext();
  const { acceptShipment } = useShipmentApi();

  const { onDismiss } = usePendingShipmentBaseModal({ onClose });

  const { mutate: callAcceptShipment, isPending: isPendingCallAcceptShipment } =
    useMutation({
      mutationFn: acceptShipment,
    });

  const {
    products,
    packagings,
    reset: resetPendingShipmentContext,
    selectedShipment,
  } = usePendingShipmentContext();

  const refusedProductIds = useMemo(() => {
    return selectedShipment?.shipmentProducts
      .filter((product) => products.every((p) => p.id !== product.id))
      .map((product) => product.id);
  }, [products, selectedShipment]);

  const handleNext = useCallback(() => {
    if (!isNullOrUndefined(selectedShipment?.id)) {
      callAcceptShipment(
        {
          shipmentID: selectedShipment.id,
          refusedProductIds: refusedProductIds || [],
          packagings: packagings.map((p) => ({
            packagingId: p.id,
            quantity: p.quantity,
          })),
        },
        {
          onSuccess: (res) => {
            showResumeConfirmationModal({
              content: t('Modals.PendingShipmentResume.confirmationContent'),
              code: res.code,
              onConfirm: () => {
                hideAllPendingShipmentModals();
                resetPendingShipmentContext();
              },
            });

            invalidateAllShipmentQueries();
          },
        },
      );
    }
  }, [
    t,
    selectedShipment?.id,
    callAcceptShipment,
    refusedProductIds,
    packagings,
    showResumeConfirmationModal,
    hideAllPendingShipmentModals,
    resetPendingShipmentContext,
    invalidateAllShipmentQueries,
  ]);

  const handleEditSection = useCallback(
    (sectionType: string) => () => {
      hideModal(ModalKey.PENDING_SHIPMENT_RESUME);

      if (sectionType === 'packagings') return;

      hideModal(ModalKey.PENDING_SHIPMENT_SELECT_PACKAGING);
    },
    [hideModal],
  );

  return (
    <GenericShipmentResumeModal
      id="acceptPendingShipmentResumeModal"
      title={t('Modals.GenericShipmentResume.title')}
      open={open}
      onNext={handleNext}
      onClose={onDismiss}
      isNextDisabled={isPendingCallAcceptShipment}
    >
      <div className="flex flex-col gap-4">
        <SingleOrganizationSection
          name={selectedShipment?.receiverOrganization.name}
          address={selectedShipment?.receiverOrganization.address}
        />
        <ProductsSection
          onEdit={handleEditSection('products')}
          products={products}
        />

        <DatesSection
          remarks={{
            urgentDelivery: selectedShipment?.isUrgent,
            desideredDeliveryDate: selectedShipment?.desiredDeliveryDate
              ? dayjs(selectedShipment?.desiredDeliveryDate).toDate()
              : undefined,
            comment: '',
          }}
        />

        <PackagingSection
          onEdit={handleEditSection('packagings')}
          packagings={packagings}
        />
      </div>
    </GenericShipmentResumeModal>
  );
};

export default React.memo(PendingShipmentResumeModal);
