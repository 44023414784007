import { useMemo } from 'react';

import { useUserContext } from '@contexts/user';
import { Roles } from '@definitions/Users';

export const AdminPages = [
  'history',
  'inCourse',
  'statistics',
  'items',
  'users',
  'organizations',
];

export const UserPages = [
  'home',
  'pending',
  'history',
  'inCourse',
  'statistics',
];

export const DriverPages = ['home', 'history'];

export const useGetNavigationItem = () => {
  const { user } = useUserContext();

  const items = useMemo(() => {
    switch (user?.role.name) {
      case Roles.ADMIN:
        return AdminPages;
      case Roles.USER:
        return UserPages;
      case Roles.DRIVER:
        return DriverPages;
      default:
        return [];
    }
  }, [user]);

  return { items };
};
