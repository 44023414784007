import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@atoms/Button';
import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { Product } from '@definitions/Products';
import BaseModal from '@organisms/Modals/BaseModal/BaseModal';

import { ShipmentAddItemCodeModalProps } from './definitions';

const ShipmentAddItemCodeModal: React.FC<ShipmentAddItemCodeModalProps> = ({
  open,
  onClose,
  product,
}) => {
  const { t } = useTranslation();
  const { showShipmentItemDetailsModal } = useModalsContext();

  const onButtonClick = useCallback(
    (type: Product['styleIdType']) => {
      showShipmentItemDetailsModal({
        type,
        product,
      });
      onClose?.();
    },
    [onClose, product, showShipmentItemDetailsModal],
  );

  return (
    <BaseModal
      id="shipmentAddItemCodeModal"
      open={open}
      onClose={onClose}
      showCloseButton
    >
      <div className="flex flex-col gap-7">
        <div className="flex flex-col gap-2">
          <Typography isUppercase isBold size="md">
            {t('Modals.ShipmentAddItemCode.firstTitle')}
          </Typography>
          <Typography size="sm" color="text-Primary-02">
            {t('Modals.ShipmentAddItemCode.firstDescription')}
          </Typography>
          <Button
            label={t('General.Sku')}
            type="primary"
            onClick={() => onButtonClick('sku')}
          />
          <Button
            label={t('General.Other')}
            type="primary"
            onClick={() => onButtonClick('other')}
            disableUppercase
          />
        </div>
        <div className="flex flex-col gap-2">
          <Typography isUppercase isBold size="md">
            {t('Modals.ShipmentAddItemCode.secondTitle')}
          </Typography>
          <Typography size="sm" color="text-Primary-02">
            <Trans
              i18nKey="Modals.ShipmentAddItemCode.secondDescription"
              components={{ u: <u /> }}
            />
          </Typography>
          <Button
            label={t('General.Sts')}
            type="primary"
            onClick={() => onButtonClick('sts')}
          />
          <Button
            label={t('General.consignmentNumber')}
            type="primary"
            onClick={() => onButtonClick('consignment')}
            disableUppercase
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default React.memo(ShipmentAddItemCodeModal);
