import { Sheet } from 'react-modal-sheet';

import { BottomSheetProps } from './definitions';

const BottomSheet = ({
  children,
  isOpen = false,
  onClose,
  ...propsRest
}: BottomSheetProps) => {
  return (
    <Sheet isOpen={isOpen} onClose={onClose} {...propsRest}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>{children}</Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

export default BottomSheet;
