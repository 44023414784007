import { useTranslation } from 'react-i18next';

import { Shipment, ShipmentType } from '@definitions/Shipment';
import { User } from '@definitions/Users';

export const useShipmentData = () => {
  const { t } = useTranslation();

  const getAllShipmentProductsTotal = (shipment: Shipment) => {
    return shipment?.shipmentProducts?.length;
  };

  const getAllAcceptedShipmentProducts = (shipment: Shipment) => {
    return shipment?.shipmentProducts?.filter((p) => p.isAccepted);
  };

  const getShipmentType = (shipment: Shipment, user: User | null) => {
    return shipment?.senderOrganizationId === user?.organization.id
      ? ShipmentType.OUTGOING
      : ShipmentType.INCOMING;
  };

  const getShipmentTotalProducts = (shipment: Shipment) => {
    const allShipmentProducts = shipment?.shipmentProducts?.length;
    const allAcceptedShipmentProducts = shipment?.shipmentProducts?.filter(
      (p) => p.isAccepted,
    ).length;

    return allAcceptedShipmentProducts
      ? `${t('General.totalItemsPartial', { firstNumber: allAcceptedShipmentProducts, secondNumber: allShipmentProducts })}`
      : `${t('General.totalItems', { count: allShipmentProducts })}`;
  };

  const getShipmentProductStyle = (shipment: Shipment) => {
    const styleIdType = () => {
      const styleIds = shipment?.shipmentProducts?.map(
        (p) => p.product.styleIdType,
      );
      const uniqueStyleIdTypes = [...new Set(styleIds)];

      return uniqueStyleIdTypes
        .map((type) => {
          return type[0].toUpperCase() + type.slice(1).toLowerCase();
        })
        .join('/');
    };

    const styleId = shipment?.shipmentProducts
      .map((p) => p.product.styleId)
      .join(', ');

    return {
      styleIdType: styleIdType(),
      styleId,
    };
  };

  return {
    getAllShipmentProductsTotal,
    getAllAcceptedShipmentProducts,
    getShipmentType,
    getShipmentTotalProducts,
    getShipmentProductStyle,
  };
};
