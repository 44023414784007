import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { UseNavigationProps } from './definitions.ts';

export const useNavigation = (): UseNavigationProps => {
  // Use the useNavigate hook from react-router-dom to get the navigate function
  const navigate = useNavigate();

  // Define a function to navigate to a given path
  const goTo = useCallback<UseNavigationProps['goTo']>(
    (path, params): void => {
      const navigationPath: string = generatePath(path, params);
      navigate(navigationPath);
    },
    [navigate]
  );

  const goBack = useCallback<UseNavigationProps['goBack']>((): void => {
    return navigate(-1);
  }, [navigate]);

  // Return the navigation functions
  return {
    goTo,
    goBack,
  };
};
