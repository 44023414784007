import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthApi } from '@api/authApi';
import { Typography } from '@atoms/Typography';
import { useAuthentication } from '@contexts/auth';
import { emailRegex, passwordRegex } from '@hooks/useRegex';
import useToast from '@hooks/useToast.tsx';
import { Input } from '@molecules/Form/Input';
import { Link } from '@molecules/Link';
import { AnonymousRoutes } from '@navigation/routes.ts';
import { LoginLayout } from '@templates/LoginLayout';

const Login = (): ReactElement => {
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');

  const { showWarningToast } = useToast();

  const { t } = useTranslation();

  const { setTokens } = useAuthentication();

  const { login } = useAuthApi();

  const isFormValid = useMemo(
    () => emailRegex.test(userEmail) && passwordRegex.test(userPassword),
    [userEmail, userPassword],
  );

  const loginHandler = useCallback(async () => {
    if (!emailRegex.test(userEmail) || !passwordRegex.test(userPassword)) {
      showWarningToast(t('Login.LoginPage.toastMessage'));
      return;
    }

    login
      .mutateAsync({ email: userEmail, password: userPassword })
      .then(
        ({
          tokenData: { accessToken, refreshToken, expiresIn, refreshExpiresIn },
        }) => {
          setTokens({
            token: accessToken,
            refreshToken: refreshToken,
            tokenExpires: expiresIn,
            refreshTokenExpires: refreshExpiresIn,
          });
        },
      )
      .catch(() => {
        setUserEmail('');
        setUserPassword('');
        showWarningToast(t('Login.LoginPage.toastMessage'));
      });
  }, [userEmail, userPassword, login, t, setTokens, showWarningToast]);

  return (
    <LoginLayout
      CTATitle={t('Login.LoginPage.title')}
      CTAHandler={loginHandler}
      CTADisabled={!isFormValid}
    >
      <Typography isUppercase color="text-Primary-05" size="xl" isBold>
        {t('Login.LoginPage.title')}
      </Typography>
      <div className={'mt-10 md:mt-16'}>
        <Input
          variant="light"
          title={t('Login.LoginPage.username')}
          placeholder={t('Login.LoginPage.usernamePlaceholder')}
          placeholderUppercase
          name={t('Login.LoginPage.username')}
          type="email"
          value={userEmail}
          onChange={setUserEmail}
        />
      </div>
      <div className="mt-10 md:mt-16">
        <Input
          variant="light"
          title={t('Login.LoginPage.password')}
          placeholder={t('Login.LoginPage.passwordPlaceholder')}
          placeholderUppercase
          name={t('Login.LoginPage.password')}
          type="password"
          value={userPassword}
          onChange={setUserPassword}
        />
      </div>

      <div className="mt-14 md:mt-20">
        <Link to={AnonymousRoutes.FORGOT_PASSWORD}>
          <Typography
            isUppercase
            color="text-Primary-05"
            size="sm"
            sizeMd="md"
            isBold
          >
            {t('Login.LoginPage.forgotPassword')}
          </Typography>
        </Link>
      </div>
    </LoginLayout>
  );
};

export default React.memo(Login);
