import { BaseModal } from '@organisms/Modals/BaseModal';
import { GenericShipmentBaseModal } from '@organisms/Modals/GenericShipmentBaseModal';

import { PendingShipmentBaseModalProps } from './definitions';
import { usePendingShipmentBaseModal } from './hooks';

const PendingShipmentBaseModal = ({
  id,
  open,
  onBottomReached,
  onClose,
  onNext,
  title,
  isNextDisabled,
  children,
  type,
  size,
}: PendingShipmentBaseModalProps) => {
  const { onDismiss } = usePendingShipmentBaseModal({ onClose });

  if (type === 'custom') {
    return (
      <BaseModal
        id={id}
        size={size}
        open={open}
        onClose={onDismiss}
        showCloseButton
        title={title}
        onBottomReached={onBottomReached}
      >
        {children}
      </BaseModal>
    );
  }

  return (
    <GenericShipmentBaseModal
      id={id}
      open={open}
      title={title}
      onClose={onDismiss}
      onBack={onClose}
      onBottomReached={onBottomReached}
      onNext={onNext}
      isNextDisabled={isNextDisabled}
    >
      {children}
    </GenericShipmentBaseModal>
  );
};
export default PendingShipmentBaseModal;
