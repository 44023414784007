import { OrganizationsFilters } from './OrganizationsFilters';
import { OrganizationsTable } from './OrganizationsTable';
import { SingleOrganization } from './SingleOrganization';
import { Timechart } from './Timechart';
import { TimechartFilters } from './TimechartFilters';
import { Toolbar } from './Toolbar';

export default {
  Toolbar,
  OrganizationsFilters,
  OrganizationsTable,
  SingleOrganization,
  TimechartFilters,
  Timechart,
};
