import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { NewShipmentProduct } from '@definitions/Products';
import { ShipmentRemarks } from '@definitions/Shipment';

import {
  CtxShipmentPackaging,
  CtxShipmentSupport,
  ShipmentContextProps,
  ShipmentFlow,
  ShipmentProviderProps,
} from './definitions';

const undefinedMethod = () => {
  throw new Error('[ShipmentContext] Context not initialised');
};

const ShipmentContext = createContext<ShipmentContextProps>({
  flow: undefined,
  setFlow: undefinedMethod,
  organizationId: undefined,
  setOrganizationId: undefinedMethod,
  products: [],
  setProduct: undefinedMethod,
  removeProduct: undefinedMethod,
  removeAllProducts: undefinedMethod,
  packagings: [],
  setPackaging: undefinedMethod,
  supports: [],
  setSupport: undefinedMethod,
  removeAllSupports: undefinedMethod,
  reset: undefinedMethod,
  remarks: undefined,
  setRemarks: undefinedMethod,
});

export const ShipmentProvider = ({ children }: ShipmentProviderProps) => {
  const [flow, setFlow] = useState<ShipmentFlow>();

  const [organizationId, setOrganizationId] = useState<number | undefined>(
    undefined,
  );

  // Products
  const [products, _setProducts] = useState<Array<NewShipmentProduct>>([]);

  const setProduct = useCallback((product: NewShipmentProduct) => {
    _setProducts((prevProducts) => {
      const targetProductIndex = prevProducts.findIndex((prevProduct) => {
        if (prevProduct.productId === -1) {
          // Added manually, so we need to compare styleIdType and styleId
          return (
            prevProduct.styleIdType === product.styleIdType &&
            prevProduct.styleId === product.styleId
          );
        }

        return prevProduct.productId === product.productId;
      });
      const foundItem = targetProductIndex > -1;

      if (foundItem) {
        const updatedProducts = [...prevProducts];
        updatedProducts[targetProductIndex] = product;
        return updatedProducts;
      }

      return [...prevProducts, product];
    });
  }, []);

  const removeProduct = useCallback(
    (productId: NewShipmentProduct['productId']) => {
      _setProducts((prevProducts) =>
        prevProducts.filter((product) => product.productId !== productId),
      );
    },
    [],
  );

  const removeAllProducts = () => _setProducts([]);

  // Supports
  const [supports, _setSupports] = useState<Array<CtxShipmentSupport>>([]);

  const setSupport = useCallback((support: CtxShipmentSupport) => {
    _setSupports((prevSupports) => {
      const targetSupportIndex = prevSupports.findIndex(
        (sup) => sup.id === support.id,
      );
      const foundItem = targetSupportIndex > -1;

      if (foundItem) {
        const updatedSupports = [...prevSupports];
        updatedSupports[targetSupportIndex] = support;
        return updatedSupports.filter((support) => support.quantity > 0);
      }

      return [...prevSupports, support].filter(
        (support) => support.quantity > 0,
      );
    });
  }, []);

  const removeAllSupports = () => _setSupports([]);

  // Packagings
  const [packagings, _setPackagings] = useState<Array<CtxShipmentPackaging>>(
    [],
  );

  const setPackaging = useCallback((packaging: CtxShipmentPackaging) => {
    _setPackagings((prevPackagings) => {
      const targetPackagingIndex = prevPackagings.findIndex(
        (findPackaging) => findPackaging.id === packaging.id,
      );

      const foundItem = targetPackagingIndex !== -1;

      if (foundItem) {
        const updatedPackagings = [...prevPackagings];
        updatedPackagings[targetPackagingIndex] = packaging;
        return updatedPackagings.filter((packaging) => packaging.quantity > 0);
      }

      return [...prevPackagings, packaging].filter(
        (packaging) => packaging.quantity > 0,
      );
    });
  }, []);

  const _removeAllPackagings = () => _setPackagings([]);

  const [remarks, setRemarks] = useState<ShipmentRemarks>();

  const reset = useCallback(() => {
    setFlow(undefined);
    setOrganizationId(undefined);
    removeAllProducts();
    removeAllSupports();
    _removeAllPackagings();
    setRemarks(undefined);
  }, []);

  const memoizedValue = useMemo<ShipmentContextProps>(
    () => ({
      flow,
      setFlow,
      organizationId,
      setOrganizationId,
      products,
      setProduct,
      removeProduct,
      removeAllProducts,
      packagings,
      setPackaging,
      supports,
      setSupport,
      removeAllSupports,
      reset,
      remarks,
      setRemarks,
    }),
    [
      flow,
      organizationId,
      products,
      setProduct,
      removeProduct,
      packagings,
      setPackaging,
      supports,
      setSupport,
      reset,
      remarks,
    ],
  );

  return (
    <ShipmentContext.Provider value={memoizedValue}>
      {children}
    </ShipmentContext.Provider>
  );
};

export const useShipmentContext = (): ShipmentContextProps =>
  useContext(ShipmentContext);
