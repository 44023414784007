import { useCallback, useRef } from 'react';

import IconSVG from '@atoms/IconSVG/IconSVG.tsx';
import colors from '@theme/colors.ts';

import Input from '../Input/Input.tsx';
import { SearchInputProps } from './definitions.ts';

const SearchInput = ({
  name,
  value,
  placeholder,
  onChange,
  onClear,
  disabled,
  error,
}: SearchInputProps) => {
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleClear = useCallback(() => {
    onClear();
    searchInputRef.current?.focus();
  }, [onClear]);

  return (
    <Input
      ref={searchInputRef}
      name={name}
      value={value}
      placeholder={placeholder}
      placeholderUppercase
      onChange={onChange}
      disabled={disabled}
      error={error}
      startAdornment={
        <IconSVG
          icon="search"
          size={16}
          color={`${disabled ? colors['Primary-03'] : colors['Primary-00']}`}
        />
      }
      endAdornment={
        value ? (
          <IconSVG
            icon="close"
            size={16}
            onClick={handleClear}
            color={`${disabled ? colors['Primary-03'] : colors['Primary-00']}`}
          />
        ) : undefined
      }
    />
  );
};

export default SearchInput;
