import { useCallback } from 'react';

import usePrivateApi from '@api/base/private';
import { useLogger } from '@hooks/useLogger';

import { GetRoleListResponse } from './definitions';

export const useRoleApi = () => {
  const { logError } = useLogger();

  const privateApi = usePrivateApi();

  const getRoleList = useCallback(async () => {
    try {
      const { data } = await privateApi.get<GetRoleListResponse>('/roles');
      return data;
    } catch (error) {
      logError(error);
      throw error;
    }
  }, [logError, privateApi]);

  return { getRoleList };
};
