import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@atoms/Button';
import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import { BaseModal } from '@organisms/Modals/BaseModal';
import { GenericReceiveBaseModalProps } from '@organisms/Modals/GenericReceiveBaseModal/definitions';

const GenericReceiveBaseModal: React.FC<GenericReceiveBaseModalProps> = ({
  id,
  open,
  onBack,
  onBottomReached,
  onClose,
  onNext,
  isNextDisabled = false,
  nextLabel,
  title,
  children,
  Footer,
  size = 'large',
  contentClassName,
}) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      size={size}
      id={id}
      open={open}
      onClose={onClose}
      onBottomReached={onBottomReached}
      showCloseButton
      contentClassName={contentClassName}
      header={{
        leftElement: onBack ? (
          <IconSVG icon="chevron-left" size={16} onClick={onBack} />
        ) : (
          <div />
        ),
        centerElement: (
          <Typography isUppercase isBold size="lg">
            {title}
          </Typography>
        ),
        rightElement: <IconSVG icon="close" size={16} onClick={onClose} />,
      }}
      footer={
        Footer ||
        (onNext && (
          <Button
            className="w-full mt-4"
            type="primary"
            label={nextLabel || t('General.next')}
            onClick={onNext}
            disabled={isNextDisabled}
          />
        ))
      }
    >
      {children}
    </BaseModal>
  );
};

export default React.memo(GenericReceiveBaseModal);
