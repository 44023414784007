import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@atoms/Card';
import TabChip from '@organisms/Modals/Shipment/ShipmentSelectProductsModal/TabChip/TabChip';

import { ToolbarProps } from './definitions';

const Toolbar = ({
  activeTab,
  onActiveTabChange,
  filtersNode,
}: ToolbarProps) => {
  const { t } = useTranslation();

  return (
    <Card hasPadding={false} className="z-10">
      <div className="w-full p-4 flex flex-row items-center">
        <TabChip
          label={t('Statistics.tabs.timechart')}
          onClick={() => onActiveTabChange('timechart')}
          isActive={activeTab === 'timechart'}
        />
        <TabChip
          label={t('Statistics.tabs.organizations')}
          onClick={() => onActiveTabChange('organizations')}
          isActive={activeTab === 'organizations'}
        />
      </div>
      <div className="w-full p-4">{filtersNode}</div>
    </Card>
  );
};

export default React.memo(Toolbar);
