import React, { useCallback, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { Button } from '@atoms/Button';
import { IconSVG } from '@atoms/IconSVG';
import { useClickOutside } from '@hooks/useClickOutside';
import { defaultLocale } from '@translations/i18n';
import ReactDateRangePicker from '@wojtekmaj/react-daterange-picker';

import { DateRangePickerProps, DateRangePickerValue } from './definitions';

const createRandomId = () => crypto.randomUUID();

const DateRangePicker = ({
  value: valueFromProps,
  onChange: onChangeFromProps,
  anchor = 'top',
}: DateRangePickerProps) => {
  const {
    i18n: { language },
  } = useTranslation();
  const portalRef = React.useRef<HTMLDivElement>(null);

  const [dateValue, setDateValue] =
    useState<DateRangePickerValue>(valueFromProps);

  const [isOpen, setIsOpen] = useState(false);
  const [dateRangePickerKey, setDateRangePickerKey] = useState<string>();

  useEffect(() => {
    if (portalRef.current) {
      // refresh daterange picker
      setDateRangePickerKey(createRandomId());
    }
  }, [portalRef]);

  const handleReset = useCallback(() => {
    setDateValue([null, null]);
    onChangeFromProps([null, null]);
  }, [onChangeFromProps]);

  const handleApply = useCallback(() => {
    if (valueFromProps !== dateValue) {
      onChangeFromProps(dateValue);
    }

    if (isOpen) {
      setIsOpen(false);
    }
    // force re-render to reset the date picker
    setDateRangePickerKey(createRandomId());
  }, [dateValue, isOpen, onChangeFromProps, valueFromProps]);

  const { setWrapperRef } = useClickOutside<HTMLDivElement>({
    clickOutsideFn: handleApply,
  });

  const calculatedClassName = useMemo(() => {
    const classes = [`lang-${language}`];

    if (Array.isArray(dateValue)) {
      if (dateValue?.filter(Boolean).length > 0) {
        classes.push('ysl-date-range-picker--filled');
      }
    }

    return classes.join(' ');
  }, [dateValue, language]);

  useEffect(() => {
    setDateValue(valueFromProps);
  }, [valueFromProps]);

  return (
    <div ref={setWrapperRef} className="ysl-date-range-picker__wrapper">
      <ReactDateRangePicker
        key={dateRangePickerKey}
        onCalendarOpen={() => setIsOpen(true)}
        onCalendarClose={() => setIsOpen(false)}
        locale={language}
        className={`ysl-date-range-picker ${calculatedClassName}`}
        onChange={setDateValue}
        value={dateValue}
        rangeDivider={<IconSVG icon="arrow-right" size={20} />}
        clearIcon={null}
        calendarIcon={null}
        calendarProps={{
          next2Label: null,
          prev2Label: null,
          formatShortWeekday: (locale, date) =>
            dayjs(date)
              .locale(locale || defaultLocale)
              .format('dd')[0],
        }}
        shouldCloseCalendar={({ reason }) => {
          return reason === 'escape';
        }}
        portalContainer={portalRef.current}
      />

      {/* Calendar */}
      <div
        className={`ysl-date-range-picker__portal ${isOpen ? 'ysl-date-range-picker__portal--open' : ''} ${anchor === 'bottom' ? 'anchor-bottom' : ''} shadow-lg`.trim()}
        ref={portalRef}
      >
        <div className="buttons">
          <Button type="secondary" label="Reset" onClick={handleReset} />
          <Button type="primary" label="Apply" onClick={handleApply} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(DateRangePicker);
