import ProductsTab from './Products/Products';
import SummaryTab from './Summary/Summary';
import SupportsTab from './Supports/Supports';

const Tabs = {
  Products: ProductsTab,
  Supports: SupportsTab,
  Summary: SummaryTab,
};

export default Tabs;
