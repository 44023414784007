import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'use-debounce';

import { useBillableCenterCostApi } from '@api/billableCenterCost';
import {
  BillableCenterCostItem,
  BillableCenterCostsForAdminParams,
  GetBillableCenterCostsForAdminResponse,
} from '@api/billableCenterCost/definitions';
import { useModalsContext } from '@contexts/modals';
import { QueryKeys } from '@definitions/QueryKeys';
import useToast from '@hooks/useToast';
import { Toggle } from '@molecules/Form/Toggle';
import { TableProps } from '@molecules/Table/definitions';
import { useTableState } from '@molecules/Table/tableState';
import { AdminTableCard } from '@organisms/AdminTableCard';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';

const SEARCH_MINIMUM_CHARACTERS = 3;

const BillableCenterCost = () => {
  const { t } = useTranslation();

  const { showWarningToast } = useToast();

  const { showConfirmationModal, showAddGenericItemModal } = useModalsContext();

  const {
    getBillableCenterCostsForAdmin,
    updateBillableCenterCostsStatus,
    createBillableCenterCosts,
  } = useBillableCenterCostApi();

  const queryClient = useQueryClient();

  const [searchValue, setSearchValue] = useState('');
  const [apiSearchValue] = useDebounce(searchValue, 300);
  const parsedApiSearchValue = useMemo(() => {
    const hasMinimumCharaters =
      apiSearchValue.length >= SEARCH_MINIMUM_CHARACTERS;

    return hasMinimumCharaters ? apiSearchValue : '';
  }, [apiSearchValue]);

  const tableState = useTableState({
    defaultSorting: [
      { id: 'name', desc: true },
      { id: 'enabled', desc: true },
    ],
  });

  const queryParams = useMemo<BillableCenterCostsForAdminParams>(() => {
    const { pagination, sorting, columnFilters } = tableState;

    const params: BillableCenterCostsForAdminParams = {
      perPage: pagination.pageSize,
      page: pagination.pageIndex + 1,
    };

    if (sorting.length > 0) {
      params.orderBy = sorting.map(({ id, desc }) => ({
        columnName: id,
        sortDirection: desc ? 'desc' : 'asc',
      }));
    }

    if (columnFilters.length > 0) {
      const nameFilter = columnFilters.find((f) => f.id === 'name');
      if (nameFilter) {
        params.textFilter = nameFilter.value as string;
      }
    }

    return params;
  }, [tableState]);

  const { data: billableData, isLoading } = useQuery({
    queryKey: [QueryKeys.SHIPMENT_BILLABLE_CENTER_COSTS_FOR_ADMIN, queryParams],
    queryFn: () => getBillableCenterCostsForAdmin(queryParams),
    placeholderData: keepPreviousData,
  });

  const { mutate: callUpdateBillableCenterCost } = useMutation({
    mutationFn: updateBillableCenterCostsStatus,
    onMutate: async ({ id, enabled }) => {
      const queryKey = [
        QueryKeys.SHIPMENT_BILLABLE_CENTER_COSTS_FOR_ADMIN,
        queryParams,
      ];

      await queryClient.cancelQueries({
        queryKey: queryKey,
      });

      const previousData =
        queryClient.getQueryData<GetBillableCenterCostsForAdminResponse>(
          queryKey,
        );

      if (previousData) {
        queryClient.setQueryData<GetBillableCenterCostsForAdminResponse>(
          queryKey,
          {
            ...previousData,
            data: previousData.data.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  enabled,
                };
              }
              return item;
            }),
          },
        );
      }

      return { previousData };
    },
    onError: (_error, _variables, context) => {
      if (context?.previousData) {
        queryClient.setQueryData<GetBillableCenterCostsForAdminResponse>(
          [QueryKeys.SHIPMENT_BILLABLE_CENTER_COSTS_FOR_ADMIN, queryParams],
          context.previousData,
        );
      }

      showWarningToast(
        t('Modals.BillableCenterCostStatusConfirmation.errorMessage'),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeys.SHIPMENT_BILLABLE_CENTER_COSTS_FOR_ADMIN,
          queryParams,
        ],
      });
    },
  });

  const tableData = useMemo(() => {
    return billableData?.data ?? [];
  }, [billableData]);

  const totalResults = useMemo(() => {
    return billableData?.meta.total ?? 0;
  }, [billableData]);

  const columns = useMemo<TableProps<BillableCenterCostItem>['columns']>(() => {
    const columnHelper = createColumnHelper<BillableCenterCostItem>();

    return [
      columnHelper.accessor('name', {
        header: t('ItemsPage.billableCenterCost.table.columns.name'),
        enableSorting: true,
      }),
      columnHelper.accessor('enabled', {
        header: t('ItemsPage.billableCenterCost.table.columns.status'),
        enableSorting: true,
        cell: (info) => {
          return (
            <div className="flex flex-row items-center">
              <Toggle
                checked={info.getValue()}
                onChange={(checked) => {
                  showConfirmationModal({
                    title: t(
                      'Modals.BillableCenterCostStatusConfirmation.title',
                    ),
                    content: t(
                      'Modals.BillableCenterCostStatusConfirmation.content',
                    ),
                    onConfirm: () => {
                      callUpdateBillableCenterCost({
                        id: Number(info.row.original.id),
                        enabled: checked,
                      });
                    },
                  });
                }}
              />
            </div>
          );
        },
      }),
    ];
  }, [callUpdateBillableCenterCost, showConfirmationModal, t]);

  useEffect(() => {
    const { onColumnFiltersChange } = tableState;

    if (parsedApiSearchValue !== '') {
      onColumnFiltersChange((prevState) => {
        // All these checks are important to avoid infinite loop
        const currentFilter = prevState.find((f) => f.id === 'name');

        if (currentFilter === undefined) {
          return [
            ...prevState,
            {
              id: 'name',
              value: parsedApiSearchValue,
            },
          ];
        }

        if (currentFilter.value !== parsedApiSearchValue) {
          return prevState.map((filter) => {
            if (filter.id === 'name') {
              return {
                ...filter,
                value: parsedApiSearchValue,
              };
            }
            return { ...filter };
          });
        }

        return prevState;
      });

      return;
    }

    onColumnFiltersChange((prevState) => {
      // This check is important to avoid infinite loop
      return prevState.length > 0 ? [] : prevState;
    });

    return;
  }, [parsedApiSearchValue, tableState]);

  return (
    <AdminTableCard
      isLoading={isLoading}
      title={t('ItemsPage.billableCenterCost.table.title')}
      search={{
        name: 'search-billable-center-costs',
        value: searchValue,
        onChange: setSearchValue,
      }}
      table={{
        data: tableData,
        columns,
        ...tableState,
        totalResults,
      }}
      footerActions={[
        {
          label: t('ItemsPage.billableCenterCost.add'),
          onClick: () =>
            showAddGenericItemModal({
              createItem: createBillableCenterCosts,
              addItemLabelNs: 'AddBillableCenterCost',
              addEditItemLabelNs: 'AddEditBillableCenterCost',
              onItemCreated: () => {
                queryClient.invalidateQueries({
                  queryKey: [
                    QueryKeys.SHIPMENT_BILLABLE_CENTER_COSTS_FOR_ADMIN,
                  ],
                  exact: false,
                });
              },
            }),
        },
      ]}
    />
  );
};

export default React.memo(BillableCenterCost);
