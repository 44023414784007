import React from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { Typography } from '@atoms/Typography';

import { ResumeDatesSectionProps } from './definitions';

const ResumeDatesSection = ({ remarks }: ResumeDatesSectionProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <Typography size="sm" sizeMd="md" color="text-Primary-02">
          {t('Modals.GenericShipmentResume.entryDate')}:
        </Typography>
        <Typography size="sm" sizeMd="md">
          {dayjs().format('DD/MM/YYYY')}
        </Typography>
      </div>
      <div className="flex gap-6">
        <div className="flex items-center gap-2">
          <Typography size="sm" sizeMd="md" color="text-Primary-02">
            {t('Modals.GenericShipmentResume.deliveryReq')}:
          </Typography>
          <Typography size="sm" sizeMd="md">
            {remarks?.desideredDeliveryDate &&
              dayjs(remarks.desideredDeliveryDate.toISOString()).format(
                'DD/MM/YYYY',
              )}
          </Typography>
        </div>
        {remarks?.urgentDelivery && (
          <div className="text-center flex">
            <div className="px-[4px] w-fit bg-Urgent-red">
              <Typography
                as="span"
                isUppercase
                size="sm"
                color="text-Primary-05"
              >
                {t('General.urgent')}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ResumeDatesSection);
