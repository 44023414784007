const fontSize = {
  xs: ['12px', { lineHeight: '18px' }],
  sm: ['14px', { lineHeight: 'normal' }],
  md: ['16px', { lineHeight: '24px' }],
  lg: ['18px', { lineHeight: '24px' }],
  xl: ['20px', { lineHeight: '30px' }],
  xxl: ['24px', { lineHeight: '30px' }],
} as const;

export default fontSize;
