import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@atoms/Checkbox';
import { IconSVG } from '@atoms/IconSVG';
import { Textarea } from '@atoms/Textarea';
import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { useShipmentContext } from '@contexts/shipment';
import useWindow from '@hooks/useWindow';
import { FileInputButton } from '@molecules/FileInputButton';
import { DatePicker } from '@molecules/Form/DatePicker';
import { ShipmentBaseModal } from '@organisms/Modals/Shipment/ShipmentBaseModal';
import { ShipmentRemarksModalProps } from '@organisms/Modals/Shipment/ShipmentRemarksModal/definitions';

const ShipmentRemarksModal: React.FC<ShipmentRemarksModalProps> = ({
  open,
  onClose,
}) => {
  const { isMobile } = useWindow();
  // Use the useTranslation hook to get the t function for localization
  const { t } = useTranslation();

  const { remarks, setRemarks, flow } = useShipmentContext();

  const [datePickerValue, setDatePickerValue] = useState<Date | null>(
    remarks?.desideredDeliveryDate || null,
  );

  const {
    showShipmentImagePreviewModal,
    showConfirmationModal,
    showShipmentResumeModal,
  } = useModalsContext();

  const textareaChangeHandler = useCallback(
    (text: string) => {
      setRemarks((prevRemarks) => {
        return {
          image: prevRemarks?.image,
          comment: text,
          desideredDeliveryDate: prevRemarks?.desideredDeliveryDate,
          urgentDelivery: prevRemarks?.urgentDelivery,
        };
      });
    },
    [setRemarks],
  );

  const imageChangeHandler = useCallback(
    (file: File | undefined) => {
      setRemarks((prevRemarks) => {
        return {
          image: file,
          comment: prevRemarks?.comment,
          desideredDeliveryDate: prevRemarks?.desideredDeliveryDate,
          urgentDelivery: prevRemarks?.urgentDelivery,
        };
      });
    },
    [setRemarks],
  );

  const imageDeleteHandler = useCallback(() => {
    showConfirmationModal({
      title: t('Modals.ShipmentRemarks.deleteImageConfirm', {
        image: remarks?.image?.name,
      }),
      onConfirm: () => {
        setRemarks((prevRemarks) => {
          return {
            image: undefined,
            comment: prevRemarks?.comment,
          };
        });
      },
    });
  }, [remarks?.image?.name, setRemarks, showConfirmationModal, t]);

  const desiredDeliveryDateHandler = useCallback(
    (event: Date | null) => {
      if (event) {
        setDatePickerValue(event);
        setRemarks((prevRemarks) => {
          return {
            comment: prevRemarks?.comment,
            desideredDeliveryDate: event,
            urgentDelivery: prevRemarks?.urgentDelivery,
          };
        });
      }
    },
    [setRemarks],
  );

  const requiredHandler = useCallback(() => {
    setRemarks((prevRemarks) => {
      return {
        comment: prevRemarks?.comment,
        desideredDeliveryDate: prevRemarks?.desideredDeliveryDate,
        urgentDelivery: !prevRemarks?.urgentDelivery,
      };
    });
  }, [setRemarks]);

  return (
    <ShipmentBaseModal
      id="shipment-remarks"
      title={t('Modals.ShipmentRemarks.title')}
      open={open}
      onNext={showShipmentResumeModal}
      onClose={onClose}
    >
      <div className="mt-10 flex flex-col gap-3">
        <div className="flex flex-col gap-1">
          <Typography isUppercase isBold size="lg">
            {t('Modals.ShipmentRemarks.commentTitle')}
          </Typography>
          <Textarea
            name="remarks-comment"
            placeholder={t('Modals.ShipmentRemarks.commentPlaceholder')}
            value={remarks?.comment}
            onChange={textareaChangeHandler}
            maxLength={250}
          />
        </div>
        {flow === 'transfer' && (
          <FileInputButton
            icon="camera"
            onChange={imageChangeHandler}
            accept="image/*"
          />
        )}
        {flow === 'transfer' && remarks?.image && (
          <div className="flex justify-between items-start mt-4 gap-2">
            <div className="flex gap-2 items-start">
              <IconSVG
                icon="attachment"
                size={isMobile ? 18 : 24}
                className="shrink-0"
              />
              <Typography color="text-Primary-02" className="break-all">
                {remarks.image.name}
              </Typography>
            </div>
            <div className="flex gap-4">
              <IconSVG
                icon="search"
                size={isMobile ? 18 : 24}
                onClick={() => {
                  showShipmentImagePreviewModal({
                    image: remarks.image,
                  });
                }}
              />
              <IconSVG
                icon="trash"
                size={isMobile ? 18 : 24}
                onClick={imageDeleteHandler}
              />
            </div>
          </div>
        )}
        {flow === 'request' && (
          <>
            <div className="flex gap-3">
              <div className="flex items-center gap-2">
                <IconSVG icon="calendar" size={16} />
                <Typography size="md" isBold>
                  {t('Modals.ShipmentRemarks.desidredDeriverySelect')}:
                </Typography>
              </div>
              <DatePicker
                onChange={desiredDeliveryDateHandler}
                value={datePickerValue}
                placeholderText={t(
                  'Modals.ShipmentRemarks.datePickerPlaceholder',
                )}
                dateFormat={'dd/MM/yyyy'}
                className="border-b border-gray-300 p-2 w-fit"
              />
            </div>
            <div className="flex flex-col">
              <div className="flex gap-2 items-center">
                <Checkbox
                  name={t('Modals.ShipmentRemarks.urgentDelivery')}
                  value={t('Modals.ShipmentRemarks.urgentDelivery')}
                  checked={remarks?.urgentDelivery || false}
                  onChange={requiredHandler}
                  aria-labelledby={t('Modals.ShipmentRemarks.urgentDelivery')}
                  size="xs"
                />
                <Typography size="md">
                  {t('Modals.ShipmentRemarks.urgentDelivery')}
                </Typography>
              </div>
              {remarks?.urgentDelivery && (
                <Typography className="ml-7">
                  {t('Modals.ShipmentRemarks.urgentDeliveryDetails')}
                </Typography>
              )}
            </div>
          </>
        )}
      </div>
    </ShipmentBaseModal>
  );
};

export default React.memo(ShipmentRemarksModal);
