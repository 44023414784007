import React from 'react';

import { Toast } from '@molecules/Toast';
import { ModalsController } from '@organisms/ModalsController';

const RootElements = (): JSX.Element => {
  return (
    <>
      <Toast />
      <ModalsController />
    </>
  );
};

export default React.memo(RootElements);
