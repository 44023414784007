import React, { useMemo } from 'react';

import { CardProps } from './definitions';

const Card = ({
  backgroundColor = 'bg-Primary-05',
  hasPadding = true,
  className,
  children,
  onClick,
}: CardProps) => {
  const cardClasses = useMemo(() => {
    const classes = [
      'flex w-full flex-col rounded-md items-start',
      className,
      backgroundColor,
    ];

    if (hasPadding) {
      classes.push('py-2 px-3');
    }

    if (onClick) {
      classes.push('cursor-pointer');
    }

    return classes.join(' ');
  }, [backgroundColor, className, hasPadding, onClick]);

  return (
    <div className={cardClasses} onClick={onClick}>
      {children}
    </div>
  );
};
export default React.memo(Card);
