import { useCallback } from 'react';

import usePrivateApi from 'src/api/base/private';

import { useLogger } from '@hooks/useLogger';

import {
  CreateSupportParams,
  GetSupportResponse,
  GetSupportsParams,
  GetSupportsResponse,
  UpdateSupportParams,
  UpdateSupportStatusParams,
} from './definitions';

export const useSupportsApi = () => {
  const { logError } = useLogger();

  const privateApi = usePrivateApi();

  // Get Supports
  const getSupports = useCallback(
    async (params?: GetSupportsParams) => {
      try {
        const { data } = await privateApi.get<GetSupportsResponse>(
          '/supports',
          { params },
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const getSupport = useCallback(
    async (supportId: number) => {
      try {
        const { data } = await privateApi.get<GetSupportResponse>(
          `/supports/${supportId}`,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const createSupport = useCallback(
    async (params: CreateSupportParams) => {
      const formData = new FormData();
      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((label, index) => {
            formData.append(
              `labels[${index}][languageId]`,
              label.languageId.toString(),
            );
            formData.append(`labels[${index}][label]`, label.label);
          });
        } else {
          formData.append(key, value);
        }
      });

      try {
        const { data } = await privateApi.post('/supports', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const updateSupport = useCallback(
    async ({ id, ...otherParams }: UpdateSupportParams) => {
      const formData = new FormData();
      Object.entries(otherParams).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((label, index) => {
            formData.append(
              `labels[${index}][languageId]`,
              label.languageId.toString(),
            );
            formData.append(`labels[${index}][label]`, label.label);
          });
        } else {
          formData.append(key, value);
        }
      });

      try {
        await privateApi.patch(`/supports/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const updateSupportStatus = useCallback(
    async ({ id, enabled }: UpdateSupportStatusParams) => {
      try {
        await privateApi.patch(`/supports/${id}/toggle`, {
          enabled,
        });
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  // Return API functions
  return {
    getSupports,
    createSupport,
    updateSupport,
    updateSupportStatus,
    getSupport,
  };
};
