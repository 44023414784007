import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { DateRangePicker } from '@molecules/Form/DateRangePicker';
import {
  DateRangePickerValue,
  ValuePiece,
} from '@molecules/Form/DateRangePicker/definitions';
import { FormField } from '@molecules/Form/FormField';

import { FilterCommonProps } from './definitions';

export type DateRangeFilterValue = [string | null, string | null];

export const DateRangeFilter = ({
  value,
  onChange,
  anchor = 'top',
}: FilterCommonProps<DateRangeFilterValue> & {
  anchor?: 'top' | 'bottom';
}) => {
  const { t } = useTranslation();

  const formattedValue = useMemo(() => {
    return value.map((date) => (date ? dayjs(date).toDate() : null)) as [
      ValuePiece,
      ValuePiece,
    ];
  }, [value]);

  const handleChange = useCallback(
    (dates: DateRangePickerValue) => {
      if (Array.isArray(dates)) {
        const [from, to] = dates;

        onChange([
          from ? dayjs(from).format('YYYY-MM-DD') : null,
          to ? dayjs(to).format('YYYY-MM-DD') : null,
        ]);
      }
    },
    [onChange],
  );

  return (
    <FormField
      label={t('Filters.dates.label')}
      direction="column"
      icon="calendar"
    >
      <DateRangePicker
        value={formattedValue}
        onChange={handleChange}
        anchor={anchor}
      />
    </FormField>
  );
};
