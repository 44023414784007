import React from 'react';

import useWindow from '@hooks/useWindow';
import { ActivityCardDesktop } from '@molecules/ActivityCard/ActivityCardDesktop';
import { ActivityCardMobile } from '@molecules/ActivityCard/ActivityCardMobile';
import { ActivityCardMobileLite } from '@molecules/ActivityCard/ActivityCardMobileLite';

import { ActivityCardProps } from './definitions';

const ActivityCard = (props: ActivityCardProps) => {
  const { isMobile } = useWindow();

  if (isMobile) {
    return props.isLite ? (
      <ActivityCardMobileLite {...props} />
    ) : (
      <ActivityCardMobile {...props} />
    );
  }

  return <ActivityCardDesktop {...props} />;
};

export default React.memo(ActivityCard);
