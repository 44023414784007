import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useModalsContext } from '@contexts/modals';
import { useReceiveContext } from '@contexts/receive';

export const useReceiveBaseModal = ({ onClose }: { onClose?: () => void }) => {
  const { t } = useTranslation();
  const { showConfirmationModal, hideAllReceiveModals } = useModalsContext();
  const { reset: resetShipmentContext } = useReceiveContext();

  const onDismiss = useCallback(() => {
    showConfirmationModal({
      onConfirm: () => {
        hideAllReceiveModals();
        resetShipmentContext();
        onClose?.();
      },
      title: t('Modals.ShipmentConfirmation.Dismiss.title'),
      content: t('Modals.ShipmentConfirmation.Dismiss.description'),
    });
  }, [
    hideAllReceiveModals,
    onClose,
    resetShipmentContext,
    showConfirmationModal,
    t,
  ]);

  return {
    onDismiss,
  };
};
