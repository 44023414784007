export enum AnonymousRoutes {
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
}

export enum AuthenticatedRoutes {
  HOME_PAGE = '/',
  PENDING_PAGE = '/pending',
  HISTORY_PAGE = '/history',
  IN_COURSE_PAGE = '/in-course',
  STATISTICS_PAGE = '/statistics',
  IN_STOCK_PAGE = '/in-stock',
  USERS_PAGE = '/users',
  ORGANIZATIONS_PAGE = '/organizations',
  ITEMS_PAGE = '/items',
}
