import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Cookies from 'js-cookie';
import { StorageKey } from 'src/definitions/Navigation';

import { useLogger } from '@hooks/useLogger';

import {
  AuthenticationContextProps,
  AuthenticationProviderProps,
  TokenPair,
} from './definitions';

const undefinedMethod = () => {
  throw new Error('[AuthContext] Context not initialised');
};

const AuthenticationContext = createContext<AuthenticationContextProps>({
  token: undefined,
  refreshToken: undefined,
  setTokens: undefinedMethod,
  removeTokens: undefinedMethod,
  isAuthenticated: false,
  isAuthenticationChecked: false,
});

export const AuthenticationProvider = ({
  children,
}: AuthenticationProviderProps) => {
  const { logError } = useLogger();

  const [token, _setToken] = useState<AuthenticationContextProps['token']>();
  const [refreshToken, _setRefreshToken] =
    useState<AuthenticationContextProps['token']>();

  const [isAuthenticationChecked, setAuthenticationChecked] =
    useState<AuthenticationContextProps['isAuthenticationChecked']>(false);

  const _setTokenCallback = useCallback(
    (newToken: string, expires?: number) => {
      _setToken(newToken);
      Cookies.set(StorageKey.Token, newToken, { expires: expires });
    },
    [],
  );

  const _setRefreshTokenCallback = useCallback(
    (newRefreshToken: string, expires?: number) => {
      _setRefreshToken(newRefreshToken);
      Cookies.set(StorageKey.RefreshToken, newRefreshToken, {
        expires: expires,
      });
    },
    [],
  );

  const setTokens = useCallback(
    ({ token, refreshToken, tokenExpires, refreshTokenExpires }: TokenPair) => {
      _setTokenCallback(token, tokenExpires);
      _setRefreshTokenCallback(refreshToken, refreshTokenExpires);
    },
    [_setRefreshTokenCallback, _setTokenCallback],
  );

  const removeTokens = useCallback(() => {
    _setToken(undefined);
    _setRefreshToken(undefined);
    Cookies.remove(StorageKey.Token);
    Cookies.remove(StorageKey.RefreshToken);
  }, []);

  const isAuthenticated = useMemo(() => {
    return token !== undefined;
  }, [token]);

  const initialize = useCallback(async () => {
    try {
      const tokenFromStorage = Cookies.get(StorageKey.Token);
      const refreshTokenFromStorage = Cookies.get(StorageKey.RefreshToken);

      if (tokenFromStorage) {
        _setToken(tokenFromStorage);
      }

      if (refreshTokenFromStorage) {
        _setRefreshToken(refreshTokenFromStorage);
      }
    } catch (error) {
      logError(error);
      throw error;
    }
  }, [logError]);

  useEffect(() => {
    initialize().then(() => {
      setAuthenticationChecked(true);
    });
  }, [initialize]);

  const memoizedValue = useMemo<AuthenticationContextProps>(
    () => ({
      token,
      refreshToken,
      setTokens,
      removeTokens,
      isAuthenticated,
      isAuthenticationChecked,
    }),
    [
      token,
      refreshToken,
      removeTokens,
      isAuthenticated,
      setTokens,
      isAuthenticationChecked,
    ],
  );

  return (
    <AuthenticationContext.Provider value={memoizedValue}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthentication = (): AuthenticationContextProps =>
  useContext(AuthenticationContext);
