import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthApi } from '@api/authApi';
import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import { useAuthentication } from '@contexts/auth';
import { useAuthActions } from '@hooks/useAuthActions';
import { useLogger } from '@hooks/useLogger';

const Logout = () => {
  const { t } = useTranslation();

  const { refreshToken } = useAuthentication();

  const { signOut } = useAuthActions();

  const { logout } = useAuthApi();

  const { logError } = useLogger();

  const logoutHandler = useCallback(() => {
    logout({ refreshToken: refreshToken || '' })
      .then(() => {
        signOut();
      })
      .catch((error) => {
        logError(error);
      });
  }, [logError, logout, refreshToken, signOut]);

  return (
    <button onClick={logoutHandler} className="flex items-center">
      <IconSVG icon="logout" size={20} color="#000" />
      <Typography isUppercase isBold size="md" className="pl-3">
        {t('General.logout')}
      </Typography>
    </button>
  );
};

export default React.memo(Logout);
