import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useModalsContext } from '@contexts/modals';
import { usePendingShipmentContext } from '@contexts/pendingShipment';

export const usePendingShipmentBaseModal = ({
  onClose,
}: {
  onClose?: () => void;
}) => {
  const { t } = useTranslation();
  const { showConfirmationModal, hideAllPendingShipmentModals } =
    useModalsContext();
  const { reset: resetPendingShipmentContext } = usePendingShipmentContext();

  const onDismiss = useCallback(() => {
    showConfirmationModal({
      onConfirm: () => {
        hideAllPendingShipmentModals();
        resetPendingShipmentContext();
        onClose?.();
      },
      title: t('Modals.PendingShipmentConfirmation.Dismiss.title'),
      content: t('Modals.PendingShipmentConfirmation.Dismiss.description'),
    });
  }, [
    hideAllPendingShipmentModals,
    onClose,
    resetPendingShipmentContext,
    showConfirmationModal,
    t,
  ]);

  return {
    onDismiss,
  };
};
