import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useShipmentApi } from '@api/ShipmentApi';
import { useUserContext } from '@contexts/user';
import { QueryKeys } from '@definitions/QueryKeys';
import useWindow from '@hooks/useWindow';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { ShipmentsInCourseFilters } from './ShipmentsInCourseFilters';
import { ShipmentsInCourseFiltersRecord } from './ShipmentsInCourseFilters/definitions';
import ShipmentsListPage from './ShipmentsListPage';

const InCoursePage = () => {
  const { isMobile } = useWindow();
  const { t } = useTranslation();
  const { isAdmin } = useUserContext();
  const { getShipmentsInCourse, getShipmentsInCourseFiltered } =
    useShipmentApi();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [QueryKeys.SHIPMENT_IN_COURSE],
      queryFn: ({ pageParam = 1 }) =>
        getShipmentsInCourse({
          page: pageParam,
          perPage: 20,
        }),
      initialPageParam: 1,
      getNextPageParam: (thisPage) =>
        thisPage.meta.currentPage + 1 > thisPage.meta.lastPage
          ? null
          : thisPage.meta.currentPage + 1,
    });

  const shipmentsInCourse = useMemo(
    () =>
      data?.pages.flatMap((pages) => {
        return pages.data;
      }),
    [data?.pages],
  );

  // Filtered results
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<ShipmentsInCourseFiltersRecord>();

  const resetFilters = useCallback(() => {
    setFilters(undefined);
  }, []);

  const filtersParams = useMemo(() => {
    const dates = filters?.dates?.split(';');
    const senderOrganizationId =
      filters?.direction === 'from'
        ? filters?.organizationId || undefined
        : undefined;
    const receiverOrganizationId =
      filters?.direction === 'to'
        ? filters.organizationId || undefined
        : undefined;

    return {
      shipmentId: filters?.shipmentInCourseId,
      from: dates?.[0] || undefined,
      to: dates?.[1] || undefined,
      senderOrganizationId,
      receiverOrganizationId,
      styleId: filters?.itemId || undefined,
    };
  }, [
    filters?.dates,
    filters?.direction,
    filters?.itemId,
    filters?.organizationId,
    filters?.shipmentInCourseId,
  ]);

  const {
    data: shipmentsInCourseFiltered,
    isLoading: isLoadingShipmentsInCourseFiltered,
  } = useQuery({
    enabled: !!filters,
    queryKey: [QueryKeys.SHIPMENTS_IN_COURSE_FILTER, filters],
    queryFn: () => getShipmentsInCourseFiltered(filtersParams),
  });

  const parsedShipmentList = useMemo(() => {
    const hasFilters = !!filters;

    if (hasFilters && shipmentsInCourseFiltered) {
      return shipmentsInCourseFiltered;
    }

    return shipmentsInCourse;
  }, [filters, shipmentsInCourse, shipmentsInCourseFiltered]);

  const handleOnBottomReached = useCallback(() => {
    if (filters) {
      return;
    }

    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, filters, hasNextPage, isFetchingNextPage]);

  return (
    <ShipmentsListPage
      title={t('InCourse.title')}
      isLoading={isLoadingShipmentsInCourseFiltered || isFetchingNextPage}
      FiltersNode={
        <ShipmentsInCourseFilters
          isMobile={isMobile}
          isMobileFiltersOpen={isMobileFiltersOpen}
          onCloseMobileFilters={() => setIsMobileFiltersOpen(false)}
          onApply={setFilters}
          onReset={resetFilters}
        />
      }
      shipmentsList={parsedShipmentList}
      onBottomReached={handleOnBottomReached}
      onMobileFiltersOpen={() => setIsMobileFiltersOpen(true)}
      canChangeActivityCardStatus={isAdmin}
    />
  );
};

export default InCoursePage;
