import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import useWindow from '@hooks/useWindow';
import { NavigationMenuDesktopLayouts } from '@templates/BaseLayout/NavigationMenuDesktopLayouts';
import { NavigationMenuMobileLayouts } from '@templates/BaseLayout/NavigationMenuMobileLayouts';

import { BaseLayoutProps } from './definitions.ts';

const BaseLayout = ({ children }: BaseLayoutProps): ReactElement => {
  const { isMobile } = useWindow();

  return (
    <>
      {isMobile && (
        <NavigationMenuMobileLayouts>{children}</NavigationMenuMobileLayouts>
      )}
      {!isMobile && (
        <NavigationMenuDesktopLayouts>{children}</NavigationMenuDesktopLayouts>
      )}
      <Outlet />
    </>
  );
};

export default React.memo(BaseLayout);
