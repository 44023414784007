import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';

import { Spinner } from '@atoms/Spinner';
import { useUserContext } from '@contexts/user';
import { Roles } from '@definitions/Users';

import { AuthenticatedRoutes } from '..';

type RoleOnlyProps = RouteProps & {
  roles: Roles[];
  fallbackRoute?: AuthenticatedRoutes;
};

const RolesOnly = ({
  element: Page,
  roles,
  fallbackRoute = AuthenticatedRoutes.HOME_PAGE,
}: RoleOnlyProps) => {
  const { user } = useUserContext();

  if (!user) return <Spinner />;

  if (user.role && roles.includes(user.role.name)) {
    return <>{Page}</>;
  }

  return <Navigate to={fallbackRoute} replace />;
};

export default React.memo(RolesOnly);
