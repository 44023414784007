import { useMemo } from 'react';

import { SpinnerProps } from './definitions.ts';

const Spinner = ({ className = '', size = 'lg' }: SpinnerProps) => {
  const sizeClass = useMemo(
    () =>
      ({
        xs: 'ui-loading-xs',
        sm: 'ui-loading-sm',
        md: 'ui-loading-md',
        lg: 'ui-loading-lg',
      })[size],
    [size],
  );

  return (
    <div className={['text-center', className].join(' ')}>
      <span className={`ui-loading ui-loading-spinner ${sizeClass}`}></span>
    </div>
  );
};
export default Spinner;
