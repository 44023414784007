import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

import { NewShipmentProduct } from '@definitions/Products';

export const useItemDetailsModalSchema = ({
  type,
  isFullForm,
  isTypeOther,
}: {
  type: NewShipmentProduct['styleIdType'];
  isFullForm: boolean;
  isTypeOther: boolean;
}) => {
  const { t } = useTranslation();

  const styleIdMatches = (() => {
    if (type === 'sku') {
      // 9 numbers to 15 characters
      return /^\w{9,15}$/;
    }

    if (type === 'other') {
      // less than 15 characters
      return /^.{0,14}$/;
    }

    if (type === 'sts') {
      // 6 numbers
      return /^\d{6}$/;
    }

    // consignment: 5 numbers
    return /^\d{5}$/;
  })();

  return useMemo(() => {
    return yup
      .object({
        StyleID: yup
          .string()
          .required(t('Form.errors.required'))
          .matches(styleIdMatches, t('Modals.ItemDetails.invalidEntry'))
          .typeError(t('Modals.ItemDetails.invalidEntry')),
        Category: !isFullForm
          ? yup.string()
          : yup
              .string()
              .required(t('Form.errors.required'))
              .typeError(t('Modals.ItemDetails.invalidEntry')),
        NameOfProduct: !isFullForm
          ? yup.string()
          : yup
              .string()
              .required(t('Form.errors.required'))
              .max(100, t('Form.errors.limitExceeded'))
              .typeError(t('Modals.ItemDetails.invalidEntry')),
        Size:
          !isFullForm || isTypeOther
            ? yup.string()
            : yup
                .string()
                .required(t('Form.errors.required'))
                .max(50, t('Form.errors.limitExceeded'))
                .typeError(t('Modals.ItemDetails.invalidEntry')),
        Billable: yup
          .string()
          .required(t('Form.errors.required'))
          .typeError(t('Modals.ItemDetails.invalidEntry')),
        BillableCenterCost: !isFullForm
          ? yup.string()
          : yup.string().when('Billable', {
              is: 'yes',
              then: (schema) =>
                schema
                  .required()
                  .typeError(t('Modals.ItemDetails.invalidEntry')),
              otherwise: undefined,
            }),
        ReasonForRequest: yup
          .string()
          .required(t('Form.errors.required'))
          .typeError(t('Modals.ItemDetails.invalidEntry')),
        Comment: yup
          .string()
          .max(250, t('Form.errors.limitExceeded'))
          .typeError(t('Modals.ItemDetails.invalidEntry'))
          .when('ReasonForRequest', {
            is: 'other',
            then: (schema) =>
              schema.required().typeError(t('Modals.ItemDetails.invalidEntry')),
            otherwise: undefined,
          }),
      })
      .required();
  }, [isFullForm, isTypeOther, styleIdMatches, t]);
};
