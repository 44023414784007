import { useRef } from 'react';

import axios, { AxiosInstance } from 'axios';

const usePublicApi = () => {
  const baseURL = import.meta.env.VITE_BASE_URL;

  const publicApi = useRef<AxiosInstance>(
    axios.create({
      timeout: 20000,
      baseURL,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
  );

  return publicApi.current;
};

export default usePublicApi;
