import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAuthentication } from '@contexts/auth';

import { AuthenticatedRoutes } from '../routes';

const Guest = () => {
  const { isAuthenticated, isAuthenticationChecked } = useAuthentication();

  if (!isAuthenticationChecked) {
    return null;
  }

  if (isAuthenticated) {
    return <Navigate to={AuthenticatedRoutes.HOME_PAGE} replace />;
  }

  return <Outlet />;
};

export default React.memo(Guest);
