import { useTranslation } from 'react-i18next';

import { IconSVG } from '@atoms/IconSVG';
import { Spinner } from '@atoms/Spinner';
import { Typography } from '@atoms/Typography';
import useWindow from '@hooks/useWindow';
import ActivityCard from '@molecules/ActivityCard/ActivityCard';
import { ScrollableView } from '@molecules/ScrollableView';
import { BaseLayout } from '@templates/BaseLayout';
import { noop } from '@utils/noop';

import { ShipmentsListPageProps } from './definitions';

const ShipmentsListPage = ({
  title,
  isLoading,
  FiltersNode,
  shipmentsList,
  onBottomReached = noop,
  onMobileFiltersOpen = noop,
  canChangeActivityCardStatus = false,
  cardProps,
}: ShipmentsListPageProps) => {
  const { isMobile } = useWindow();
  const { t } = useTranslation();

  const hasFilters = !!FiltersNode;

  return (
    <BaseLayout>
      <div className="flex flex-col h-screen">
        {/* Page title */}
        <div className="sticky z-[2] flex justify-between top-0 px-4 bg-Primary-05 md:bg-Primary-04 py-6">
          <Typography isUppercase isBold size="xl">
            {title}
          </Typography>
          {hasFilters && isMobile && (
            <IconSVG
              icon="filter"
              size={24}
              onClick={() => onMobileFiltersOpen()}
            />
          )}
        </div>

        {/* Filters */}
        {FiltersNode && <div className="md:p-4 md:pt-0">{FiltersNode}</div>}

        {shipmentsList?.length === 0 ? (
          <div className="flex flex-col h-full justify-start items-center mt-10 px-4 text-Primary-03 text-sm md:text-lg">
            {t('ShipmentListPage.noData', {
              context: hasFilters ? 'filters' : undefined,
            })}
          </div>
        ) : (
          <ScrollableView
            className="px-4 pt-0 pb-4 flex-1 flex flex-col gap-2"
            onBottomReached={onBottomReached}
            treshold={400}
          >
            <>
              {shipmentsList?.map((shipment) => {
                return (
                  <ActivityCard
                    key={shipment.id}
                    shipment={shipment}
                    canChangeStatus={canChangeActivityCardStatus}
                    {...cardProps}
                  />
                );
              })}
              {isLoading && <Spinner />}
            </>
          </ScrollableView>
        )}
      </div>
    </BaseLayout>
  );
};

export default ShipmentsListPage;
