import { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@atoms/Typography';
import { useUserContext } from '@contexts/user';
import Statistics from '@organisms/Statistics';
import {
  FiltersData as OrganizationsFiltersData,
  OrganizationsFiltersProps,
} from '@organisms/Statistics/OrganizationsFilters';
import {
  FiltersData as TimechartFiltersData,
  TimechartFiltersProps,
} from '@organisms/Statistics/TimechartFilters';
import {
  ToolbarProps,
  TabKeys as ToolbarTabKeys,
} from '@organisms/Statistics/Toolbar';
import { BaseLayout } from '@templates/BaseLayout';

const StatisticsPage = () => {
  const { t } = useTranslation();

  const { isAdmin } = useUserContext();

  const [activeTab, setActiveTab] = useState<ToolbarTabKeys>('timechart');

  const [organizationsFilters, setOrganizationsFilters] =
    useState<OrganizationsFiltersData | null>(null);

  const [timechartFilters, setTimechartFilters] =
    useState<TimechartFiltersData | null>(null);

  const handleOrganizationsFilterChange = useCallback<
    OrganizationsFiltersProps['onFiltersChange']
  >((filters) => {
    setOrganizationsFilters(filters);
  }, []);

  const handleTimechartFilterChange = useCallback<
    TimechartFiltersProps['onFiltersChange']
  >((filters) => {
    setTimechartFilters(filters);
  }, []);

  const handleActiveTabChange = useCallback<ToolbarProps['onActiveTabChange']>(
    (tab) => {
      setActiveTab(tab);
      if (tab !== 'organizations') {
        setOrganizationsFilters(null);
      }

      if (tab !== 'timechart') {
        setTimechartFilters(null);
      }
    },
    [],
  );

  return (
    <BaseLayout>
      <div className="flex flex-col">
        <div className="sticky z-[2] top-0 px-4 bg-Primary-05 md:bg-Primary-04 py-6">
          <Typography isUppercase isBold size="xl">
            {t('Statistics.title')}
          </Typography>
        </div>
        <div className="z-[1] px-4 pb-4 flex flex-col gap-4 items-center">
          <Statistics.Toolbar
            activeTab={activeTab}
            onActiveTabChange={handleActiveTabChange}
            filtersNode={
              <Fragment>
                {activeTab === 'timechart' && (
                  <Statistics.TimechartFilters
                    onFiltersChange={handleTimechartFilterChange}
                  />
                )}
                {activeTab === 'organizations' && (
                  <Statistics.OrganizationsFilters
                    onFiltersChange={handleOrganizationsFilterChange}
                  />
                )}
              </Fragment>
            }
          />
          {activeTab === 'timechart' && (
            <Statistics.Timechart filters={timechartFilters} />
          )}
          {activeTab === 'organizations' &&
            (isAdmin ? (
              <Statistics.OrganizationsTable filters={organizationsFilters} />
            ) : (
              <Statistics.SingleOrganization filters={organizationsFilters} />
            ))}
        </div>
      </div>
    </BaseLayout>
  );
};
export default StatisticsPage;
