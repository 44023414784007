import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { QrCodeScannerProps } from '@atoms/QrCodeScanner/definitions';
import { Spinner } from '@atoms/Spinner';
import { Typography } from '@atoms/Typography';
import { Scanner } from '@yudiel/react-qr-scanner';

const QrCodeScanner: React.FC<QrCodeScannerProps> = ({ onScan, paused }) => {
  const { t } = useTranslation();
  const [isCheckingCapabilities, setIsCheckingCapabilities] = useState(false);
  const [hasEnabledCamera, setHasEnabledCamera] = useState(false);

  useEffect(() => {
    setIsCheckingCapabilities(true);
    navigator?.mediaDevices
      ?.getUserMedia?.({
        video: true,
      })
      .then(({ active }) => {
        if (active) {
          setHasEnabledCamera(true);
        }
      })
      .catch(() => {
        setHasEnabledCamera(false);
      })
      .finally(() => {
        setIsCheckingCapabilities(false);
      });
  }, []);

  if (isCheckingCapabilities || !hasEnabledCamera) {
    return (
      <div className=" bg-Primary-05 p-6 mx-4">
        {isCheckingCapabilities && <Spinner />}
        {!isCheckingCapabilities && !hasEnabledCamera && (
          <Typography className="text-center px-8">
            {t('QrCodeScanner.enableCamera')}
          </Typography>
        )}
      </div>
    );
  }

  return (
    <div>
      <Scanner
        formats={['micro_qr_code', 'qr_code', 'rm_qr_code']}
        styles={{
          finderBorder: 0,
        }}
        onScan={onScan}
        paused={paused}
      />
    </div>
  );
};

export default React.memo(QrCodeScanner);
