import React from 'react';

import { Typography } from '@atoms/Typography';

import { ToggleProps } from './definitions';

const Toggle = ({ checked, onChange, label }: ToggleProps) => {
  return (
    <label className="inline-flex items-center justify-center gap-1 cursor-pointer">
      <div className="relative rounded-[120px] overflow-hidden w-[32px] h-[20px]">
        <input
          type="checkbox"
          className="absolute opacity-0 w-full h-full z-3 cursor-pointer"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
        <div
          className={`absolute w-full h-full z-1 transition-background ${checked ? 'bg-Primary-00' : 'bg-Primary-03'}`}
        />
        <div
          className={`absolute w-[16px] h-[16px] rounded-full bg-Primary-05 top-[2px] left-[2px] transition-transform ${checked ? 'translate-x-[12px]' : 'translate-x-0'}`}
        />
      </div>
      {label && <Typography size="md">{label}</Typography>}
    </label>
  );
};

export default React.memo(Toggle);
