import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config';

const useTailwindConfig = () => {
  const getTailwindConfiguration = () => {
    return resolveConfig(tailwindConfig).theme;
  };
  const config = getTailwindConfiguration();

  return {
    config,
  };
};
export default useTailwindConfig;
