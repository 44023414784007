import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GetOrganizationsStatisticsParams,
  useStatisticsApi,
} from '@api/statistics';
import { Card } from '@atoms/Card';
import { Spinner } from '@atoms/Spinner';
import { Typography } from '@atoms/Typography';
import { QueryKeys } from '@definitions/QueryKeys';
import { useQuery } from '@tanstack/react-query';

import { SingleOrganizationProps } from './definitions';

const SingleOrganization = ({ filters }: SingleOrganizationProps) => {
  const { t } = useTranslation();

  const { getOrganizationsStatistics } = useStatisticsApi();

  const queryParams = useMemo<GetOrganizationsStatisticsParams>(() => {
    const params: GetOrganizationsStatisticsParams = {};

    if (filters?.dates) {
      const [from, to] = filters.dates.split(';');
      params.from = from;
      params.to = to;
    }

    return params;
  }, [filters]);

  const { data: organizationsStatisticsData, isLoading } = useQuery({
    queryKey: [QueryKeys.ORGANIZATIONS_STATISTICS, queryParams],
    queryFn: () => getOrganizationsStatistics(queryParams),
  });

  const singleOrganizationStatistics = useMemo(() => {
    if (
      !organizationsStatisticsData ||
      organizationsStatisticsData.data.length === 0
    ) {
      return null;
    }

    return organizationsStatisticsData.data[0];
  }, [organizationsStatisticsData]);

  const getInfoBox = useCallback(
    (label: string, value: string, isHalf?: boolean) => {
      return (
        <div
          className={`border border-Primary-03 rounded-[6px] bg-Primary-05 flex flex-col items-center justify-center gap-7 min-h-[112px] p-3 ${isHalf ? 'col-span-3' : 'col-span-2'}`}
        >
          <Typography size="md" isBold>
            {label}
          </Typography>
          <Typography size="xxl">{value}</Typography>
        </div>
      );
    },
    [],
  );

  return isLoading ? (
    <div className="w-full min-h-[100px] flex items-center justify-center">
      <Spinner />
    </div>
  ) : !singleOrganizationStatistics ? (
    <div className="w-full min-h-[100px] flex items-center justify-center">
      <Typography size="sm" sizeMd="lg" color="text-Primary-03">
        {t('Statistics.organizations.noData')}
      </Typography>
    </div>
  ) : (
    <Card hasPadding={false} className="p-4">
      <div className="w-full grid grid-cols-6 gap-4">
        {getInfoBox(
          t('Statistics.organizations.table.columns.transited'),
          String(singleOrganizationStatistics?.transited),
        )}
        {getInfoBox(
          t('Statistics.organizations.table.columns.returnedAsSender'),
          String(singleOrganizationStatistics?.returnedAsSender),
        )}
        {getInfoBox(
          t('Statistics.organizations.table.columns.requestedAsSender'),
          String(singleOrganizationStatistics?.requestedAsSender),
        )}
        {getInfoBox(
          t('Statistics.organizations.table.columns.totalAsSender'),
          String(singleOrganizationStatistics?.totalAsSender),
        )}
        {getInfoBox(
          t('Statistics.organizations.table.columns.returnedAsReceiver'),
          String(singleOrganizationStatistics?.returnedAsReceiver),
        )}
        {getInfoBox(
          t('Statistics.organizations.table.columns.requestedAsReceiver'),
          String(singleOrganizationStatistics?.requestedAsReceiver),
        )}
        {getInfoBox(
          t('Statistics.organizations.table.columns.totalAsReceiver'),
          String(singleOrganizationStatistics?.totalAsReceiver),
        )}
        {getInfoBox(
          t('Statistics.organizations.table.columns.urgent'),
          String(singleOrganizationStatistics?.urgent),
        )}
        {getInfoBox(
          t('Statistics.organizations.table.columns.desiredDeliveryDate'),
          String(singleOrganizationStatistics?.desiredDeliveryDate),
        )}
        {getInfoBox(
          t('Statistics.organizations.table.columns.withReserve'),
          String(singleOrganizationStatistics?.withReserve),
          true,
        )}
        {getInfoBox(
          t('Statistics.organizations.table.columns.avgDelivery'),
          String(singleOrganizationStatistics?.avgDelivery),
          true,
        )}
      </div>
    </Card>
  );
};

export default React.memo(SingleOrganization);
