import React from 'react';

import { RadioProps } from './definitions';

const Radio = ({ name, value, checked, onChange }: RadioProps) => {
  return (
    <input
      type="radio"
      name={name}
      className="ui-radio border-solid border-2 border-Primary-02"
      value={value}
      checked={checked}
      onChange={(e) => onChange(value, e.target.checked)}
    />
  );
};

export default React.memo(Radio);
