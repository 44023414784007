import React, { useCallback, useMemo } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useOrganizationsApi } from '@api/OrganizationsApi';
import { Button } from '@atoms/Button';
import { QueryKeys } from '@definitions/QueryKeys';
import { yupResolver } from '@hookform/resolvers/yup';
import useToast from '@hooks/useToast';
import { FormField } from '@molecules/Form/FormField';
import { Input } from '@molecules/Form/Input';
import { Select } from '@molecules/Form/Select';
import { BaseModal } from '@organisms/Modals/BaseModal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import imagePlaceholder from '@theme/assets/image-placeholder.svg';

import { AddOrganizationModalProps, FormValues } from './definitions';
import { useValiadtionSchema } from './validation';

const AddOrganizationModal = ({ open, onClose }: AddOrganizationModalProps) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { showSuccessToast, showWarningToast } = useToast();

  const validation = useValiadtionSchema();

  const { getOrganizationTypeList, createOrganization } = useOrganizationsApi();

  const { control, handleSubmit, reset } = useForm<FormValues>({
    resolver: yupResolver(validation),
    defaultValues: {
      name: '',
      address: '',
      type: '',
    },
  });

  const [typeValue] = useWatch({
    control,
    name: ['type'],
  });

  const handleClose = useCallback(() => {
    onClose?.();
    reset();
  }, [onClose, reset]);

  const { data: organizationTypesData } = useQuery({
    queryKey: [QueryKeys.ORGANIZATIONS_TYPE_LIST],
    queryFn: () => getOrganizationTypeList(),
    enabled: open,
  });

  const { mutate: callCreateOrganization, isPending: isAddingOrganization } =
    useMutation({
      mutationFn: createOrganization,
      onSuccess: () => {
        showSuccessToast(t('Modals.AddOrganization.successMessage'));
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ORGANIZATION_LIST],
          exact: false,
        });
        handleClose();
      },
      onError: () => {
        showWarningToast(t('Modals.AddOrganization.errorMessage'));
      },
    });

  const selectedType = useMemo(() => {
    return organizationTypesData?.find((item) => item.id === Number(typeValue));
  }, [organizationTypesData, typeValue]);

  const typeOptions = useMemo(() => {
    if (!organizationTypesData) {
      return [];
    }

    return organizationTypesData.map((item) => ({
      value: String(item.id),
      label: item.label,
    }));
  }, [organizationTypesData]);

  const onSubmit = useCallback<SubmitHandler<FormValues>>(
    (values) => {
      callCreateOrganization({
        name: values.name,
        address: values.address,
        organizationTypeId: Number(values.type),
      });
    },
    [callCreateOrganization],
  );

  return (
    <BaseModal
      id="addOrganizationModal"
      title={t('Modals.AddOrganization.title')}
      size="medium"
      showCloseButton={true}
      open={open}
      onClose={handleClose}
    >
      <div className="flex flex-col gap-9 items-stretch">
        <div className="flex flex-col gap-6 items-stretch">
          <Controller
            control={control}
            name="name"
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <FormField
                label={t('Modals.AddOrganization.fields.name.label')}
                required={true}
              >
                <Input
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder={t(
                    'Modals.AddOrganization.fields.name.placeholder',
                  )}
                  error={error?.message}
                  disabled={isAddingOrganization}
                />
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="address"
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <FormField
                label={t('Modals.AddOrganization.fields.address.label')}
                required={true}
                info={t('Modals.AddOrganization.fields.address.info')}
              >
                <Input
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder={t(
                    'Modals.AddOrganization.fields.address.placeholder',
                  )}
                  error={error?.message}
                  disabled={isAddingOrganization}
                />
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="type"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={t('Modals.AddOrganization.fields.type.label')}
                required={true}
              >
                <div className="flex flex-row items-start gap-6">
                  <div className="flex-1">
                    <Select
                      value={value}
                      onChange={onChange}
                      placeholder={t(
                        'Modals.AddOrganization.fields.type.placeholder',
                      )}
                      options={typeOptions}
                      error={error?.message}
                      disabled={isAddingOrganization}
                    />
                  </div>

                  <img
                    className="w-[40px] h-auto"
                    width={40}
                    height={40}
                    src={selectedType?.image || imagePlaceholder}
                  />
                </div>
              </FormField>
            )}
          />
        </div>
        <Button
          type="primary"
          label={t('General.ok')}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </BaseModal>
  );
};

export default React.memo(AddOrganizationModal);
