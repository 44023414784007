import { useCallback } from 'react';

import usePrivateApi from 'src/api/base/private';

import { useLogger } from '@hooks/useLogger';

import {
  CreatePackagingPayload,
  GetPackagingResponse,
  GetPackagingsParams,
  GetPackagingsResponse,
  UpdatePackagingPayload,
  UpdatePackagingsStatusPayload,
} from './definitions';

export const usePackagingApi = () => {
  const { logError } = useLogger();

  const privateApi = usePrivateApi();

  // Get Packagings
  const getPackagings = useCallback(
    async (params?: GetPackagingsParams) => {
      try {
        const { data } = await privateApi.get<GetPackagingsResponse>(
          '/packagings',
          { params },
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const getPackaging = useCallback(
    async (packagingId: number) => {
      try {
        const { data } = await privateApi.get<GetPackagingResponse>(
          `/packagings/${packagingId}`,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const createPackaging = useCallback(
    async (params: CreatePackagingPayload) => {
      const formData = new FormData();
      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((label, index) => {
            formData.append(
              `labels[${index}][languageId]`,
              label.languageId.toString(),
            );
            formData.append(`labels[${index}][label]`, label.label);
          });
        } else {
          formData.append(key, value);
        }
      });

      try {
        const { data } = await privateApi.post('/packagings', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const updatePackaging = useCallback(
    async ({ id, ...otherParams }: UpdatePackagingPayload) => {
      const formData = new FormData();
      Object.entries(otherParams).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((label, index) => {
            formData.append(
              `labels[${index}][languageId]`,
              label.languageId.toString(),
            );
            formData.append(`labels[${index}][label]`, label.label);
          });
        } else {
          formData.append(key, value);
        }
      });

      try {
        await privateApi.patch(`/packagings/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const updatePackagingStatus = useCallback(
    async ({ id, enabled }: UpdatePackagingsStatusPayload) => {
      try {
        await privateApi.patch(`/packagings/${id}/toggle`, {
          enabled,
        });
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  // Return API functions
  return {
    getPackagings,
    createPackaging,
    updatePackaging,
    updatePackagingStatus,
    getPackaging,
  };
};
